import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  deployBot,
  deployProcessWorkflow,
  nodeListContextMenu,
  saveProcessWorkflowData,
  saveWorkflowData,
} from "../builderLogic/builderStoreLogic";

export const saveWorkflowDataToServerThunk = createAsyncThunk(
  "",
  async ({ state, saveType, fromProcessWorkflow }) => {
    if (isBotDeployed(state.botStatus)) {
      return state;
    }

    if (fromProcessWorkflow === true) {
      return await saveProcessWorkflowData(state, saveType)
    }

    return await saveWorkflowData(state, saveType);
  }
);

export const deployBotThunk = createAsyncThunk("deploybot", async ({ state, fromProcessWorkflow }) => {
  if (isBotDeployed(state.botStatus)) {
    return state;
  }

  if (fromProcessWorkflow === true) {
    return await deployProcessWorkflow(state)
  }


  return await deployBot(state);
});

export const isBotDeployed = (botStatus) => {
  return botStatus === "deployed";
};

let userAgent = window.navigator.userAgent,
  platform =
    window.navigator?.userAgentData?.platform ?? window.navigator.platform,
  macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K", "macOS"],
  windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
  iosPlatforms = ["iPhone", "iPad", "iPod"],
  OS = null;

if (macosPlatforms.indexOf(platform) !== -1) {
  OS = "MacOS";
} else if (iosPlatforms.indexOf(platform) !== -1) {
  OS = "iOS";
} else if (windowsPlatforms.indexOf(platform) !== -1) {
  OS = "Windows";
} else if (/Android/.test(userAgent)) {
  OS = "Android";
} else if (!OS && /Linux/.test(platform)) {
  OS = "Linux";
}

const workflowEditorActionSlice = createSlice({
  name: "Workflow Editor Action Slice",
  initialState: {
    botStatus: "draft",
    isBotDeploying: false,
    simulator: { visible: false, channel: "" },
    isPropertyMenuVisible: false,
    nodeListContextMenu: { visible: false, top: 0, left: 0 },
    currentlySelectedNode: null,
    logContainerVisible: false,
    showPopOverDialog: {
      visible: false,
      dialog: "",
    },
    fullScreenPropertyMenu: {
      visible: false,
      nodeType: ''
    },
    snackbar: {
      open: false,
      severity: "",
      message: "",
    },
    userOS: OS,
    isContextMenuClick: false,
    userInfoForTestWhatsapp: { number: '', name: '' },
    userInfoForTestTubulu: { number: '', firstName: '', lastName: '', email: '' },
    workflowType: '',  // 'process' | 'bot'
    searchContainerVisible: false,
  },
  reducers: {
    // IMport all reducers here from store
    ResetActionForFlowBuilder: () => {
      return {
        botStatus: "draft",
        isBotDeploying: false,
        simulator: { visible: false, channel: "" },
        isPropertyMenuVisible: false,
        nodeListContextMenu: { visible: false, top: 0, left: 0 },
        currentlySelectedNode: null,
        logContainerVisible: false,
        logCount: { warningCount: 0, errorCount: 0 },
        showPopOverDialog: {
          visible: false,
          dialog: "",
        },
        snackbar: {
          open: false,
          severity: "",
          message: "",
        },
        userOS: OS,
      };
    },
    ToggleSimulatorWindow: (state, action) => {
      state.simulator = {
        visible: action.payload.visible,
        channel: action.payload.channel,
      };
    },
    NodeListContextMenu: (state, action) => {
      if (isBotDeployed(state.botStatus)) return state;
      return nodeListContextMenu(state, action.payload);
    },
    ClosePropertyMenu: (state) => {
      return {
        ...state,
        isPropertyMenuVisible: false,
      };
    },
    ShowPropertyMenu: (state, action) => {
      return { ...state, isPropertyMenuVisible: true };
    },
    ToggleLogContainer: (state, action) => {
      if (isBotDeployed(state.botStatus)) return state;
      return {
        ...state,
        isPropertyMenuVisible: false,
        logContainerVisible: action.payload.visible,
        nodeListContextMenu: { visible: false, top: 0, left: 0 },
        searchContainerVisible: false,  // when error log container open, close search container
      };
    },
    TogglePopoverContainer: (state, action) => {
      // if (isBotDeployed(state.botStatus)) return state;
      return {
        ...state,
        showPopOverDialog: {
          visible: action.payload.visible,
          dialog: action.payload.dialog,
        },
        nodeListContextMenu: { visible: false, top: 0, left: 0 },
        isPropertyMenuVisible: false,
      };
    },
    CloseSnackBar: (state, action) => {
      return {
        ...state,
        snackbar: {
          open: false,
          message: "",
          severity: "",
        },
      };
    },
    SnackBarMessage: (state, action) => {
      return {
        ...state,
        snackbar: {
          open: true,
          message: action.payload.message,
          severity: action.payload.severity,
        },
      };
    },
    UpdateBotStatus: (state, action) => {
      return { ...state, botStatus: action.payload.botStatus };
    },
    UpdateIsBotDelyoing: (state, action) => {
      return { ...state, isBotDeploying: action.payload.isBotDeploying };
    },

    UpdateSimulatorChat: (state, action) => {
      return { ...state, simulatorChat: [...state.simulatorChat, action.payload] }
    },
    AddUserInfoForWhatsapp: (state, action) => {
      return { ...state, userInfoForTestWhatsapp: { number: action.payload.number, name: action.payload.name } }
    },
    AddUserInfoForTubulu: (state, action) => {
      return {
        ...state,
        userInfoForTestTubulu: {
          number: action.payload.number,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email
        }
      }
    },
    ToggleFullScreenPropertyMenu: (state, action) => {
      state.fullScreenPropertyMenu = {
        visible: action.payload.visible,
        nodeType: action.payload.nodeType
      }
    },
    SetWorkflowType: (state, action) => {
      // here workflowType could be 'process' or 'bot'
      state.workflowType = action.payload.workflowType;
    },

    ToggleSearchContainer: (state, action) => {
      if (isBotDeployed(state.botStatus)) return state;
      return {
        ...state,
        isPropertyMenuVisible: false,
        searchContainerVisible: action.payload.visible,
        nodeListContextMenu: { visible: false, top: 0, left: 0 },
        logContainerVisible: false,  // close error log container
      };
    },

  },

  extraReducers: (builder) => {
    builder
      .addCase(saveWorkflowDataToServerThunk.pending, (state, action) => {
        // state.status = STATUS.LOADING;
      })
      .addCase(saveWorkflowDataToServerThunk.fulfilled, (state, action) => {
        state.snackbar = action.payload;
      })
      .addCase(saveWorkflowDataToServerThunk.rejected, (state, action) => {
        // state.status = STATUS.ERROR;
      })

      // Deploy bot thunk

      .addCase(deployBotThunk.pending, (state, action) => {
        // state.status = STATUS.LOADING;
      })
      .addCase(deployBotThunk.fulfilled, (state, action) => {
        let snackbar = { open: true, severity: "", message: "" };
        let simulator = {
          visible: state.simulator.visible,
          channel: state.simulator.channel,
        };
        try {
          if (action.payload === undefined || action.payload === null) {
            throw new Error("Unable to deploy bot");
          }

          if (action.payload.status === "DEPLOYED") {
            snackbar.severity = "success";
            simulator.visible = state.workflowType === 'process' ? false : true;
            snackbar.message = action.payload.message;
          } else {
            snackbar.severity = "warning";
            simulator.visible = false;
            snackbar.message = action.payload.message;
          }
        } catch (error) {
          snackbar.severity = "error";
          simulator.visible = false;
          snackbar.message = error.message;
        }
        state.isBotDeploying = false;
        state.snackbar = snackbar;
        state.simulator = simulator;
      })
      .addCase(deployBotThunk.rejected, (state, action) => {
        // state.status = STATUS.ERROR;
      });
  },
});

export const {
  NodeListContextMenu,
  ClosePropertyMenu,
  ShowPropertyMenu,
  ToggleLogContainer,
  TogglePopoverContainer,
  CloseSnackBar,
  SnackBarMessage,
  ResetActionForFlowBuilder,
  ToggleSimulatorWindow,
  UpdateBotStatus,
  UpdateIsBotDelyoing,
  AddUserInfoForWhatsapp,
  AddUserInfoForTubulu,
  ToggleFullScreenPropertyMenu,
  SetWorkflowType,
  ToggleSearchContainer
} = workflowEditorActionSlice.actions;

export default workflowEditorActionSlice.reducer;
