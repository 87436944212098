import * as React from "react";
import {Navigate, useLocation} from "react-router-dom";

import useAuth from "../../hooks/useAuth";
import {useSelector} from "react-redux";

// For routes that can only be accessed by authenticated team members
function AuthGuard({children}) {
    const {isAuthenticated, isInitialised} = useAuth();
    const accessToken = localStorage.getItem("accessToken");
    const org = useSelector((store) => store.org);
    const location = useLocation();
    if (!accessToken) {
        return <Navigate to="/login"/>;
    }
    if (isInitialised && !isAuthenticated) {
        return <Navigate to="/login"/>;
    } else {
        if (location.pathname === '/' && !isAuthenticated) return <Navigate to="/login"/>
        if (location.pathname === '/dashboard' && isAuthenticated && org.LogedInTeamMember && org.LogedInTeamMember.role === 'editor')
            return <Navigate to='/bots'/>
    }

    return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
