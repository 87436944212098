import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { Paper, Typography } from "@mui/material";
import ResetPasswordComponent from "../../components/auth/ResetPassword";

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;
const BrandText = styled(Typography)`
  color: ${(props) => props.theme.palette.secondary.main};
`;
function ResetPassword() {
  return (
    <React.Fragment>
      <Wrapper>
        <Helmet title="Reset Password" />
        <BrandText variant="h1" align="center" className="mb-3">
          1SPOC
        </BrandText>
        <Typography  component="h1" variant="h3" align="center" gutterBottom>
          Reset Password
        </Typography>
        <Typography component="h1"  align="center">
          Enter your email to reset your password
        </Typography>

        <ResetPasswordComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default ResetPassword;
