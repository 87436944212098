import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const orderListSlice = createSlice({
    name: "order list",
    initialState: {
        data: [],
        selectedInvoice: {},
        status: STATUS.IDLE
    },
    reducers: {
        SetInvoiceList: (state, action) => {
            state.data = action.payload;
        },
        SetSelectedInvoice: (state, action) => {
            state.selectedInvoice = action.payload;
        },
    }
});

export const {
    SetInvoiceList,
    SetSelectedInvoice
} = orderListSlice.actions;

export default orderListSlice.reducer;




