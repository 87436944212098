import { createSlice } from "@reduxjs/toolkit";
import {PricingPlans} from "../../utils/Constants/PricingPlans";

export let STATUS = {
    IDLE: "idle",
    LOADING: "Loading",
    ERROR: "error",
};

const signUpSlice = createSlice({
    name: "signUp",
    initialState: {
        pricingPlans: PricingPlans,
        selectedPricingPlan: {},
        status: STATUS.IDLE,
    },
    reducers: {
        setSelectedPricingPlan: (state, action) => {
            state.selectedPricingPlan = action.payload;
        },
        ResetState: (state, action) => {
            state.pricingPlans = {};
            state.status = STATUS.IDLE;
        },
    }
});

export const {
    setSelectedPricingPlan,
    ResetState,
} = signUpSlice.actions;

export default signUpSlice.reducer;