import moment from "moment";
import * as shortUUID from "short-uuid";


export function generateUniqueIdForCard() {
    return shortUUID.generate();
}

export function generateUniqueId() {
    return shortUUID.generate();
}

/**
 * Function to check the provided string is UUID or not.
 * Here id could be Short UUID or UUID(v4)
 */
export function isPassedIdIsUUID(id) {
    try {
        if (shortUUID().validate(id)) {
            return true;
        }
        shortUUID().fromUUID(id);
        return true;
    } catch (error) {
        return false;
    }
}

export function readFromLocalStorge({ keyname }) {
    if (localStorage.getItem(keyname) !== null) {
        let result = JSON.parse(localStorage.getItem(keyname));
        // return value = JSON.parse(localStorage.getItem(keyname));
        return {
            nodes: result.nodes,
            edges: result.edges,
            logs: result.logs,
            variables: result.variables,
        };
    } else {
        return {
            nodes: [],
            edges: [],
            logs: { errors: [], warnings: [] },
            variables: [],
        };
    }
}

export function setItemInLocalStorage({ keyname, value }) {
    sessionStorage.setItem(keyname, value);
}

export function getItemFromLocalStorage({ keyname }) {
    return sessionStorage.getItem(keyname);
}


export const getConditions = () => {
    const conditions = [
        { key: "LESSTHAN", value: "Less Than" },
        { key: "LESSTHANEQUALS", value: "Less Than Equal To" },
        { key: "GREATERTHAN", value: "Greater Than" },
        { key: "GREATERTHANEQUALS", value: "Greater Than Equal To" },
        { key: "EQUALS", value: "Equal To" },
        { key: "NOTEQUALS", value: "Not Equal To" },
    ];

    return conditions;
};

export class Queue {
    constructor() {
        this.items = [];
    }

    // add element to the queue
    enqueue(element) {
        return this.items.push(element);
    }

    // remove element from the queue
    dequeue() {
        if (this.items.length > 0) {
            return this.items.shift();
        }
    }

    // view the last element
    peek() {
        return this.items[this.items.length - 1];
    }

    // check if the queue is empty
    isEmpty() {
        return this.items.length == 0;
    }

    // the size of the queue
    size() {
        return this.items.length;
    }

    // empty the queue
    clear() {
        this.items = [];
    }
}

export const convertIntervalInSecondsFormat = ({
    seconds = 0,
    minutes = 0,
    hours = 0,
    days = 0,
    months = 0,
    years = 0,
}) => {
    let newSeconds = seconds;
    if (minutes !== 0) newSeconds += 60 * minutes;
    if (hours !== 0) newSeconds += 60 * 60 * hours;
    if (days !== 0) newSeconds += 60 * 60 * 24 * days;
    if (months !== 0) newSeconds += 60 * 60 * 24 * 31 * months;
    if (years !== 0) newSeconds += 60 * 60 * 24 * 31 * 12 * years;
    return newSeconds;
};


export const removeBracesFromString = (stringToCheck) => {
    const regexForFindingVariables = /({{*[a-zA-Z0-9_.\[\]]*}})/g;
    const regexForFindingBraces = /({{)|(}})/g;
    const arrayOfUsedVariables = stringToCheck.match(regexForFindingVariables);
    if (!arrayOfUsedVariables) {
        return stringToCheck;
    }
    let stringToReturn = stringToCheck;

    for (const iterator of arrayOfUsedVariables) {
        stringToReturn = stringToReturn.replace(iterator, iterator.replace(regexForFindingBraces, ''));
    }

    return stringToReturn;
};

export const getDefaultSourceHandleIdForNode = (nodeId) => {
    return `${nodeId}_default_source_handle`;
};

export const checkForBracesInVariableString = (stringToCheck = "") => {
    if (!stringToCheck) {
        return true;
    }

    /**
     *  The following regex was passing the following cases :
     *  ['{test}}','{[test}}']
     *  here it was taking variable where it was finding the "}}" which is not valid
     *  hence we are not using this implementation 
     */
    // const regexForFindingVariables = /({{*[a-zA-Z0-9_.\[\]]*}})/g;
    // const arrayOfUsedVariables = stringToCheck.match(regexForFindingVariables);
    // if (!arrayOfUsedVariables) {
    //   return false;
    // }
    // return true;



    /**
     * following is the result of new implmentation :-
     *  {{test}}={{test}}
        {{testkjdf.sjkdf}}={{testkjdf.sjkdf}}
        {dskjf}=null
        {{fksdj}=null
        {sjls}}=null
        jfksl=null
        {[sdf]}=null
        {[sdf}}=null
        {{sdjfk]}=null
        {{last.test[0].djfkl}}=null
        {{a.b.c.d.e.f.g.h.i[0].dj.df}}=null
        {{last.test["helo"]}}=l
        {{last.test[helo]}}=null
        {{last.test["helo"]}}=l
        {{last.test['helo']}}=l
        {{last.test[`helo`]}}=null
        {{last.test['how are you']}}=a
        {{last.test['how are you7d 9df8823748347']}}=a
        {{last.test['123how are you']}}=a
     */

    /**
     * here we are using three regex
     * 1. First checks the generic variables(valid variables that follows {{test}}, {{test.name}}, {{test[0]}})
     * 2. Second will search for all possible cases wich includes {{last.test[helo]}}, {{last.test["helo"]}}, {{dfd dfk '' df}}
     * 3. Third will filter out variable that consist of ["name"], ["name of customer"] [0] from second regex result/
     */

    const regex = /{{([a-zA-Z0-9_.\[\]]*)}}/g;
    const regex2 = /{{([a-zA-Z0-9_.\["'` \]]*)}}/g;
    const regex3 = /\[(\d+|'[^']*'|"[^"]*")\]/g;


    let firstRegexOutput = stringToCheck.match(regex);
    if (firstRegexOutput) {  // if we get generic variables like {{test}}, {{test.name}}, {{test[0]}} returning true
        return true;
    }

    const secondRegexOutput = stringToCheck.match(regex2);  // It will return all possible matches which contains "{{ }}"
    if (secondRegexOutput) {
        const thirdRegexOutput = stringToCheck.match(regex3);  // here filtering ["name"], ["name of customer"] from all possible matches
        if (thirdRegexOutput) {
            return true;
        }
    }
    return false;
};

// Here we are assuming  the stringToCheck is one word without space i.e. 'abc', '{{test}}'. '{{test["first name"]}}'
export const removeBracesFromStringNewImplementation = (stringToCheck) => {
    const regexForFindingBraces = /({{)|(}})/g;
    const isValidVariable = checkForBracesInVariableString(stringToCheck);
    if (isValidVariable === false) {
        return stringToCheck;
    }
    let stringToReturn = stringToCheck;
    stringToReturn = stringToReturn.replace(stringToReturn, stringToReturn.replace(regexForFindingBraces, ''));
    return stringToReturn;
};

/**
 * params :- {{test}}, {{one}}abc, abc, now{{test}}
 */
export const checkVariableStringIsValidOrNot = (variable) => {
    if (!variable || typeof variable !== 'string') {
        return false;
    }
    if (variable[0] !== '{' || variable[1] !== '{' || variable[variable.length - 1] !== '}' || variable[variable.length - 2] !== '}') {
        return false;
    }
    return true;
}


//  Here we are collecting all error in array
export const convertNodesErrorsInArray = (nodeData) => {
    let nodeErrors = [];
    if (nodeData.reports) {
        if (nodeData.reports.errors) {
            Object.keys(nodeData.reports.errors).map(errorKey => {
                if (nodeData.reports.errors[errorKey].messages.length > 0) {
                    nodeErrors = [...nodeErrors, ...nodeData.reports.errors[errorKey].messages];
                }
            })
        }
    }
    return nodeErrors;
}


/**
 * 
 * @param {string} dataToDownload 
 * @param {string} fileName 
 * @param {'json'|'txt'|'csv'} fileExtension 
 */
export const downloadFileHandler = (dataToDownload, fileName, fileExtension) => {
    const url = window.URL.createObjectURL(new Blob([dataToDownload]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${fileExtension}`);
    document.body.appendChild(link);
    link.click();
    // setError({ show: true, message: 'File downloaded successfully.' })
};


/**
 *
 * @param {*} date 2024-06-13
 * @param {*} time 00:00:00 || 23:59:59
 * @returns UTC Date with time // '2024-06-12T18:30:00.000Z' || '2024-06-13T18:29:59.000Z'
 */
export const convertLocalDateInUTCFormat = (date, time) => {
    const localDate = `${date} ${time}`;
    const utcDate = moment(localDate, 'YYYY-MM-DD HH:mm:ss').utc().toJSON();
    return utcDate;
};
