import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export let STATUS = {
  IDLE: "idle",
  LOADING: "Loading",
  ERROR: "error",
};

// const stepperState = {
//     activeStep:1,
//     completed:{0:true}
//  };

const botsSlice = createSlice({
  name: "bot",
  initialState: {
    botVersion: {
      list: [], // [{ _id : string, version :string }]
      selectedBotVersion: "",
    },
    data: {},
    completedSteps: [],
    isSettingsOpen: false,
    status: STATUS.IDLE,
  },
  reducers: {
    AddNewBot: (state, action) => {
      state.data = action.payload;
    },
    UpdateBotStatus: (state, action) => {
      state.data.status = action.payload;
    },
    UpdateStepper: (state, action) => {
      let isCompleted = state.completedSteps.includes(action.payload);
      if (isCompleted) return;
      state.completedSteps.push(action.payload);
    },
    NextStepStepper: (state, action) => {
      let isCompleted = state.completedSteps.includes(action.payload);
      if (isCompleted) return;
      state.completedSteps.push(action.payload);
    },
    BackStepStepper: (state, action) => {
      state.completedSteps.pop();
    },
    DeleteBot: (state, action) => {
      state.data = {};
      state.completedSteps = [];
      state.botVersion = [];
      state.status = STATUS.IDLE;
    },
    ResetStepper: (state, action) => {
      state.completedSteps = action.payload || [];
    },
    ResetState: (state, action) => {
      state.data = {};
      state.completedSteps = [];
      state.botVersion = {
        list: [], // [{ _id : string, version :string }]
        selectedBotVersion: "",
      };
      state.isSettingsOpen = false;
      state.status = STATUS.IDLE;
    },
    UpdateBotVersion: (state, action) => {
      let selectedVersion = "";

      if (action.payload.type === 'initial') {
        if (action.payload.versions.length > 0 && action.payload.versions[0].version) {
          selectedVersion = action.payload.versions[0].version;
        }
        state.botVersion = {
          list: action.payload.versions,
          selectedBotVersion: state.botVersion.selectedBotVersion === "" ? selectedVersion : state.botVersion.selectedBotVersion
        }
      }

      if (action.payload.type === 'edit') {
        if (action.payload.versions.length > 0 && action.payload.versions[0].version) {
          selectedVersion = action.payload.versions[0].version;
        }
        state.botVersion = {
          list: action.payload.versions,
          selectedBotVersion: selectedVersion
        }
      }

      if (action.payload.type === 'deploy') {
        if (action.payload.versions.length > 0 && action.payload.versions[0].version) {
          selectedVersion = action.payload.versions[0].version;
        }
        state.botVersion = {
          list: action.payload.versions,
          selectedBotVersion: selectedVersion
        }
      }

    },
    SelectBotVersion: (state, action) => {
      state.botVersion.selectedBotVersion = action.payload;
    },
    SetOpenSettings: (state, action) => {
      state.isSettingsOpen = action.payload;
    },
    UpdateBotLocales: (state, action) => {
      state.data.locales = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewBot.pending, (state, action) => {
        state.status = STATUS.LOADING;
      })
      .addCase(fetchNewBot.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUS.IDLE;
      })
      .addCase(fetchNewBot.rejected, (state, action) => {
        state.status = STATUS.ERROR;
      });
  },
});

export const {
  AddNewBot,
  UpdateBotStatus,
  ResetState,
  UpdateBotVersion,
  SelectBotVersion,
  SetOpenSettings,
  UpdateBotLocales,
} = botsSlice.actions;

export default botsSlice.reducer;

// Thunks async  actions
export const fetchNewBot = createAsyncThunk("bots/addNew", async () => {
  // const res = await fetch('http');
  // const data = await res.json();
  // return data;
});
