import merge from "deepmerge";
import { green, grey } from "@mui/material/colors";
import { THEMES } from "../constants";
import { flowBuilderDarkThemeObject, flowBuilderLightThemeObject } from "./workflowBuilderTheme";

const customBlue = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#918AFF",
  400: "#918AFF",
  500: "#1180FF",
  600: "#918AFF",
  700: "#1180FF",
  800: "#918AFF",
  900: "#2052c2",
};


let customDefault = {
  50: "#e9f0fb",
  100: "#c8daf4",
  200: "#a3c1ed",
  300: "#918AFF",
  400: "#918AFF",
  500: "#1180FF",
  600: "#918AFF",
  700: "#1180FF",
  800: "#918AFF",
  900: "#2052c2",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customDefault[700],
      contrastText: "#FFF",
    },
    secondary: {
      main: customDefault[700],
      contrastText: "#FFF",
      secondaryText: customDefault[500],
    },
    background: {
      default: "#f9f9f9",
      paper: "#FFF",
    },
  },
  header: {
    color: grey[500],
    background: "#FFF",
    fontColor: "#02121C",
    search: {
      color: grey[800],
      background: "#DCE7EF",
    },
    indicator: {
      background: customDefault[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#ffffff",
    header: {
      color: grey[200],
      background: "#282C54",
      brand: {
        color: customDefault[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#282C54",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#000000",
      background: customDefault[500],
    },
  },
  workflowBuilder: { ...flowBuilderLightThemeObject },
  tableHeader: {
    color: "#2D3152",
    background: "#E5E8EC",
  },

};

const darkVariant = merge(defaultVariant, {
  name: THEMES.DARK,
  palette: {
    mode: "dark",
    primary: {
      main: customBlue[500],
      contrastText: "#FFF",
    },
    secondary: {
      main: customDefault[700],
      contrastText: "#FFF",
      secondaryText: customDefault[500],
    },
    background: {
      default: "#001226",
      paper: "#031A33",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.95)",
      secondary: "rgba(255, 255, 255, 0.5)",
    },
  },
  sidebar: {
    color: grey[200],
    background: "#031A33",
    header: {
      color: grey[300],
      background: "#1E223D",
      fontColor: "#fff",
      search: {
        color: grey[200],
        background: "#1B2635",
      },
    },
  },
  footer: {
    color: grey[300],
    background: "#031A33",
  },
  tableHeader: {
    color: grey[300],
    background: "#02121C",
  },
  workflowBuilder: { ...flowBuilderDarkThemeObject }
});


const variants = [
  defaultVariant,
  darkVariant,
];

export default variants;
