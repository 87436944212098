import axios from "axios";
import { appConfig } from "../config";

const axiosInstance = axios.create({
    baseURL: appConfig.builderServerUrl,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(error || "Something went wrong")
);

export default axiosInstance;