import React from "react";
import {matchPath} from "react-router-dom";
import SidebarNavListItem from "./SidebarNavListItem";
import SidebarNavList from "./SidebarNavList";

const reduceChildRoutes = (props) => {
    const {items, index, page, depth, currentRoute, isBotsScreen} = props;

    if (page.children) {
        const open = page.href
            ? !!matchPath(
                {
                    path: page.href,
                    end: false,
                },
                currentRoute
            )
            : false;

        items.push(
            <div key={index}>
                <SidebarNavListItem
                    depth={depth}
                    icon={page.icon}
                    key={page.title}
                    badge={page.badge}
                    open={!!open}
                    title={!isBotsScreen ? page.title : ""}
                    href={page.href}
                >
                    {isBotsScreen &&
                        <SidebarNavList depth={depth} pages={page.children} isBotsScreen={isBotsScreen}/>}
                    {!isBotsScreen &&
                        <SidebarNavList depth={depth + 1} pages={page.children} isBotsScreen={isBotsScreen}/>}
                </SidebarNavListItem>
            </div>
        );
    } else {
        items.push(
            <div key={index}>
                <SidebarNavListItem
                    depth={depth}
                    href={page.href}
                    icon={page.icon}
                    key={page.title}
                    badge={page.badge}
                    title={page.title}
                />
            </div>
        );
    }

    return items;
};

export default reduceChildRoutes;
