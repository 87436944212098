import styled from "@emotion/styled";
import {
    Box,
    CssBaseline,
    Paper as MuiPaper
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import GlobalStyle from "../components/GlobalStyle";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import NotificationsReport from "../pages/pages/Notifications";
import { authorizePage, getPermission, isAccessible } from "../utils/AccessControl";
import { ModuleNames } from "../utils/Constants/AccessControl";
import { useDispatch } from "react-redux";
import { SetPagePermissions } from "../redux/slices/orgData";

const drawerWidth = 110;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;


const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const FlowBuilderLayout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [botsOpen, setBotsOpen] = useState(false);
    const [isOpen, setIsOen] = useState(false);
    const [pagePermission, setPagePermission] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const params = useParams();
    // const path = RouteUtility.getRoutePathPattern(location, params);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const openNotification = async () => {
        await setIsOen(!isOpen);
    };


    useEffect(() => {
        (async () => {
            let permissions = await getPermission(location.pathname.split('/')[1]);

            let sideBarModulePermission = permissions.find(x => x.module === ModuleNames.SidebarMenu);
            sideBarModulePermission = (sideBarModulePermission && sideBarModulePermission.permission) ? sideBarModulePermission.permission : 0;
            setPagePermission(sideBarModulePermission);

            if (!await authorizePage(sideBarModulePermission, location.pathname.split('/')[1])) {
                return navigate('/401');
            }
            await dispatch(SetPagePermissions(permissions));
        })();


        if ((location.pathname).includes('/bots/') || (location.pathname).includes('/workflow/') || (location.pathname).includes('/master-workflow/')) {
            setBotsOpen(true);
        } else {
            setBotsOpen(false);
        }
        // eslint-disable-next-line
    }, [location]);

    const getDashboardItems = () => {
        if (pagePermission <= 0) return;
        let items = dashboardItems;
        let pages = [];
        for (let i = 0; i < items[0].pages.length; i++) {
            if (isAccessible(pagePermission, items[0].pages[i].permission_code)) {
                pages.push(items[0].pages[i]);
            }
        }
        items[0].pages = pages;

        return items;
    }

    return (<Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
            <Box sx={{ display: { xs: "block", lg: "none" } }}>
                <Sidebar
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    items={getDashboardItems()}
                    isBotsScreen={true}
                />
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Sidebar
                    PaperProps={{ style: { width: drawerWidth } }}
                    items={getDashboardItems()}
                    isBotsScreen={true}
                    permission={252}
                />
            </Box>
        </Drawer>
        <AppContent>
            {/* <Navbar onDrawerToggle={handleDrawerToggle} openNotification={openNotification} /> */}
            <MainContent>
                {children}
                <Outlet />
            </MainContent>
            {!botsOpen && <Footer />}
        </AppContent>
        {/*<Settings/>*/}
        <NotificationsReport openNotification={openNotification} isOpen={isOpen} />
    </Root>);
};

export default FlowBuilderLayout;
