import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const botsListSlice = createSlice({
    name: "bots list",
    initialState: {
        data: { bots: [], totalCount: 0, page: 1, searchText: '' },
        archivesData: { archivesBots: [], totalRecords: 0, totalCount: 0, page: 1, searchText: '' },
        status: STATUS.IDLE
    },
    reducers: {
        SetBotsList: (state, action) => {
            state.data.bots = action.payload.bots;
            state.data.totalCount = action.payload.totalCount;
        },
        updateBotsSearchTextPage: (state, action) => {
            state.data.searchText = action.payload.searchText;
            state.data.page = action.payload.page;
        },
        SetArchivesBotsList: (state, action) => {
            state.archivesData.archivesBots = action.payload.versions;
            state.archivesData.totalCount = action.payload.totalCount;
            state.archivesData.totalRecords = action.payload.totalRecords;
        },
        updateArchivesBotsSearchTextPage: (state, action) => {
            state.archivesData.searchText = action.payload.searchText;
            state.archivesData.page = action.payload.page;
        },
        ResetBotsState: (state, action) => {
            state.data = { bots: [], totalCount: 0, page: 1, searchText: '' };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteBot.pending, (state, action) => {
                state.status = STATUS.LOADING;
            })
            .addCase(deleteBot.fulfilled, (state, action) => {
                let id = action.payload;
                let BotsList = state.data.bots;
                state.data.bots = BotsList.filter(bot => bot._id && bot._id.toString() !== id.toString());
                state.status = STATUS.IDLE;
            })
            .addCase(deleteBot.rejected, (state, action) => {
                state.status = STATUS.ERROR;
            });
    }

});

export const { SetBotsList, updateBotsSearchTextPage, ResetBotsState, SetArchivesBotsList, updateArchivesBotsSearchTextPage } = botsListSlice.actions;

export default botsListSlice.reducer;

// Thunks async  actions
export const deleteBot = createAsyncThunk('bots/delete', async (id) => {
    await axios.delete(`bots/${id}`);
    return id;
});