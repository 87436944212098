import React from "react";
import styled from "@emotion/styled";
import { rgba } from "polished";
import { Box, Container, Grid, Typography } from "@mui/material";
import { spacing } from "@mui/system";

import {
  Mail as MailIcon,
  Code as CodeIcon,
  Users as UsersIcon,
  Figma as FigmaIcon,
  BookOpen as BookOpenIcon,
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";

const Wrapper = styled.div`
  ${spacing};
  //background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;


const FeatureWrapper = styled.div`
  display: flex;
  text-align: left;
  padding: 18px 20px;
`;

const FeatureIcon = styled.div`
  svg {
    flex-shrink: 0;
    width: auto;
    height: 48px;
    width: 48px;
    background: ${(props) => rgba(props.theme.palette.primary.main, 0.15)};
    color: ${(props) => props.theme.palette.primary.main};
    padding: 10px;
    border-radius: 50%;
  }
`;


const Feature = ({ Icon, title, description }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <FeatureWrapper>
        <FeatureIcon>
          <Icon />
        </FeatureIcon>
        <Box ml={6}>
          <Typography variant="h4" gutterBottom>
            {title}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
        </Box>
      </FeatureWrapper>
    </Grid>
  );
};

function Features() {
  const {t,ready}=useTranslation('LandingPage')
  if(!ready) return <Loader />
  return (
    <Wrapper py={20}>
      <Container>
        <Typography variant="h2" component="h3" color="#000" gutterBottom>
          {t("features")}
        </Typography>
        <Box mb={8} />
        <Grid container spacing={6}>
          <Feature
            Icon={CodeIcon}
            title={t("TrueNoCodeLowCode")}
            description={"1SPOC is a true no-code low-code platform even for the most complex workflows"}
          />
          <Feature
            Icon={PlusCircleIcon}
            title={"Seamless Integration"}
            description={"Hassle-free 3rd Party Integrations with applications of your choice"}
          />
          <Feature
            Icon={MailIcon}
            title={"Hyper Personalized"}
            description={"Free support to build cognitive conversations to drive hyper-personalized bot experience"}
          />
          <Feature
            Icon={BookOpenIcon}
            title={"Intelligent Decision Nodes"}
            description={"Experience the ease of building complicated workflows with intelligent decision nodes"}
          />
          <Feature
            Icon={UsersIcon}
            title={"Live Agent"}
            description={"Integrated Live Agent Channel for beyond Conversations across the channels"}
          />
          <Feature
            Icon={FigmaIcon}
            title={"Data Security"}
            description= {"Impeccable standards of data security with end-to-end encryption"}
          />
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Features;
