import {createSlice} from "@reduxjs/toolkit";

const usersListSlice = createSlice({
    name: 'Users',
    initialState: {
        UsersList: {users: [], totalCount: 0, totalRecords: 0, searchText: "", page: 1},
        UserData: {},
        UserChannel: [],
        importLogs: {},
        errorRecords: {records: [], count: 0},
        ImportsHistory: {importLogs: [], totalCount: 0, totalRecords: 0, searchText: "", page: 1}
    },
    reducers: {
        SetUsersList: (state, action) => {
            state.UsersList.totalCount = action.payload.totalCount;
            state.UsersList.users = action.payload.users;
        },
        SetUserData: (state, action) => {
            state.UserData = action.payload;
        },
        DeleteUserData: (state, action) => {
            state.UsersList.users = state.UsersList.users.filter(user => user._id !== action.payload);
            state.UserData = {};
        },
        SetUserChannel: (state, action) => {
            state.UserChannel = action.payload;
        },
        pushUserChannel: (state, action) => {
            let indexOfChannel = state.UserChannel.findIndex( x => x._id === action.payload._id);
            if (indexOfChannel < 0) {
                state.UserChannel.push(action.payload);
            } else {
                state.UserChannel[indexOfChannel] = action.payload;
            }
        },
        DeleteUserChannel: (state, action) => {
            state.UserChannel = state.UserChannel.filter(channel => channel._id !== action.payload);
        },
        SetImportLogData: (state, action) => {
            state.importLogs = action.payload;
        },
        SetErrorRecords: (state, action) => {
            state.errorRecords = action.payload;
        },
        SetImportsHistoryList: (state, action) => {
            state.ImportsHistory.totalCount = action.payload.totalCount;
            state.ImportsHistory.importLogs = action.payload.importLogs;
        },
        updateImportsHistorySearchTextPage: (state, action) => {
            state.ImportsHistory.searchText = action.payload.searchText;
            state.ImportsHistory.page = action.payload.page;
        },
        updateUsersSearchTextPage: (state, action) => {
            state.UsersList.searchText = action.payload.searchText;
            state.UsersList.page = action.payload.page;
        },
        resetState: (state, action) => {
            state.UserData = {};
            state.UserChannel = [];
            state.importLogs = {};
            state.errorRecords = {records: [], count: 0};
        },
        resetUserList: (state)=>{
            state.UsersList = {users: [], totalCount: 0, totalRecords: 0, searchText: "", page: 1};
        },
        resetImportsHistoryList: (state)=>{
            state.ImportsHistory = {importLogs: [], totalCount: 0, totalRecords: 0, searchText: "", page: 1};
        }
    },
})

export const {
    SetUsersList, SetUserChannel, SetUserData, DeleteUserData, resetState, pushUserChannel, DeleteUserChannel, SetImportLogData, SetErrorRecords,
    SetImportsHistoryList,updateImportsHistorySearchTextPage, updateUsersSearchTextPage, resetUserList, resetImportsHistoryList
} = usersListSlice.actions;

export default usersListSlice.reducer;
