import {createSlice} from "@reduxjs/toolkit";

export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const channelsSlice = createSlice({
    name: 'Channels',
    initialState: {
        channelIntegrations: [],
        utilityIntegrations: [],
        tempChannels: [],
        status: STATUS.IDLE
    },
    reducers: {
        SetChannelData: (state, action) => {         
            state.channelIntegrations = action.payload.filter(integration => !integration.utilityIntegration);
            state.utilityIntegrations = action.payload.filter(integration => integration.utilityIntegration);
        },
        AddChannelData: (state, action) => {
            if (action.payload.utilityIntegration) {
                state.utilityIntegrations.push(action.payload)
            } else {
                state.channelIntegrations.push(action.payload)
            }
        },
        AddTempChannels: (state, action) => {
            state.tempChannels.push(action.payload)
        },
        ResetTempChannels: (state) => {
            state.tempChannels = [];
        },
        UpdateChannelData: (state, action) => {
            let channelIndex = state.data.findIndex(x => x.type === action.payload.type);
            let data = state.data;
            data[channelIndex].config = action.payload.config;
            state.data = data;
        },
        DeleteChannelData: (state, action) => {
            if (action.payload.utilityIntegration) {
                state.utilityIntegrations = state.utilityIntegrations.filter(x => x._id !== action.payload._id);
            } else {
                state.channelIntegrations = state.channelIntegrations.filter(x => x._id !== action.payload._id);
            }
        },
        ResetChannelState: (state, action) => {
            state.data = [];
            state.status = STATUS.IDLE;
        },

    },
})

export const {
    SetChannelData,
    AddChannelData,
    UpdateChannelData,
    DeleteChannelData,
    ResetChannelState,
    AddTempChannels,
    ResetTempChannels
} = channelsSlice.actions;

export default channelsSlice.reducer;
