import React, { Fragment } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Unauthorized from "../../../src/assets/SVG/Unauthorized.svg";
import { Button as MuiButton, CircularProgress, Typography, Grid } from "@mui/material";
import { Box, spacing } from "@mui/system";
import { useSelector } from "react-redux";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page401() {
    const LogedInTeamMember = useSelector(store => store.org.LogedInTeamMember);
    const navigate = useNavigate();

    const handleRedirect = () => {
        const accessToken = window.localStorage.getItem("accessToken");
        if (LogedInTeamMember.role === 'editor' && accessToken) {
            navigate("/bots");
        } else if (accessToken && LogedInTeamMember.role !== 'editor') {
            navigate("/dashboard");
        }
    }

    return (
        <Grid
            container
            sx={{
                minHeight: "70vh",
                alignItems: "center",
                justifyContent: "center",
                gap: '8rem',
                // backgroundColor: "#f4f0f5",
                // position: "relative",
                boxShadow: 9,
                width: "90rem",
                marginLeft: "20px !important"
            }}
        >
            <Helmet title="401 Unauthorized" />

            <Grid>
                {/* {Object.keys(LogedInTeamMember).length === 0 ? (
                    <Fragment>
                        <CircularProgress />
                    </Fragment>
                ) : ( */}
                <Grid sx={{ textAlign: "left" }}>
                    <Typography variant="h1" color={'#525252'}>
                        Oops! <br /> Unauthorized.
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: 5 }}>
                        You are not authorized to access this page.
                        <br /> Contact us at  <a href="mailto:support@1spoc.ai" style={{ "text-decoration": "none", "color": "black" }}>support@1spoc.ai</a>.
                    </Typography>
                    <Button
                        onClick={handleRedirect}
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ mt: 8 }}
                    >
                        Return to Application
                    </Button>
                </Grid>
                {/* )} */}
            </Grid>
            <img src={Unauthorized} style={{
                width: '38rem',
                height: "25rem"
            }} />
        </Grid>
    );
}

export default Page401;
