import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
  IDLE: "idle",
  LOADING: "Loading",
  ERROR: "error",
};

const SubscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    plans: [],
    selectedPlan: {},
    currentSubscription: {},
    currentPlan: {},
    status: STATUS.IDLE
  },
  reducers: {
    SetPlans: (state, action) => {
      let allPlans = action.payload;

      // allPlans.push({
      //   type: "paid",
      //   name: "Enterprise plan",
      //   plan_code: "1spoc_enterprise",
      //   status: "active",
      //   recurring_price: "Custom",
      //   description: "Plane for large enterprises, Customized features and limits, Contact sales for details"
      // })

      state.plans = allPlans;
    },
    CurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    SelectPlans: (state, action) => {
      state.selectedPlan = action.payload;
    },
    SetSubscriptions: (state, action) => {
      state.currentSubscription = action.payload;
    }
  }
});

export const {
  SetPlans,
  SelectPlans,
  SetSubscriptions,
  CurrentPlan
} = SubscriptionSlice.actions;

export default SubscriptionSlice.reducer;