import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
    Menu as MuiMenu, Typography,
    IconButton as MuiIconButton, Grid, Avatar, List, ListItemButton, ListItemText, ListItemIcon,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ModuleNames, Permissions } from "../../utils/Constants/AccessControl";
import { isAccessible } from "../../utils/AccessControl";
import Loader from "../Loader";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
    margin: 0.5px;
  }

  &:hover {
    background-color: transparent;
  }
`;

const Ellipsis = styled.span`
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  width: 10vw;
  white-space: nowrap;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  font-family: inherit;
`;

const AvatarText = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.h5.fontWeight};
  display: inline-block;
`;

const Menu = styled(MuiMenu)`
  .MuiMenu-list {
    padding: 0;
    border: 1px solid #dee2e6;
    border-radius: 16px;
    box-shadow: 5px 4px 4px 2px rgb(0 0 0 / 20%) !important;
  }
`;

function NavbarUserDropdown() {
    const navigate = useNavigate();
    const { signOut } = useAuth();
    const LogedInTeamMember = useSelector((store) => store.org.LogedInTeamMember);
    const { t, ready } = useTranslation(['navbar', 'global']);
    const permissions = useSelector((store) => store.org.permissions);
    let page_permission = permissions.find(x => x.module === ModuleNames.SidebarMenu);
    const profile = document.getElementById('profile');
    const userMenu = useRef(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const openMenu = () => {
        setIsMenuOpen(true);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    /*const handleSubscription = () => {
        navigate('/settings/subscription');
        setIsMenuOpen(false);
    }*/

    const handleSetting = () => {
        navigate('/settings/account')
        setIsMenuOpen(false);
    }

    const handleCompDetails = () => {
        navigate('/settings/company-details')
        setIsMenuOpen(false);
    }

    const handleSignOut = async () => {
        await signOut();
    };

    if (!ready) return <Loader />

    return (
        <React.Fragment>
            <Grid className="d-flex align-items-center" id="profile">
                <IconButton
                    component="span"
                    aria-owns={isMenuOpen ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onMouseEnter={openMenu}
                    onMouseLeave={closeMenu}
                    ref={userMenu}
                    color="inherit"
                    size="large"
                    sx={{ borderRadius: '5px' }}
                >
                    {!!LogedInTeamMember &&
                        <Avatar alt={LogedInTeamMember?.name} src={LogedInTeamMember?.profile_img} />}
                    {!LogedInTeamMember && <Avatar alt="User" src="User" />}
                </IconButton>
            </Grid>

            <Menu
                id="menu-appbar"
                anchorEl={userMenu.current}
                open={isMenuOpen}
                onClose={closeMenu}
                PaperProps={{
                    style: {
                        pointerEvents: 'auto',
                        boxShadow: 'rgb(0 0 0 / 5%) 5px 4px 4px 2px',
                        borderRadius: "16px"
                    }
                }}
                MenuListProps={{ onMouseEnter: openMenu, onMouseLeave: closeMenu }}
                sx={{ left: profile ? 3 * profile.offsetLeft : 0, pointerEvents: 'none' }}
            >
                <List sx={{ padding: 0 }}>
                    <ListItemButton className='square border-bottom' dense>
                        <ListItemIcon>
                            {!!LogedInTeamMember &&
                                <Avatar alt={LogedInTeamMember?.name} src={LogedInTeamMember?.profile_img} />}
                            {!LogedInTeamMember && <Avatar alt="User" src="/static/img/avatars/avatar-1.jpg" />}
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={LogedInTeamMember && (<AvatarText variant='subtitle1'
                                lineHeight={1.3}><Ellipsis >{LogedInTeamMember.name}</Ellipsis></AvatarText>)}
                            secondary={LogedInTeamMember && (<Typography lineHeight={1.3}
                            ><Ellipsis>{LogedInTeamMember.email || 'Admin'}</Ellipsis></Typography>)}
                        />
                    </ListItemButton>
                    <ListItemButton onClick={handleSetting} className='pt-2' dense>
                        <ListItemText
                            disableTypography
                            primary={<Typography component={'span'} variant="body1">{t("account", { ns: 'global' })}</Typography>}
                        />
                    </ListItemButton>
                    {page_permission && isAccessible(page_permission.permission, Permissions.Sidebar.CompanyDetails) &&
                        <ListItemButton onClick={handleCompDetails} className="square pb-2" dense>
                            <ListItemText
                                disableTypography
                                primary={<Typography component={'span'} variant="body1">{t("companyDetails")}</Typography>}
                            />
                        </ListItemButton>}
                    <ListItemButton onClick={handleSignOut} className='py-2 border-top' dense>
                        <ListItemText
                            disableTypography
                            primary={<Typography component={'span'} variant="body1">{t("signOut")}</Typography>}
                        />
                    </ListItemButton>
                </List>
                {/*{page_permission && isAccessible(page_permission.permission, Permissions.Sidebar.Subscription) &&*/}
                {/*    <MenuItem onClick={handleSubscription} className="square border-bottom py-2"><CreditCardIcon*/}
                {/*        size={20} className='me-2' fontSize='medium' color="secondary"/>*/}
                {/*        <b>{t("subscription")}</b></MenuItem>}*/}
            </Menu>
        </React.Fragment>
    );
}

export default NavbarUserDropdown;
