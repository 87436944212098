import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: [],
    integrationConfig: [],
    orgIntegrations: { orgIntegrations: [], totalCount: 0, totalRecords: 0, searchText: "", page: 1 },
    selectedOrgIntegration: {},
    installedIntegrations: [],
};

export const IntegrationSlice = createSlice({
    name: "Integrate",
    initialState,
    reducers: {
        pushed: (state, action) => {
            state.data = action.payload;
        },
        updateMainIntegration: (state, action) => {
            const allIntegrations = state.data;
            let data = allIntegrations.map(x => {
                if (x._id === action.payload) {
                    x.installed = true;
                }

                return x;
            });
            state.data = data;
        },
        pushIntegrationConfig: (state, action) => {
            state.integrationConfig = action.payload;
        },

        setSelectedIntegration: (state, action) => {
            state.selectedOrgIntegration = action.payload;
        },

        updateOrgIntegrationSearchTextPage: (state, action) => {
            state.orgIntegrations.searchText = action.payload.searchText;
            state.orgIntegrations.page = action.payload.page;
        },
        pushOrgIntegrations: (state, action) => {
            state.orgIntegrations.orgIntegrations = action.payload.orgIntegrations;
            state.orgIntegrations.totalCount = action.payload.totalCount;
            state.orgIntegrations.totalRecords = action.payload.totalRecords;
        },

        pushOrgIntegrationDashboard: (state, action) => {
            state.orgIntegrations.orgIntegrations.push(action.payload);
        },
        UpdatePushOrgIntegrationDashboard: (state, action) => {
            const orgIntegrations = state.orgIntegrations.orgIntegrations;
            let filterOrgIntegrations = orgIntegrations.filter(x => x._id !== action.payload._id);
            filterOrgIntegrations.push(action.payload);
            state.orgIntegrations.orgIntegrations = filterOrgIntegrations;
        },

        deleteOrgIntegration: (state, action) => {
            let data = state.orgIntegrations
            state.orgIntegrations.orgIntegrations = data.orgIntegrations.filter(x => x._id.toString() !== action.payload.toString());
        },

        resetState: (state, action) => {
            state.orgIntegrations.orgIntegrations = [];
            state.orgIntegrations.totalCount = 0;
            state.orgIntegrations.searchText = "";
            state.orgIntegrations.page = 1;
            state.integrationConfig = [];
            state.selectedOrgIntegration = {};
        }
    }
});

export const { pushed, updateMainIntegration, pushIntegrationConfig, setSelectedIntegration, pushOrgIntegrations, updateOrgIntegrationSearchTextPage, UpdatePushOrgIntegrationDashboard,
    resetState, deleteOrgIntegration, pushOrgIntegrationDashboard } = IntegrationSlice.actions;
export default IntegrationSlice.reducer;
