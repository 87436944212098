const typography = {
  fontFamily: [
    "proxima-nova",
    "Inter",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  fontSize: 13,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  h1: {
    fontSize: "2.25rem",
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h2: {
    fontSize: "2rem",
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h3: {
    fontSize: "1.75rem",
    fontWeight: 600,
    lineHeight: 1.25,
  },
  h4: {
    fontSize: "1.5rem",
    fontWeight: 550,
    lineHeight: 1.25,
  },
  h5: {
    fontSize: "1.25rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
  h6: {
    fontSize: "1.125rem",
    fontWeight: 500,
    lineHeight: 1.25,
  },
   body1: {
    fontSize: "0.875rem",
    color: '#67727B',
  },
  body2: {
    fontSize : "1rem",
    fontWeight : 400,
  },
  button: {
    textTransform: "none",
  },
  caption: {
    fontSize: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  caption1:{
    fontSize: "12px !important",
  },
  errorFont: {
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#d32f2f',
    marginTop: '0.20rem',
    marginLeft: '0.875rem'
  },
};

export default typography;
