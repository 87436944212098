import {createSlice} from "@reduxjs/toolkit";

export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const botsTemplatesListSlice = createSlice({
    name: "botsTemplateList",
    initialState: {
        data: [],
        botsTemplates: [],
        status: STATUS.IDLE
    },
    reducers: {
        SetBotsTemplateList: (state, action) => {
            state.botsTemplates = action.payload;
        },
        resetState:(state, action)=>{
            state.data = [];
            state.botsTemplates = [];
            state.status = STATUS.IDLE;
        }
    }
});

export const {SetBotsTemplateList, resetState} = botsTemplatesListSlice.actions;

export default botsTemplatesListSlice.reducer;
