import {Global, css} from "@emotion/react";


const GlobalStyle = (props) => (

    <Global
        {...props}
        styles={css`
          html,
          body,
          #root {
            height: 100%;
          }

          body {
            margin: 0;
            //font-family: 'Poppins' !important;
          }

          p {
            margin-bottom: 0 !important;
          }

          .font-theme {
            font-family: Poppins, Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
          }

          //sidebar fonts
          .css-1tdh1ss-MuiListItemText-root span {
            font-size: 16px !important;
          }

          //sidebar icon
          .css-8laq16-MuiButtonBase-root-MuiListItemButton-root svg {
            width: 25px;
            height: 25px;
            font-weight: bold;
          }

          .MuiCardHeader-action .MuiIconButton-root {
            padding: 4px;
            width: 28px;
            height: 28px;
          }

          body > iframe {
            pointer-events: none;
          }

          .cursor-pointer {
            cursor: pointer !important;
          }

          Button {
            font-size: 16px !important;
          }

          .btn-theme {
            min-width: 100px !important;
            font-size: 16px !important;
          }

          .btn-light {
            color: #918AFF !important;
          }


          //scrollbar hide class
          .scrollBarHide {
            ::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
              background-color: transparent;
              border-radius: 10px;
            }

            ::-webkit-scrollbar {
              width: 6px;
              background-color: transparent;
            }

            ::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #7A7A7A5F;
            }

            ::-webkit-scrollbar-button {
              height: 30px;
            }
          }

          //scrollbar styling
          ::-webkit-scrollbar {
            width: 6px;
            background-color: transparent;
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background-color: #7A7A7A5F;
          }
          
          //phone no container 
          .react-tel-input.contact-number-input.invalid input[type='tel'] {
            border: 1px solid #ce0b0b !important;

            &:focus {
              border: 1px solid #ce0b0b !important;
            }
          }

          .react-tel-input.contact-number-input.invalid .selected-flag {
            border: 1px solid #ce0b0b !important;

            &:focus {
              border: 1px solid #ce0b0b !important;
            }
          }

          .react-tel-input .flag-dropdown.open-dropdown {
            border-radius: 8px 0 0 8px !important;
          }

          .react-tel-input .flag-dropdown.open-dropdown .selected-flag {
            border-radius: 8px 0 0 8px !important;
          }


          .card-div {
            background-color: white !important;
            border-radius: 4px !important;
          }

          .setting-submenu {
            position: absolute;
          }

          .text-name {
            width: 100%;
            height: 30px;
            position: relative;
            overflow: hidden;
            text-align: center;
            color: white;
          }

          .text-name:after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            bottom: 0;
            top: -5px;
            left: 52%;
            height: 30px;
            width: 100%;
            border-width: 5px;
            border-style: solid;
            border-color: #1180FF transparent transparent transparent;
            transform: translate(-49%, 49%);
            box-shadow: 0 0 0 200px transparent;
            border-radius: 50%;
          }
          
          .MuiSwitch-thumb{
          box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
          }

        `}
    />
);

export default GlobalStyle;
