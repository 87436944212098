import useAuth from "../../hooks/useAuth";
import React, {useEffect} from "react";
import { CircularProgress } from "@mui/material";
function SignOut() {
    const { signOut } = useAuth();

    useEffect(()=>{
        (async function(){
              await signOut();
        })();
        // eslint-disable-next-line
    },[])
    return (
        <>
            <CircularProgress m={2} />
        </>
    );
}

export default SignOut;