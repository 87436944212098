import { createSlice } from "@reduxjs/toolkit";


export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const teamMembersListSlice = createSlice({
    name: "Team",
    initialState: {
        data: [],
        totalCount: 0,
        totalData: [],
        selectedTeamMember: {},
        availableBots: [],
        botsCount: 0,
        filterData: { searchText: "", pageNumber: 1, pageSize: 10, businessunit_id: '' },
        status: STATUS.IDLE,
        availableProcessContainers: [],
        processContainersCount: 0,
        availableDocuwiseModels: [],
        docuwiseModelsCount: 0,
    },
    reducers: {
        SetTeamList: (state, action) => {
            state.data = action.payload.teamMembers;
            state.totalCount = action.payload.totalCount;
        },
        UpdateTeamList: (state, action) => {
            let updateTeamDataIndex = state.data.findIndex(item => item._id === action.payload._id);
            state.data[updateTeamDataIndex] = action.payload;
        },
        SetSelectedTeamMember: (state, action) => {
            state.selectedTeamMember = action.payload;
        },
        SetFilterData: (state, action) => {
            state.filterData.searchText = action.payload.searchText;
            state.filterData.pageNumber = action.payload.pageNumber;
            state.filterData.businessunit_id = action.payload.businessunit_id;
        },
        SetSelectedBots: (state, action) => {
            state.availableBots = action.payload.availableBots;
            state.botsCount = action.payload.botsCount;
            state.availableProcessContainers = action.payload.availableProcessContainers;
            state.processContainersCount = action.payload.processContainersCount;
            state.availableDocuwiseModels = action.payload.availableDocuwiseModels;
            state.docuwiseModelsCount = action.payload.docuwiseModelsCount;
        },
        DeleteTeamMember: (state, action) => {
            state.data = state.data.filter(member => member._id !== action.payload);
        },
        ResetData: (state, action) => {
            state.filterData = { searchText: "", pageNumber: 1, pageSize: 10, businessunit_id: '' }
            state.data = [];
            state.totalCount = 0;
        }
    }
})

export const {
    SetTeamList,
    DeleteTeamMember,
    SetSelectedBots,
    UpdateTeamList,
    SetFilterData,
    SetSelectedTeamMember,
    ResetData,
    ResetFilterData
} = teamMembersListSlice.actions;

export default teamMembersListSlice.reducer;
