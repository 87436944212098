import {createSlice} from "@reduxjs/toolkit";


export const BusinessUnitSlice = createSlice({
    name: "BusinessUnit",
    initialState: {
        BusinessUnitList: {BusinessUnits: [], totalCount: 0, searchText: ""},
        BusinessUnitData: {is_billing_unit: false},
    },
    reducers: {
        SetBusinessUnitList: (state, action) => {
            state.BusinessUnitList.BusinessUnits = action.payload.data.BusinessUnits
            state.BusinessUnitList.totalCount = action.payload.data.totalCount
        },
        SetBusinessUnitData: (state, action) => {
            state.BusinessUnitData = action.payload.data;
        },
        SetFilterData: (state, action) => {
            state.BusinessUnitList.searchText = action.payload.searchText;
            state.BusinessUnitList.page = action.payload.page;
        },
        SetAppendBusinessUnitList: (state, action) => {
            state.BusinessUnitList.BusinessUnits.push(...action.payload.data.BusinessUnits);
            state.BusinessUnitList.totalCount = action.payload.data.totalCount
        },
        resetBusinessUnitList: (state) => {
            state.BusinessUnitList = {BusinessUnits: [], totalCount: 0, searchText: "", page: 1};
        },
        resetBusinessUnitData: (state) => {
            state.BusinessUnitData = {}
        },
        DeleteBusinessUnitList: (state, action) => {
            state.BusinessUnitList.BusinessUnits = state.BusinessUnitList.BusinessUnits.filter(channel => channel._id !== action.payload);
        },
        DeleteBusinessUnitData: (state, action) => {
            state.BusinessUnitList.BusinessUnits = state.BusinessUnitList.BusinessUnits.filter(channel => channel._id !== action.payload);
            state.BusinessUnitData = {};
        },
    }
});
export const {
    SetBusinessUnitList,
    SetBusinessUnitData,
    SetFilterData,
    resetBusinessUnitList,
    resetBusinessUnitData,
    SetAppendBusinessUnitList,
    DeleteBusinessUnitList,
    DeleteBusinessUnitData
} = BusinessUnitSlice.actions;
export default BusinessUnitSlice.reducer;