import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
    IDLE: "idle",
    LOADING: "Loading",
    ERROR: "error",
};

const campaignListSlice = createSlice({
    name: "campaign list",
    initialState: {
        data: { campaigns: [], totalCount: 0, page: 1, searchText: '' },
        status: STATUS.IDLE,
    },
    reducers: {
        SetCampaignsList: (state, action) => {
            state.data.campaigns = action.payload.campaigns;
            state.data.totalCount = action.payload.totalCount;
        },
        UpdateCampaignsSearchTextPage: (state, action) => {
            state.data.searchText = action.payload.searchText;
            state.data.page = action.payload.page;
        },
        RemoveCampaign: (state, action) => {
            state.data = action.payload;
        },
        UpdateCampaignData: (state, action) => {
            state.data = action.payload;
        },
        UpdateCampaignsList: (state, action) => {
            state.data.campaigns = action.payload;
        },
        ResetCampaignsState: (state, action) => {
            state.data = { campaigns: [], totalCount: 0, page: 1, searchText: '' };
        },
    },
});

export const { SetCampaignsList, UpdateCampaignsList, UpdateCampaignsSearchTextPage, ResetCampaignsState, UpdateCampaignData } = campaignListSlice.actions;

export default campaignListSlice.reducer;
