import React from "react";
import styled from "@emotion/styled";
import {useNavigate} from "react-router-dom";
import {Box, Container, Grid, Typography, Card as MuiCard, CardContent, Button, Stack} from "@mui/material";
import {spacing} from "@mui/system";
import {useSelector, useDispatch} from "react-redux";
import {setSelectedPricingPlan} from "../../../redux/slices/SignUp";

const Wrapper = styled.div`
  ${spacing};
  background: ${(props) => props.theme.palette.background.paper};
  text-align: center;
`;


const Card = styled(MuiCard)`
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
`

const Price = styled.div`
  text-align: center;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const RenderIncludedFeatures = ({includedFeatures}) => {
    return includedFeatures.map((includedFeature) => {
        return (  <Stack direction="row" spacing={1} alignItems="center"  ml={4}>
            <Box className={`icon-dot icon-color-dark`} style={{fontSize: '12px'}}/>
            <Typography component="h2" variant="body2">
                {includedFeature}
            </Typography>
        </Stack>   );
    });
}

const RenderPricingPlans = ({pricingPlans}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setPricingPlan = async (e, pricingPlan) => {
        await dispatch(setSelectedPricingPlan(pricingPlan));
        navigate("/signup");
    }

    return pricingPlans.map((pricingPlan) => {
        return (<Grid item md={3.5}>
            <Card className='p-3 border' sx={{ minHeight: '28rem' }} >
                <Typography
                    align='center'
                    variant='h3'
                    pb={0}
                    pt={2}
                >
                    {pricingPlan.title}
                </Typography>
                <CardContent >
                    <Price>
                        {!pricingPlan.price && <Box minHeight="3rem" display="flex" justifyContent="center" alignItems="center">
                            <Typography
                                component="h2"
                                variant="h4"
                                color="textPrimary"
                                align="center"
                                display="inline"
                            >
                                Contact Us
                            </Typography>
                        </Box>}
                        <br/>
                        <Typography
                            variant="h5"
                            color="textPrimary"
                            align="center"
                            display="inline"
                        >
                            What's Included:
                        </Typography>
                    </Price>
                    <Typography variant="subtitle1" align="left" >
                        <RenderIncludedFeatures includedFeatures={pricingPlan.includedFeatures}/>
                    </Typography>
                </CardContent>
                <Box display="flex" alignItems="center" justifyContent="center" minHeight="4rem">
                    {pricingPlan.price && <Button width="85%" variant="outlined" align="center" onClick={(e) => setPricingPlan(e, pricingPlan)}
                                                  color="primary" sx={{cursor: "pointer"}}>
                        {pricingPlan.buttonText}
                    </Button>}
                    {!pricingPlan.price && <Button width="85%" variant="outlined" align="center" href="mailto:support@1spoc.ai"
                                                   color="primary" sx={{cursor: "pointer"}}>
                        {pricingPlan.buttonText}
                    </Button>}
                </Box>
            </Card>
        </Grid>)
    });
}

function Demos() {
    const {pricingPlans} = useSelector(store => store.signUp);

    return (
        <Wrapper pt={16} pb={20} id="demos">
            <Container>

                <Typography variant="h2" component="h3" color="#000" gutterBottom>
                    Let us get you started with the Limited 14 Day Early Access
                </Typography>

                <Box mb={8}/>
                <Grid container spacing={10} justifyContent="center">
                    <RenderPricingPlans pricingPlans={pricingPlans}/>
                </Grid>
            </Container>
        </Wrapper>
    );
}

export default Demos;
