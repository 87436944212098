import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/workflowAxios";

export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const processesListSlice = createSlice({
    name: "processes list",
    initialState: {
        data: { processes: [], totalCount: 0, page: 1, searchText: '' },
        // archivesData: { archivesBots: [], totalRecords: 0, totalCount: 0, page: 1, searchText: '' },
        status: STATUS.IDLE
    },
    reducers: {
        SetProcessesList: (state, action) => {
            state.data.processes = action.payload.processesData;
            state.data.totalCount = action.payload.totalCount;
        },
        updateProcessesSearchTextPage: (state, action) => {
            state.data.searchText = action.payload.searchText;
            state.data.page = action.payload.page;
        },
        // SetArchivesBotsList: (state, action) => {
        //     state.archivesData.archivesBots = action.payload.versions;
        //     state.archivesData.totalCount = action.payload.totalCount;
        //     state.archivesData.totalRecords = action.payload.totalRecords;
        // },
        // updateArchivesBotsSearchTextPage: (state, action) => {
        //     state.archivesData.searchText = action.payload.searchText;
        //     state.archivesData.page = action.payload.page;
        // },
        ResetProcessesListingState: (state, action) => {
            state.data = { processes: [], totalCount: 0, page: 1, searchText: '' };
        },

    },
    extraReducers: (builder) => {
        builder
            .addCase(deleteProcess.pending, (state, action) => {
                state.status = STATUS.LOADING;
            })
            .addCase(deleteProcess.fulfilled, (state, action) => {
                let id = action.payload;
                let ProcessesList = state.data.processes;
                state.data.processes = ProcessesList.filter(process => process._id && process._id.toString() !== id.toString());
                state.status = STATUS.IDLE;
            })
            .addCase(deleteProcess.rejected, (state, action) => {
                state.status = STATUS.ERROR;
            });
    }

});

export const { SetProcessesList, updateProcessesSearchTextPage, ResetProcessesListingState } = processesListSlice.actions;

export default processesListSlice.reducer;

// Thunks async  actions
export const deleteProcess = createAsyncThunk('processes/delete', async (id) => {
    await axiosInstance.delete(`process/${id}`);
    return id;
});