import * as React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  AppBar, Box,
  Button as MuiButton,
  Container,
  Grid,
  Toolbar,
} from "@mui/material";
import {useTranslation} from "react-i18next";

const Button = styled(MuiButton)(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;


function AppBarComponent () {
  const navigate = useNavigate()
  const {t}=useTranslation('LandingPage')
  return (
      <React.Fragment>
        <AppBar position="relative" color="transparent" elevation={0}>
          <Toolbar>
            <Container>
              <Grid container alignItems="center">
                <Grid item>
                  <Brand>
                    <Box component="img" src="/static/img/logo.svg" height={40}
                         width={120}/>
                  </Brand>
                </Grid>
                <Grid item xs/>
                <Grid item>
                  <Button ml={2} variant="contained" align="center" color="primary"
                          onClick={() => navigate("/login")}>
                    {t("login")}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </React.Fragment>
  );
}
export default AppBarComponent;
