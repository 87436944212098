import { createSlice } from "@reduxjs/toolkit";

export let STATUS = {
  IDLE: "idle",
  LOADING: "Loading",
  ERROR: "error",
};

const messageTemplateListSlice = createSlice({
  name: "message Template list",
  initialState: {
    data: [],
    status: STATUS.IDLE,
  },
  reducers: {
    SetMessageTemplateList: (state, action) => {
      state.data = action.payload;
    },
    RemoveMessageTemplate: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { SetMessageTemplateList } = messageTemplateListSlice.actions;

export default messageTemplateListSlice.reducer;
