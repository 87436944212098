import React from "react";
import styled from "@emotion/styled";
// import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Found from "../../../src/assets/SVG/Found.svg";
import { Grid, Button as MuiButton, Typography } from "@mui/material";
import { color, spacing } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)};
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Page500() {
  const LogedInTeamMember = useSelector(store => store.org.LogedInTeamMember);
  const navigate = useNavigate();

  const handleRedirect = () => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (LogedInTeamMember.role === 'editor' && accessToken) {
      navigate("/bots");
    } else if (accessToken && LogedInTeamMember.role !== 'editor') {
      navigate("/dashboard");
    } else if (!accessToken) {
      navigate("/login");
    }
  }

  return (
    <Grid
      container
      sx={{
        minHeight: "70vh",
        alignItems: "center",
        justifyContent: "center",
        gap: '5rem',
        // backgroundColor: "#f4f0f5",
        // position: "relative",
        boxShadow: 9,
        width: "90rem",
        marginLeft: "20px !important"
      }}
    >
      <Helmet title="500 Error" />

      <Grid>
        {/* {Object.keys(LogedInTeamMember).length === 0 ? (
              <Fragment>
                  <CircularProgress />
              </Fragment>
          ) : ( */}
        <Grid sx={{ textAlign: "left" }}>
          <Typography variant="h1" color={'#525252'}>
            Oops!
          </Typography>
          <Typography variant="h1" color={'#525252'}>
            Internal server error.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 5 }}>
            The server encountered something unexpected that didn't allow it to complete the request. <br /> Contact us at <a href="mailto:support@1spoc.ai" style={{ "text-decoration": "none", "color": "black" }}>support@1spoc.ai</a>
            .
          </Typography>
          <Button
            onClick={handleRedirect}
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mt: 8 }}
          >
            Return to Application
          </Button>
        </Grid>
      </Grid>
      <img src={Found} style={{ width: '38rem', height: "25rem" }} />
    </Grid>
  )
};

export default Page500;
