import React from "react";
import AppBar from "./AppBar";
import Introduction from "./Introduction";
import Demos from "./Demos";
import Features from "./Features";




function Presentation() {
  return (
    <React.Fragment>
      <AppBar />
      <Introduction />
      <Demos />
      <Features />
    </React.Fragment>
  );
}

export default Presentation;
