/**
 * Convert previous non localised format into localized format
 * @param {*} param0 
 * @returns nodes[]
 */
export const addLocaleToPreviousNodeVersion = ({ nodes, locale }) => {
    const newNodesArray = [];
    let newLocaleContent = null;

    for (let index = 0; index < nodes.length; index++) {
        const currentNode = nodes[index];
        const version = currentNode.data.version ?? '1.0'
        // Handle all renderable nodes content for localization
        switch (currentNode.type) {
            case "plainMessageTextNode":
            case "questionNode": {
                if (typeof currentNode.data.contents === "string") {
                    // meaning its previous version
                    newLocaleContent = {
                        [locale]: currentNode.data.contents,
                    };
                } else {
                    newLocaleContent = currentNode.data.contents;
                }
                break;
            }

            case "buttonNode": {
                let tempContent = {
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                    buttons: [],
                    isMediaRender: currentNode.data.contents.isMediaRender,
                    mediaUrl: currentNode.data.contents.mediaUrl,
                    mediaFromType: currentNode.data.contents.mediaFromType,
                    isUrlByVariable: currentNode.data.contents.isUrlByVariable,
                    mediaType: currentNode.data.contents.mediaType ?? "",
                    fileDisplayNameForDocument:
                        currentNode.data.contents.fileDisplayNameForDocument,
                };
                if (typeof currentNode.data.contents.headerText === "string") {
                    tempContent["headerText"][locale] =
                        currentNode.data.contents.headerText;
                } else {
                    tempContent["headerText"] = currentNode.data.contents.headerText;
                }

                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }

                if (typeof currentNode.data.contents.footerText === "string") {
                    tempContent["footerText"][locale] =
                        currentNode.data.contents.footerText;
                } else {
                    tempContent["footerText"] = currentNode.data.contents.footerText;
                }

                // converting all the value into locale object
                if (Array.isArray(currentNode.data.contents.buttons)) {
                    tempContent["buttons"] = currentNode.data.contents.buttons.map(
                        (button) => {
                            if (typeof button.value === "string") {
                                return {
                                    ...button,
                                    value: {
                                        [locale]: button.value,
                                    },
                                };
                            } else {
                                return button;
                            }
                        }
                    );
                }
                newLocaleContent = tempContent;
                break;
            }

            case "listNode": {
                let tempContent = {
                    openButtonText: {},
                    bodyText: {},
                    list: [],
                };

                if (typeof currentNode.data.contents.openButtonText === "string") {
                    tempContent["openButtonText"][locale] =
                        currentNode.data.contents.openButtonText;
                } else {
                    tempContent["openButtonText"] =
                        currentNode.data.contents.openButtonText;
                }

                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }
                // converting all the value into locale object
                if (Array.isArray(currentNode.data.contents.list)) {
                    tempContent["list"] = currentNode.data.contents.list.map(
                        (listItem) => {
                            if (typeof listItem.value === "string") {
                                return {
                                    ...listItem,
                                    value: {
                                        [locale]: listItem.value,
                                    },
                                    description: {
                                        [locale]: listItem.description ?? ""
                                    }
                                };
                            } else {
                                return listItem;
                            }
                        }
                    );
                }
                newLocaleContent = tempContent;
                break;
            }

            case "carouselNode": {

                switch (version) {
                    case '2.0': {
                        //=========================================For FB Messenger ==================================================
                        let tempContentForFbMessenger = {
                            ...currentNode.data.contents.fbMessenger,
                            carousels: [],
                            buttons: [],
                        };

                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.fbMessenger.carousels)) {
                            tempContentForFbMessenger["carousels"] = currentNode.data.contents.fbMessenger.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: {
                                                ...item.buttonName,
                                                [locale]: item.buttonName[locale] ?? "",
                                            },
                                        };
                                    });

                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        title: {
                                            ...listItem.title,
                                            [locale]: listItem.title[locale] ?? ""
                                        },
                                        bodyText: {
                                            ...listItem.bodyText,
                                            [locale]: listItem.bodyText[locale] ?? ""
                                        },
                                    };
                                }
                            );
                        }

                        //=========================================For Instagram ==================================================

                        let tempContentForInstagram = {
                            ...currentNode.data.contents.instagram,
                            carousels: [],
                            buttons: [],
                        };

                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.instagram.carousels)) {
                            tempContentForInstagram["carousels"] = currentNode.data.contents.instagram.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: {
                                                ...item.buttonName,
                                                [locale]: item.buttonName[locale] ?? "",
                                            },
                                        };
                                    });

                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        title: {
                                            ...listItem.title,
                                            [locale]: listItem.title[locale] ?? ""
                                        },
                                        bodyText: {
                                            ...listItem.bodyText,
                                            [locale]: listItem.bodyText[locale] ?? ""
                                        },
                                    };
                                }
                            );
                        }

                        //=========================================For Tubulu ==================================================

                        let tempContentForTubulu = {
                            ...currentNode.data.contents.tubulu,
                            bodyText: {},
                            carousels: [],
                            suggestionButtons: [],
                        };


                        tempContentForTubulu["bodyText"] = {
                            ...currentNode.data.contents.tubulu.bodyText,
                            [locale]: currentNode.data.contents.tubulu.bodyText[locale] ?? "",
                        };


                        if (Array.isArray(currentNode.data.contents.tubulu.suggestionButtons)) {
                            tempContentForTubulu["suggestionButtons"] = currentNode.data.contents.tubulu.suggestionButtons.map(
                                (listItem) => {

                                    return {
                                        ...listItem,
                                        text: {
                                            ...listItem.text,
                                            [locale]: listItem.text[locale] ?? '',

                                        }

                                    };

                                }
                            );
                        };


                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.tubulu.carousels)) {
                            tempContentForTubulu["carousels"] = currentNode.data.contents.tubulu.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: {
                                                ...item.buttonName,
                                                [locale]: item.buttonName[locale] ?? "",
                                            },
                                        };
                                    });


                                    return {
                                        ...listItem,
                                        buttons: tempButtons,

                                    };

                                }
                            );
                        }


                        //=========================================For RCS ==================================================

                        let cardContentsRcs = [];

                        if (Array.isArray(currentNode.data.contents.rcs.carousels)) {
                            cardContentsRcs = currentNode.data.contents.rcs.carousels.map(
                                (individualCard) => {
                                    const buttonsList = individualCard.buttons.map((button) => {
                                        return {
                                            ...button,
                                            buttonName: {
                                                ...button.buttonName,
                                                [locale]: button.buttonName[locale] ?? "",
                                            },
                                        }
                                    });

                                    return {
                                        ...individualCard,
                                        buttons: buttonsList,
                                        title: {
                                            ...individualCard.title,
                                            [locale]: individualCard.title[locale] ?? ""
                                        },
                                        bodyText: {
                                            ...individualCard.bodyText,
                                            [locale]: individualCard.bodyText[locale] ?? ""
                                        },
                                    };

                                }
                            );
                        }

                        newLocaleContent = {
                            ...currentNode.data.contents, fbMessenger: tempContentForFbMessenger, instagram: tempContentForInstagram, tubulu: tempContentForTubulu, rcs: { ...currentNode.data.contents.rcs, carousels: cardContentsRcs }
                        };


                        break;
                    }
                    default: {
                        let tempContent = {
                            bodyText: {},
                            carousels: [],
                            buttons: [],
                        };

                        if (typeof currentNode.data.contents.bodyText === "string") {
                            tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                        } else {
                            tempContent["bodyText"] = currentNode.data.contents.bodyText;
                        }

                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.carousels)) {
                            tempContent["carousels"] = currentNode.data.contents.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        if (typeof item.buttonName === "string") {
                                            return {
                                                ...item,
                                                buttonName: {
                                                    [locale]: item.buttonName,
                                                },
                                            };
                                        } else {
                                            return item;
                                        }
                                    });
                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                    };
                                }
                            );
                        }
                        newLocaleContent = tempContent;
                        break;
                    }
                }
                break;
            }

            case "mediaNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    caption: {},
                };

                if (typeof currentNode.data.contents.caption === "string") {
                    tempContent["caption"][locale] = currentNode.data.contents.caption;
                } else {
                    tempContent["caption"] = currentNode.data.contents.caption;
                }

                newLocaleContent = tempContent;
                break;
            }

            case "mediaInputNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    text: {},
                };

                if (typeof currentNode.data.contents.text === "string") {
                    tempContent["text"][locale] = currentNode.data.contents.text;
                } else {
                    tempContent["text"] = currentNode.data.contents.text;
                }
                newLocaleContent = tempContent;
                break;
            }

            case "locationInputNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    text: {},
                };

                if (typeof currentNode.data.contents.text === "string") {
                    tempContent["text"][locale] = currentNode.data.contents.text;
                } else {
                    tempContent["text"] = currentNode.data.contents.text;
                }
                newLocaleContent = tempContent;
                break;
            }

            case "dynamicButtonNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    bodyText: {},
                };

                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }
                newLocaleContent = tempContent;
                break;
            }

            case "dynamicListNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    bodyText: {},
                    openButtonText: {},
                };
                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }

                if (typeof currentNode.data.contents.openButtonText === "string") {
                    tempContent["openButtonText"][locale] =
                        currentNode.data.contents.openButtonText;
                } else {
                    tempContent["openButtonText"] =
                        currentNode.data.contents.openButtonText;
                }
                newLocaleContent = tempContent;
                break;
            }

            case "liveAgentNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    liveAgentNotAvailableButtonContents: {
                        ...currentNode.data.contents.liveAgentNotAvailableButtonContents,
                        bodyText: {},
                        buttons: {
                            ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                                .buttons,
                            register: {
                                ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                                    .buttons.register,
                                value: {},
                            },
                            cancelRegister: {
                                ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                                    .buttons.cancelRegister,
                                value: {},
                            },
                        },
                    },
                    liveAgentWhenCallbackReceived: {
                        ...currentNode.data.contents.liveAgentWhenCallbackReceived,
                        bodyText: {},
                        buttons: {
                            ...currentNode.data.contents.liveAgentWhenCallbackReceived
                                .buttons,
                            connectToLiveAgent: {
                                ...currentNode.data.contents.liveAgentWhenCallbackReceived
                                    .buttons.connectToLiveAgent,
                                value: {},
                            },
                            skipLiveAgentCallback: {
                                ...currentNode.data.contents.liveAgentWhenCallbackReceived
                                    .buttons.skipLiveAgentCallback,
                                value: {},
                            },
                        },
                    },
                    liveAgentDisconnectKeywords: {},
                };
                if (
                    typeof currentNode.data.contents.liveAgentNotAvailableButtonContents
                        .bodyText === "string"
                ) {
                    tempContent["liveAgentNotAvailableButtonContents"]["bodyText"][
                        locale
                    ] =
                        currentNode.data.contents.liveAgentNotAvailableButtonContents.bodyText;
                } else {
                    tempContent["liveAgentNotAvailableButtonContents"]["bodyText"] =
                        currentNode.data.contents.liveAgentNotAvailableButtonContents.bodyText;
                }
                if (
                    typeof currentNode.data.contents.liveAgentNotAvailableButtonContents
                        .buttons.register.value === "string"
                ) {
                    tempContent["liveAgentNotAvailableButtonContents"]["buttons"][
                        "register"
                    ]["value"][locale] =
                        currentNode.data.contents.liveAgentNotAvailableButtonContents.buttons.register.value;
                } else {
                    tempContent["liveAgentNotAvailableButtonContents"]["buttons"][
                        "register"
                    ]["value"] =
                        currentNode.data.contents.liveAgentNotAvailableButtonContents.buttons.register.value;
                }
                if (
                    typeof currentNode.data.contents.liveAgentNotAvailableButtonContents
                        .buttons.cancelRegister.value === "string"
                ) {
                    tempContent["liveAgentNotAvailableButtonContents"]["buttons"][
                        "cancelRegister"
                    ]["value"][locale] =
                        currentNode.data.contents.liveAgentNotAvailableButtonContents.buttons.cancelRegister.value;
                } else {
                    tempContent["liveAgentNotAvailableButtonContents"]["buttons"][
                        "cancelRegister"
                    ]["value"] =
                        currentNode.data.contents.liveAgentNotAvailableButtonContents.buttons.cancelRegister.value;
                }
                if (
                    typeof currentNode.data.contents.liveAgentWhenCallbackReceived
                        .bodyText === "string"
                ) {
                    tempContent["liveAgentWhenCallbackReceived"]["bodyText"][locale] =
                        currentNode.data.contents.liveAgentWhenCallbackReceived.bodyText;
                } else {
                    tempContent["liveAgentWhenCallbackReceived"]["bodyText"] =
                        currentNode.data.contents.liveAgentWhenCallbackReceived.bodyText;
                }
                if (
                    typeof currentNode.data.contents.liveAgentWhenCallbackReceived.buttons
                        .connectToLiveAgent.value === "string"
                ) {
                    tempContent["liveAgentWhenCallbackReceived"]["buttons"][
                        "connectToLiveAgent"
                    ]["value"][locale] =
                        currentNode.data.contents.liveAgentWhenCallbackReceived.buttons.connectToLiveAgent.value;
                } else {
                    tempContent["liveAgentWhenCallbackReceived"]["buttons"][
                        "connectToLiveAgent"
                    ]["value"] =
                        currentNode.data.contents.liveAgentWhenCallbackReceived.buttons.connectToLiveAgent.value;
                }
                if (
                    typeof currentNode.data.contents.liveAgentWhenCallbackReceived.buttons
                        .skipLiveAgentCallback.value === "string"
                ) {
                    tempContent["liveAgentWhenCallbackReceived"]["buttons"][
                        "skipLiveAgentCallback"
                    ]["value"][locale] =
                        currentNode.data.contents.liveAgentWhenCallbackReceived.buttons.skipLiveAgentCallback.value;
                } else {
                    tempContent["liveAgentWhenCallbackReceived"]["buttons"][
                        "skipLiveAgentCallback"
                    ]["value"] =
                        currentNode.data.contents.liveAgentWhenCallbackReceived.buttons.skipLiveAgentCallback.value;
                }
                if (
                    typeof currentNode.data.contents.liveAgentDisconnectKeywords ===
                    "object" &&
                    Array.isArray(currentNode.data.liveAgentDisconnectKeywords)
                ) {
                    tempContent["liveAgentDisconnectKeywords"][locale] =
                        currentNode.data.contents.liveAgentDisconnectKeywords;
                } else {
                    tempContent["liveAgentDisconnectKeywords"] =
                        currentNode.data.contents.liveAgentDisconnectKeywords;
                }
                newLocaleContent = tempContent;
                break;
            }
            case "documentCognitionCard": {
                const tempContent = {
                    ...currentNode.data.contents,
                    modelDisconnectKeywords: {},
                };
                if (typeof tempContent["modelDisconnectKeywords"] === "string") {
                    tempContent["modelDisconnectKeywords"][locale] =
                        currentNode.data.contents.modelDisconnectKeywords;
                } else {
                    tempContent["modelDisconnectKeywords"] =
                        currentNode.data.contents.modelDisconnectKeywords;
                }

                newLocaleContent = tempContent;
                break;
            }

            case "httpTriggerNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    text: {},
                };
                if (typeof tempContent["text"] === "string") {
                    tempContent["text"][locale] = currentNode.data.contents.text;
                } else {
                    tempContent["text"] = currentNode.data.contents.text;
                }
                newLocaleContent = tempContent;
                break;
            }

            case "whatsAppFlowsNode": {
                let tempContent = {
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                    openButtonText: {},
                    flowData: currentNode.data.contents.flowData,
                    firstScreenSubmitAction: currentNode.data.contents.firstScreenSubmitAction,
                };

                if (typeof currentNode.data.contents.headerText === "string") {
                    tempContent["headerText"][locale] =
                        currentNode.data.contents.headerText;
                } else {
                    tempContent["headerText"] = currentNode.data.contents.headerText;
                }

                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }

                if (typeof currentNode.data.contents.footerText === "string") {
                    tempContent["footerText"][locale] =
                        currentNode.data.contents.footerText;
                } else {
                    tempContent["footerText"] = currentNode.data.contents.footerText;
                }

                if (typeof currentNode.data.contents.openButtonText === "string") {
                    tempContent["openButtonText"][locale] =
                        currentNode.data.contents.openButtonText;
                } else {
                    tempContent["openButtonText"] =
                        currentNode.data.contents.openButtonText;
                }

                newLocaleContent = tempContent;
                break;
            }

            case "whatsAppTemplateNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    templateCategory: {},
                    templatePlaceholders: {},
                    //   headerText: {},
                    //   bodyText: {},
                    //   footerText: {},
                    //   openButtonText: {},
                };

                if (typeof currentNode.data.contents.templateCategory === "string") {
                    tempContent["templateCategory"][locale] =
                        currentNode.data.contents.templateCategory;
                } else {
                    tempContent["templateCategory"] =
                        currentNode.data.contents.templateCategory;
                }

                if (Array.isArray(currentNode.data.contents.templatePlaceholders)) {
                    tempContent["templatePlaceholders"][locale] =
                        currentNode.data.contents.templatePlaceholders;
                } else {
                    tempContent["headerText"] =
                        currentNode.data.contents.templatePlaceholders;
                }

                if (typeof currentNode.data.contents.headerText === "string") {
                    tempContent["headerText"][locale] =
                        currentNode.data.contents.headerText;
                } else {
                    tempContent["headerText"] = currentNode.data.contents.headerText;
                }

                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }

                if (typeof currentNode.data.contents.footerText === "string") {
                    tempContent["footerText"][locale] =
                        currentNode.data.contents.footerText;
                } else {
                    tempContent["footerText"] = currentNode.data.contents.footerText;
                }

                if (typeof currentNode.data.contents.openButtonText === "string") {
                    tempContent["openButtonText"][locale] =
                        currentNode.data.contents.openButtonText;
                } else {
                    tempContent["openButtonText"] =
                        currentNode.data.contents.openButtonText;
                }

                newLocaleContent = tempContent;
                break;
            }

            case "whatsAppPaymentNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    bodyText: {},
                    footerText: {},
                    response: { successResponse: {}, failureResponse: {} },
                };
                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }

                if (typeof currentNode.data.contents.footerText === "string") {
                    tempContent["footerText"][locale] =
                        currentNode.data.contents.footerText;
                } else {
                    tempContent["footerText"] = currentNode.data.contents.footerText;
                }

                // update locale for success response 
                if (currentNode.data.contents.response && currentNode.data.contents.response.successResponse) {
                    if (typeof currentNode.data.contents.response.successResponse === "string") {
                        tempContent.response.successResponse[locale] = currentNode.data.contents.response.successResponse;
                    } else {
                        tempContent.response.successResponse = currentNode.data.contents.response.successResponse;
                    }
                }

                // update locale for failure response
                if (currentNode.data.contents.response && currentNode.data.contents.response.failureResponse) {
                    if (typeof currentNode.data.contents.response.failureResponse === "string") {
                        tempContent.response.failureResponse[locale] = currentNode.data.contents.response.failureResponse;
                    } else {
                        tempContent.response.failureResponse = currentNode.data.contents.response.failureResponse;
                    }
                }


                newLocaleContent = tempContent;
                break;
            }
            case "whatsAppCatalogNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                };

                if (typeof currentNode.data.contents.headerText === "string") {
                    tempContent["headerText"][locale] =
                        currentNode.data.contents.headerText;
                } else {
                    tempContent["headerText"] = currentNode.data.contents.headerText;
                }

                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }

                if (typeof currentNode.data.contents.footerText === "string") {
                    tempContent["footerText"][locale] =
                        currentNode.data.contents.footerText;
                } else {
                    tempContent["footerText"] = currentNode.data.contents.footerText;
                }
                // tempContent["catalogId"] = currentNode.data.contents.catalogId;
                // tempContent["sectionVariable"] =
                //   currentNode.data.contents.sectionVariable;

                newLocaleContent = tempContent;
                break;
            }
            case "rcsStandAloneNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    title: {},
                    description: {},
                    buttons: [],
                };
                if (typeof currentNode.data.contents.title === "string") {
                    tempContent["title"][locale] = currentNode.data.contents.title;
                } else {
                    tempContent["title"] = currentNode.data.contents.title;
                }

                if (typeof currentNode.data.contents.description === "string") {
                    tempContent["description"][locale] =
                        currentNode.data.contents.description;
                } else {
                    tempContent["description"] = currentNode.data.contents.description;
                }

                // converting all the text into locale object
                if (Array.isArray(currentNode.data.contents.buttons)) {
                    tempContent["buttons"] = currentNode.data.contents.buttons.map(
                        (button) => {
                            if (typeof button.text === "string") {
                                return {
                                    ...button,
                                    text: {
                                        [locale]: button.text,
                                    },
                                };
                            } else {
                                return button;
                            }
                        }
                    );
                }
                newLocaleContent = tempContent;
                break;
            }
            case "whatsAppCTANode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                    text: {},
                };

                if (typeof currentNode.data.contents.headerText === "string") {
                    tempContent["headerText"][locale] =
                        currentNode.data.contents.headerText;
                } else {
                    tempContent["headerText"] = currentNode.data.contents.headerText;
                }

                if (typeof currentNode.data.contents.bodyText === "string") {
                    tempContent["bodyText"][locale] = currentNode.data.contents.bodyText;
                } else {
                    tempContent["bodyText"] = currentNode.data.contents.bodyText;
                }

                if (typeof currentNode.data.contents.footerText === "string") {
                    tempContent["footerText"][locale] =
                        currentNode.data.contents.footerText;
                } else {
                    tempContent["footerText"] = currentNode.data.contents.footerText;
                }

                if (typeof currentNode.data.contents.text === "string") {
                    tempContent["text"][locale] =
                        currentNode.data.contents.text;
                } else {
                    tempContent["text"] =
                        currentNode.data.contents.text;
                }
                newLocaleContent = tempContent;
                break;
            }
            default:
                newLocaleContent = currentNode.data.contents;
                break;
        }

        // Handle Localization for validation error message
        let validation = currentNode.data.validation;
        if (
            currentNode.data.validation &&
            currentNode.data.validation.validate &&
            currentNode.data.validation.validate === true &&
            currentNode.data.validation.errorMessage &&
            typeof currentNode.data.validation.errorMessage === "string"
        ) {
            validation = {
                ...currentNode.data.validation,
                errorMessage: {
                    [locale]: currentNode.data.validation.errorMessage,
                },
            };
        }

        newNodesArray.push({
            ...currentNode,
            data: { ...currentNode.data, contents: newLocaleContent, validation },
        });
    }

    return newNodesArray;
};

/**
 * This methods add new locale to every renderable nodes
 * @param {} param0
 * @returns
 */
export const addLocaleToAllRenderableNode = ({ nodes, locale }) => {
    const newNodesArray = [];
    let newLocaleContent = null;
    for (let index = 0; index < nodes.length; index++) {
        const currentNode = nodes[index];
        const version = currentNode.data.version ?? '1.0'

        // Handle all renderable nodes for localization
        switch (currentNode.type) {
            case "plainMessageTextNode":
            case "questionNode": {
                newLocaleContent = {
                    ...currentNode.data.contents,
                    [locale]: currentNode.data.contents[locale] ?? "",
                };
                break;
            }

            case "buttonNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                    buttons: [],
                };

                tempContent["headerText"] = {
                    ...currentNode.data.contents.headerText,
                    [locale]: currentNode.data.contents.headerText[locale] ?? "",
                };

                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: currentNode.data.contents.bodyText[locale] ?? "",
                };

                tempContent["footerText"] = {
                    ...currentNode.data.contents.footerText,
                    [locale]: currentNode.data.contents.footerText[locale] ?? "",
                };

                // converting all the value into locale object
                if (Array.isArray(currentNode.data.contents.buttons)) {
                    tempContent["buttons"] = currentNode.data.contents.buttons.map(
                        (button) => {
                            return {
                                ...button,
                                value: {
                                    ...button.value,
                                    [locale]: button.value[locale] ?? "",
                                },
                            };
                        }
                    );
                }
                newLocaleContent = tempContent;
                break;
            }

            case "listNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    openButtonText: {},
                    bodyText: {},
                    list: []
                };


                tempContent["openButtonText"] = {
                    ...currentNode.data.contents.openButtonText,
                    [locale]: currentNode.data.contents.openButtonText[locale] ?? ""
                };

                tempContent['bodyText'] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: currentNode.data.contents.bodyText[locale] ?? ""
                };

                // converting all the value into locale object
                if (Array.isArray(currentNode.data.contents.list)) {
                    tempContent["list"] = currentNode.data.contents.list.map((listItem) => {
                        // FALLBACK for description
                        let _description = {};
                        if (listItem.description) {
                            _description = {
                                ...listItem.description,
                                [locale]: listItem.description[locale] ?? ""
                            }
                        } else {
                            _description = {
                                [locale]: ""
                            }
                        }
                        // FALLBACK for description

                        return {
                            ...listItem,
                            value: {
                                ...listItem.value,
                                [locale]: listItem.value[locale] ?? ""
                            },
                            description: _description
                        }
                    });
                }
                newLocaleContent = tempContent;
                break;
            }

            case "carouselNode": {
                switch (version) {
                    case '2.0': {

                        //=========================================For FB Messenger ==================================================
                        let tempContentForFbMessenger = {
                            ...currentNode.data.contents.fbMessenger,
                            carousels: [],
                            buttons: [],
                        };


                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.fbMessenger.carousels)) {
                            tempContentForFbMessenger["carousels"] = currentNode.data.contents.fbMessenger.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: {
                                                ...item.buttonName,
                                                [locale]: item.buttonName[locale] ?? "",
                                            },
                                        };
                                    });
                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        title: {
                                            ...listItem.title,
                                            [locale]: listItem.title[locale] ?? ""
                                        },
                                        bodyText: {
                                            ...listItem.bodyText,
                                            [locale]: listItem.bodyText[locale] ?? "",
                                        },
                                    };
                                }
                            );
                        }

                        //=========================================For Instagram ==================================================

                        let tempContentForInstagram = {
                            ...currentNode.data.contents.instagram,
                            carousels: [],
                            buttons: [],
                        };

                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.instagram.carousels)) {
                            tempContentForInstagram["carousels"] = currentNode.data.contents.instagram.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: {
                                                ...item.buttonName,
                                                [locale]: item.buttonName[locale] ?? "",
                                            },
                                        };
                                    });
                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        title: {
                                            ...listItem.title,
                                            [locale]: listItem.title[locale] ?? ""
                                        },
                                        bodyText: {
                                            ...listItem.bodyText,
                                            [locale]: listItem.bodyText[locale] ?? "",
                                        },
                                    };
                                }
                            );
                        }


                        //=========================================For Tubulu ==================================================

                        let tempContentForTubulu = {
                            ...currentNode.data.contents.tubulu,
                            bodyText: {},
                            carousels: [],
                            buttons: [],
                        };

                        tempContentForTubulu["bodyText"] = {
                            ...currentNode.data.contents.tubulu.bodyText,
                            [locale]: currentNode.data.contents.tubulu.bodyText[locale] ?? "",
                        };

                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.tubulu.carousels)) {
                            tempContentForTubulu["carousels"] = currentNode.data.contents.tubulu.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: {
                                                ...item.buttonName,
                                                [locale]: item.buttonName[locale] ?? "",
                                            },
                                        };
                                    });

                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                    };
                                }
                            );
                        }

                        if (Array.isArray(currentNode.data.contents.tubulu.suggestionButtons)) {
                            tempContentForTubulu["suggestionButtons"] = currentNode.data.contents.tubulu.suggestionButtons.map(
                                (listItem) => {

                                    return {
                                        ...listItem,
                                        text: {
                                            ...listItem.text,
                                            [locale]: listItem.text[locale] ?? '',

                                        }

                                    };

                                }
                            );
                        };


                        //=========================================For RCS ==================================================

                        let cardContentsRcs = [];

                        if (Array.isArray(currentNode.data.contents.rcs.carousels)) {
                            cardContentsRcs = currentNode.data.contents.rcs.carousels.map(
                                (individualCard) => {
                                    const buttonsList = individualCard.buttons.map((button) => {
                                        return {
                                            ...button,
                                            buttonName: { ...button.buttonName, [locale]: button.buttonName[locale] ?? "" }
                                        }
                                    });

                                    return {
                                        ...individualCard,
                                        buttons: buttonsList,
                                        title: {
                                            ...individualCard.title,
                                            [locale]: individualCard.title[locale] ?? ""
                                        },
                                        bodyText: {
                                            ...individualCard.bodyText,
                                            [locale]: individualCard.bodyText[locale] ?? "",
                                        },
                                    };
                                }
                            );
                        }

                        newLocaleContent = {
                            ...currentNode.data.contents, fbMessenger: tempContentForFbMessenger, instagram: tempContentForInstagram, tubulu: tempContentForTubulu, rcs: { ...currentNode.data.contents.rcs, carousels: cardContentsRcs }
                        };

                        break;
                    }

                    default: {
                        let tempContent = {
                            ...currentNode.data.contents,
                            bodyText: {},
                            carousels: [],
                            buttons: [],
                        };

                        tempContent["bodyText"] = {
                            ...currentNode.data.contents.bodyText,
                            [locale]: currentNode.data.contents.bodyText[locale] ?? "",
                        };

                        // converting all the value into locale object
                        if (Array.isArray(currentNode.data.contents.carousels)) {
                            tempContent["carousels"] = currentNode.data.contents.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: {
                                                ...item.buttonName,
                                                [locale]: item.buttonName[locale] ?? "",
                                            },
                                        };
                                    });
                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        bodyText: {
                                            ...listItem.bodyText,
                                            [locale]: listItem.bodyText[locale] ?? "",
                                        },
                                    };
                                }
                            );
                        }
                        newLocaleContent = tempContent;
                        break;
                    }
                }


                break;
            }

            case "mediaNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    caption: {},
                };
                tempContent["caption"] = {
                    ...currentNode.data.contents.caption,
                    [locale]: currentNode.data.contents.caption[locale] ?? "",
                };
                newLocaleContent = tempContent;
                break;
            }

            case "mediaInputNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    text: {},
                };
                tempContent["text"] = {
                    ...currentNode.data.contents.text,
                    [locale]: currentNode.data.contents.text[locale] ?? "",
                };
                newLocaleContent = tempContent;
                break;
            }

            case "locationInputNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    text: {},
                };

                tempContent["text"] = {
                    ...currentNode.data.contents.text,
                    [locale]: currentNode.data.contents.text[locale] ?? "",
                };
                newLocaleContent = tempContent;
                break;
            }

            case "dynamicButtonNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    bodyText: {},
                };

                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: currentNode.data.contents.bodyText[locale] ?? "",
                };
                newLocaleContent = tempContent;
                break;
            }

            case "dynamicListNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    bodyText: {},
                    openButtonText: {},
                };

                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: currentNode.data.contents.bodyText[locale] ?? "",
                };

                tempContent["openButtonText"] = {
                    ...currentNode.data.contents.openButtonText,
                    [locale]: currentNode.data.contents.openButtonText[locale] ?? "",
                };
                newLocaleContent = tempContent;
                break;
            }

            case "liveAgentNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    liveAgentNotAvailableButtonContents: {
                        ...currentNode.data.contents.liveAgentNotAvailableButtonContents,
                        bodyText: {},
                        buttons: {
                            ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                                .buttons,
                            register: {
                                ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                                    .buttons.register,
                                value: {},
                            },
                            cancelRegister: {
                                ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                                    .buttons.cancelRegister,
                                value: {},
                            },
                        },
                    },
                    liveAgentWhenCallbackReceived: {
                        ...currentNode.data.contents.liveAgentWhenCallbackReceived,
                        bodyText: {},
                        buttons: {
                            ...currentNode.data.contents.liveAgentWhenCallbackReceived
                                .buttons,
                            connectToLiveAgent: {
                                ...currentNode.data.contents.liveAgentWhenCallbackReceived
                                    .buttons.connectToLiveAgent,
                                value: {},
                            },
                            skipLiveAgentCallback: {
                                ...currentNode.data.contents.liveAgentWhenCallbackReceived
                                    .buttons.skipLiveAgentCallback,
                                value: {},
                            },
                        },
                    },
                    liveAgentDisconnectKeywords: {},
                };

                tempContent["liveAgentNotAvailableButtonContents"]["bodyText"] = {
                    ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                        .bodyText,
                    [locale]:
                        currentNode.data.contents.liveAgentNotAvailableButtonContents
                            .bodyText[locale] ?? "",
                };

                tempContent["liveAgentNotAvailableButtonContents"]["buttons"][
                    "register"
                ]["value"] = {
                    ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                        .buttons.register.value,
                    [locale]:
                        currentNode.data.contents.liveAgentNotAvailableButtonContents
                            .buttons.register.value[locale] ?? "",
                };

                tempContent["liveAgentNotAvailableButtonContents"]["buttons"][
                    "cancelRegister"
                ]["value"] = {
                    ...currentNode.data.contents.liveAgentNotAvailableButtonContents
                        .buttons.cancelRegister.value,
                    [locale]:
                        currentNode.data.contents.liveAgentNotAvailableButtonContents
                            .buttons.cancelRegister.value[locale] ?? "",
                };

                tempContent["liveAgentWhenCallbackReceived"]["bodyText"] = {
                    ...currentNode.data.contents.liveAgentWhenCallbackReceived.bodyText,
                    [locale]:
                        currentNode.data.contents.liveAgentWhenCallbackReceived.bodyText[
                        locale
                        ] ?? "",
                };

                tempContent["liveAgentWhenCallbackReceived"]["buttons"][
                    "connectToLiveAgent"
                ]["value"] = {
                    ...currentNode.data.contents.liveAgentWhenCallbackReceived.buttons
                        .connectToLiveAgent.value,
                    [locale]:
                        currentNode.data.contents.liveAgentWhenCallbackReceived.buttons
                            .connectToLiveAgent.value[locale] ?? "",
                };

                tempContent["liveAgentWhenCallbackReceived"]["buttons"][
                    "skipLiveAgentCallback"
                ]["value"] = {
                    ...currentNode.data.contents.liveAgentWhenCallbackReceived.buttons
                        .skipLiveAgentCallback.value,
                    [locale]:
                        currentNode.data.contents.liveAgentWhenCallbackReceived.buttons
                            .skipLiveAgentCallback.value[locale] ?? "",
                };

                tempContent["liveAgentDisconnectKeywords"] = {
                    ...currentNode.data.contents.liveAgentDisconnectKeywords,
                    locale:
                        currentNode.data.contents.liveAgentDisconnectKeywords[locale] ?? "",
                };

                newLocaleContent = tempContent;
                break;
            }

            case "documentCognitionCard": {
                const tempContent = {
                    ...currentNode.data.contents,
                    modelDisconnectKeywords: {},
                };
                tempContent["modelDisconnectKeywords"] = {
                    ...currentNode.data.contents.modelDisconnectKeywords,
                    [locale]:
                        currentNode.data.contents.modelDisconnectKeywords[locale] ?? "",
                };
                newLocaleContent = tempContent;
                break;
            }

            case "httpTriggerNode": {
                const tempContent = {
                    ...currentNode.data.contents,
                    text: {},
                };
                tempContent["text"] = {
                    ...currentNode.data.contents.text,
                    [locale]: currentNode.data.contents.text[locale] ?? "",
                };

                newLocaleContent = tempContent;
                break;
            }

            case "whatsAppFlowsNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                    openButtonText: {},
                };

                tempContent["headerText"] = {
                    ...currentNode.data.contents.headerText,
                    [locale]: currentNode.data.contents.headerText[locale] ?? ""
                };

                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: currentNode.data.contents.bodyText[locale] ?? "",
                };

                tempContent["footerText"] = {
                    ...currentNode.data.contents.footerText,
                    [locale]: currentNode.data.contents.footerText[locale] ?? "",
                };

                tempContent["openButtonText"] = {
                    ...currentNode.data.contents.openButtonText,
                    [locale]: currentNode.data.contents.openButtonText[locale] ?? "",
                };

                newLocaleContent = tempContent;
                break;
            }

            case "whatsAppTemplateNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                    openButtonText: {},
                };

                tempContent["headerText"] = {
                    ...currentNode.data.contents.headerText,
                    [locale]: "",
                };

                tempContent["headerText"] = {
                    ...currentNode.data.contents.headerText,
                    [locale]: "",
                };

                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: "",
                };

                tempContent["footerText"] = {
                    ...currentNode.data.contents.footerText,
                    [locale]: "",
                };

                tempContent["openButtonText"] = {
                    ...currentNode.data.contents.openButtonText,
                    [locale]: "",
                };

                newLocaleContent = tempContent;
                break;
            }

            case "whatsAppPaymentNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    bodyText: {},
                    footerText: {},
                    responsePayment: { successResponse: {}, failureResponse: {} },
                };


                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: currentNode.data.contents.bodyText[locale] ?? "",
                };

                tempContent["footerText"] = {
                    ...currentNode.data.contents.footerText,
                    [locale]: currentNode.data.contents.footerText[locale] ?? "",
                };

                tempContent["footerText"] = {
                    ...currentNode.data.contents.footerText,
                    [locale]: currentNode.data.contents.footerText[locale] ?? "",
                };

                tempContent.response['successResponse'] = {
                    ...currentNode.data.contents.response.successResponse,
                    [locale]: currentNode.data.contents.response.successResponse[locale] ?? "",
                };

                tempContent.response['failureResponse'] = {
                    ...currentNode.data.contents.response.failureResponse,
                    [locale]: currentNode.data.contents.response.failureResponse[locale] ?? "",
                };


                newLocaleContent = tempContent;
                break;
            }

            case "whatsAppCatalogNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                };

                tempContent["headerText"] = {
                    ...currentNode.data.contents.headerText,
                    [locale]: "",
                };

                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: "",
                };

                tempContent["footerText"] = {
                    ...currentNode.data.contents.footerText,
                    [locale]: "",
                };
                newLocaleContent = tempContent;
                break;
            }
            case "rcsStandAloneNode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    title: {},
                    description: {},
                    buttons: []
                };

                tempContent["title"] = {
                    ...currentNode.data.contents.title,
                    [locale]: "",
                };

                tempContent["description"] = {
                    ...currentNode.data.contents.description,
                    [locale]: "",
                };

                // converting all the text into locale object
                if (Array.isArray(currentNode.data.contents.buttons)) {
                    tempContent["buttons"] = currentNode.data.contents.buttons.map(
                        (button) => {
                            return {
                                ...button,
                                text: {
                                    ...button.text,
                                    [locale]: button.text[locale] ?? "",
                                },
                            };
                        }
                    );
                }
                newLocaleContent = tempContent;
                break;
            }
            case "whatsAppCTANode": {
                let tempContent = {
                    ...currentNode.data.contents,
                    headerText: {},
                    bodyText: {},
                    footerText: {},
                    text: {},
                };

                tempContent["headerText"] = {
                    ...currentNode.data.contents.headerText,
                    [locale]: currentNode.data.contents.headerText[locale] ?? ""
                };

                tempContent["bodyText"] = {
                    ...currentNode.data.contents.bodyText,
                    [locale]: currentNode.data.contents.bodyText[locale] ?? "",
                };

                tempContent["footerText"] = {
                    ...currentNode.data.contents.footerText,
                    [locale]: currentNode.data.contents.footerText[locale] ?? "",
                };

                tempContent["text"] = {
                    ...currentNode.data.contents.text,
                    [locale]: currentNode.data.contents.text[locale] ?? "",
                };
                newLocaleContent = tempContent;
                break;
            }

            // Non renderable cards will not have any localized content
            default:
                newLocaleContent = currentNode.data.contents;
                break;
        }

        // Handle Localization for validation error message
        let validation = currentNode.data.validation;
        if (
            currentNode.data.validation &&
            currentNode.data.validation.validate &&
            currentNode.data.validation.validate === true &&
            currentNode.data.validation.errorMessage &&
            typeof currentNode.data.validation.errorMessage === "string"
        ) {
            validation = {
                ...currentNode.data.validation,
                errorMessage: {
                    [locale]: currentNode.data.validation.errorMessage,
                },
            };
        }

        newNodesArray.push({
            ...currentNode,
            data: { ...currentNode.data, contents: newLocaleContent, validation },
        });
    }

    return newNodesArray;
};
