export const PricingPlans = [
    {
        id: 1,
        title: 'Free',
        price: 5,
        includedFeatures: [
            'No Platform Fee', '5 active chatbots', '5 Live Agents', '1000 WhatsApp Messages', '10000 Email',
            'Unlimited Conversations on other Channels', 'Free 24x7 Support', 'Advance Reporting'
        ],
        buttonText: 'Sign Up for Free'
    },
]