import axios from "axios";
import { appConfig } from "../config";

const axiosReporting = axios.create({
    baseURL: appConfig.reportingAndAlertServerUrl
});

axiosReporting.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(error || "Something went wrong")
);

export default axiosReporting;
