import styled from "@emotion/styled";
import { Box, CssBaseline, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import GlobalStyle from "../components/GlobalStyle";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
import {
    authorizePage,
    getPermission,
    isAccessible,
} from "../utils/AccessControl";
import { ModuleNames } from "../utils/Constants/AccessControl";
import { useDispatch } from "react-redux";
import { SetPagePermissions } from "../redux/slices/orgData";

const drawerWidth = 110;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;
const getCustomStyleForAppContent = (pathName) => {
    // Adjusting the AppContent width
    // Since the bot attribute header will be dynamic and it could disturb the screen layout
    // Here the table rendering creates problem in layout,It take full 100vw width
    // Thus providing the 100vw minus sidebar width (7rem = 5.833vw) and padding (2rem = 1.667vw)
    // And 5.833 + 1.667 = 7.5
    if (pathName.includes("activity/bot-attributes")) {
        const width = 100 - 7.5;
        const obj = { maxWidth: `${width}vw` };
        return obj;
    }
    if (pathName.includes("activity/whatsApp-payment")) {
        const width = 100 - 7.5;
        const obj = { maxWidth: `${width}vw` };
        return obj;
    }
    return {};
};

const Dashboard = ({ children }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [botsOrFlowsOpen, setBotsOrFlowsOpen] = useState(false);
    const [pagePermission, setPagePermission] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    // const params = useParams();
    // const path = RouteUtility.getRoutePathPattern(location, params);
    const VerifyModule = ["settings", "activity"];

    const customStylesForAppContent = getCustomStyleForAppContent(location.pathname);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const theme = useTheme();
    let isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

    useEffect(() => {
        (async () => {
            let permissions = await getPermission(location.pathname.split("/")[1]);

            let sideBarModulePermission = permissions.find(
                (x) => x.module === ModuleNames.SidebarMenu
            );
            sideBarModulePermission =
                sideBarModulePermission && sideBarModulePermission.permission
                    ? sideBarModulePermission.permission
                    : 0;
            setPagePermission(sideBarModulePermission);
            const page_url = VerifyModule.includes(location.pathname.split("/")[1])
                ? location.pathname.split("/")[2]
                : location.pathname.split("/")[1];
            if (!(await authorizePage(sideBarModulePermission, page_url))) {
                //For Privacy and teams service do not authorize
                if (page_url !== "privacy" && page_url !== "terms-service") {
                    return navigate("/401");
                }
            }
            await dispatch(SetPagePermissions(permissions));
        })();

        if (
            location.pathname.includes("/workflow/") ||
            location.pathname.includes("/master-workflow/") ||
            location.pathname.includes("/activity/broadcastreports") ||
            location.pathname.includes("/activity/urlshortnerreport") ||
            location.pathname.includes("/activity/urlshortnerreport/:id") ||
            location.pathname.includes("/activity/processreports") ||
            location.pathname.includes("/activity/bot-attributes") ||
            location.pathname.includes("/activity/whatsApp-payment") ||
            location.pathname.includes("/whatsapp-flows/")
        ) {
            setBotsOrFlowsOpen(true);
        } else {
            setBotsOrFlowsOpen(false);
        }
        // eslint-disable-next-line
    }, [location]);

    const getDashboardItems = () => {
        if (pagePermission <= 0) return;
        let items = dashboardItems;
        let pages = [];
        for (let i = 0; i < items[0].pages.length; i++) {
            if (isAccessible(pagePermission, items[0].pages[i].permission_code)) {
                pages.push(items[0].pages[i]);
            }
        }
        items[0].pages = pages;

        return items;
    };

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <Drawer style={{ boxShadow: `4px -20px 20px 0px rgba(1, 1, 1, 0.1)` }}>
                <Box
                    sx={{ display: { xs: "block", lg: "none" } }}
                    style={{ boxShadow: `4px -20px 20px 0px rgba(1, 1, 1, 0.1)` }}
                >
                    <Sidebar
                        PaperProps={{ style: { width: drawerWidth } }}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        items={getDashboardItems()}
                        isBotsScreen={true}
                        style={{ boxShadow: `4px -20px 20px 0px rgba(1, 1, 1, 0.1)` }}
                    />
                </Box>
                <Box
                    sx={{ display: { xs: "none", md: "block" } }}
                    style={{ boxShadow: `4px -20px 20px 0px rgba(1, 1, 1, 0.1)` }}
                >
                    <Sidebar
                        PaperProps={{ style: { width: drawerWidth } }}
                        items={getDashboardItems()}
                        isBotsScreen={true}
                        permission={252}
                        style={{ boxShadow: `4px -20px 20px 0px rgba(1, 1, 1, 0.1)` }}
                    />
                </Box>
            </Drawer>
            <AppContent style={customStylesForAppContent}>
                {/*<Navbar onDrawerToggle={handleDrawerToggle} openNotification={openNotification}/>*/}
                <MainContent
                    py={botsOrFlowsOpen ? 0 : isLgUp ? 10 : 5}
                    sx={{ paddingX: botsOrFlowsOpen ? "" : "8%" }}
                >
                    {children}
                    <Outlet />
                </MainContent>
                {!botsOrFlowsOpen && <Footer />}
            </AppContent>
            {/*<Settings/>*/}
            {/*<NotificationsReport openNotification={openNotification} isOpen={isOpen}/>*/}
        </Root>
    );
};

export default Dashboard;
