import styled from "@emotion/styled";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Found from "../../../src/assets/SVG/Found.svg";


import { Grid, Button as MuiButton, Typography } from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";

const Button = styled(MuiButton)(spacing);

function Page404() {
    const LogedInTeamMember = useSelector(store => store.org.LogedInTeamMember);
    const navigate = useNavigate();

    const handleRedirect = () => {
        const accessToken = window.localStorage.getItem("accessToken");
        if (LogedInTeamMember.role === 'editor' && accessToken) {
            navigate("/bots");
        } else if (accessToken && LogedInTeamMember.role !== 'editor') {
            navigate("/dashboard");
        } else if (!accessToken) {
            navigate("/login");
        }
    }

    return (
        <Grid
            container
            sx={{
                minHeight: "70vh",
                alignItems: "center",
                justifyContent: "center",
                gap: '5rem',
                // backgroundColor: "#f4f0f5",
                // position: "relative",
                boxShadow: 9,
                width: "90rem",
                marginLeft: "20px !important"
            }}
        >
            <Helmet title=" 404 Page Not Found" />

            <Grid>
                {/* {Object.keys(LogedInTeamMember).length === 0 ? (
                    <Fragment>
                        <CircularProgress />
                    </Fragment>
                ) : ( */}
                <Grid sx={{ textAlign: "left" }}>
                    <Typography variant="h1" color={'#525252'}>
                        Oops! <br /> The page you're looking for doesn't exist.
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: 5 }}>
                        The page you are looking for might have been removed. <br /> Contact us at  <a href="mailto:support@1spoc.ai" style={{ "text-decoration": "none" ,"color": "black" }}>support@1spoc.ai</a>.
                    </Typography>
                    <Button
                        onClick={handleRedirect}
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ mt: 8 }}
                    >
                        Return to Application
                    </Button>
                </Grid>
                {/* )} */}
            </Grid>
            <img src={Found} style={{ width: '38rem', height: "25rem" }} />
        </Grid>
    );
}

export default Page404;
