import { checkForBracesInVariableString } from '../../pages/pages/Bots/workflow-builder/utils/helpers';
import WorkflowErrors from '../../utils/workflowErrors';
import { renderableCards } from './builderStoreLogic';
import {
    InstagramMessengerValidation,
    MessengerValidation,
    RCSValidation,
    TelegramValidation,
    TubuluValidation,
    WebChatValidation,
    WhatsAppValidation,
} from './channelsValidation';
import { reports_key } from './genericValidation';
import { limitation } from './limitation';
import { NodeValidationWithoutChannel } from './nodeValidationWithoutChannel';
const {
    NODE_DESCRIPTION_MAX_CHARACTER_ERROR,
    OUTPUT_VARIABLE_NOT_SELECTED_ERROR,
    SELECTED_OUTPUT_VARIABLE_NOT_VALID,
    INPUT_VALIDATIONS,
    TUBULU_VALIDATIONS,
    FACEBOOK_MESSENGER_VALIDATIONS,
    INSTAGRAM_VALIDATIONS,
    TELEGRAM_VALIDATIONS,
    WHATSAPP_VALIDATIONS,
    WEBCHAT_VALIDATIONS,
    RCS_VALIDATIONS
} = WorkflowErrors;

// Generating Distinct Channels Name Array for validation
const generateDistinctChannelsIdArray = belongsToChannel => {
    let distinctChannelsIdArray = [];
    if (belongsToChannel && Array.isArray(belongsToChannel) && belongsToChannel.length > 0) {
        // here we are avoiding duplicate channels of nodes
        // reason:- if node is connected to more than one channel switch (here start node is also a channel switch)
        // then it make two different reference for each channel based on channel switch id
        distinctChannelsIdArray = [
            ...new Set(
                belongsToChannel.map(channel => (channel.channelId && typeof channel.channelId === 'string' ? channel.channelId.toLowerCase() : '')),
            ),
        ];
    }
    return distinctChannelsIdArray;
};

// General Validation Start

const validationForComplusoryVariableInNode = outputVariable => {
    let result = { flag: false, messages: [] };
    if (outputVariable === null) {
        result = { flag: true, messages: [OUTPUT_VARIABLE_NOT_SELECTED_ERROR] };
    } else {
        if (!checkForBracesInVariableString(outputVariable.variableName)) {
            result = {
                flag: true,
                messages: [`${outputVariable.variableName} : ${SELECTED_OUTPUT_VARIABLE_NOT_VALID}`],
            };
        }
    }
    return result;
};

const validationForInputValidationChecked = inputValidation => {
    let result = { flag: false, messages: [] };
    if (inputValidation.validationType.trim() === '') {
        result = {
            flag: true,
            messages: [...result.messages, INPUT_VALIDATIONS.VALIDATION_TYPE_NOT_SELECTED_ERROR],
        };
    } else {
        if (inputValidation.validationType.trim() === 'mediaInput') {
            if (inputValidation.mediaInput.mediaTypes === undefined || inputValidation.mediaInput.mediaTypes.trim() === '') {
                result = {
                    flag: true,
                    messages: [...result.messages, INPUT_VALIDATIONS.MEDIA_TYPE_NOT_SELECTED_ERROR],
                };
            }
        }
    }
    if (inputValidation.isOnErrorHandleConnected === false) {
        result = {
            flag: true,
            messages: [...result.messages, INPUT_VALIDATIONS.ON_ERROR_HANDLE_NOT_CONNECTED],
        };
    }
    return result;
};

// validation [ is timeout handle is connected or not]
const validationForInputTimeoutChecked = inputTimeout => {
    let result = { flag: false, messages: [] };
    if (inputTimeout.isTimeoutHandleConnected === false) {
        result = {
            flag: true,
            messages: [...result.messages, INPUT_VALIDATIONS.INPUT_TIMEOUT_HANDLE_NOT_CONNECTED],
        };
    }
    return result;
};

// General Validation End

// Channels Base Validation Start

const buttonNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        distinctChannelsIdArray.forEach(channelId => {
            let validationResult = null;
            switch (channelId) {
                case 'whatsapp':
                case 'whatsapp_pinnacle_bsp':
                    validationResult = WhatsAppValidation.validateButton(data);
                    break;
                case 'facebook_messenger':
                    validationResult = MessengerValidation.validateButton(data);
                    break;
                case 'instagram_messenger':
                    validationResult = InstagramMessengerValidation.validateButtonNode(data);
                    break;
                case 'telegram':
                    validationResult = TelegramValidation.validateButton(data);
                    break;
                case 'rcs': {
                    validationResult = RCSValidation.validateButton(data);
                    break;
                }
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });

        if (result.flag === false) {
            result = NodeValidationWithoutChannel.validateButton(data);
        }

        if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
            const inputValidationResult = validationForInputValidationChecked(data.validation);
            if (inputValidationResult !== null && inputValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputValidationResult.messages],
                };
            }
        }
        if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
            const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
            if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                };
            }
        }
        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        return result;
    },
};

// Text Message Node Validation
const messageNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        result = NodeValidationWithoutChannel.validateTextMessage(data);
        if (result.flag === false) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'whatsapp':
                    case 'whatsapp_pinnacle_bsp':
                        validationResult = WhatsAppValidation.validateTextMessage(data);
                        break;
                    case 'tubulu':
                        validationResult = TubuluValidation.validateTextMessage(data);
                        break;
                    case 'facebook_messenger':
                        validationResult = MessengerValidation.validateTextMessage(data);
                        break;
                    case 'instagram_messenger':
                        validationResult = InstagramMessengerValidation.validateTextMessage(data);
                        break;
                    case 'telegram':
                        validationResult = TelegramValidation.validateTextMessage(data);
                        break;
                    case 'webchat':
                        break;
                    case "rcs": {
                        validationResult = RCSValidation.validateTextMessage(data);
                        break;
                    }
                    default:
                        break;
                }
                if (validationResult !== null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });
        }
        return result;
    },
};
const questionNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        result = NodeValidationWithoutChannel.validateTextQuestion(data);
        if (result.flag === false) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'whatsapp':
                    case 'whatsapp_pinnacle_bsp':
                        validationResult = WhatsAppValidation.validateTextQuestion(data);
                        break;
                    case 'tubulu':
                        validationResult = TubuluValidation.validateTextQuestion(data);
                        break;
                    case 'facebook_messenger':
                        validationResult = MessengerValidation.validateTextQuestion(data);
                        break;
                    case 'instagram_messenger':
                        validationResult = InstagramMessengerValidation.validateTextQuestion(data);
                        break;
                    case 'telegram':
                        validationResult = TelegramValidation.validateTextQuestion(data);
                        break;
                    case 'webchat':
                        break;
                    case 'rcs':
                        validationResult = RCSValidation.validateTextQuestion(data);
                        break;
                    default:
                        break;
                }

                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });
        }
        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
            const inputValidationResult = validationForInputValidationChecked(data.validation);
            if (inputValidationResult !== null && inputValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputValidationResult.messages],
                };
            }
        }
        if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
            const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
            if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                };
            }
        }
        return result;
    },
};

const listNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        distinctChannelsIdArray.forEach(channelId => {
            let validationResult = null;
            switch (channelId) {
                case 'tubulu': {
                    validationResult = {
                        flag: true,
                        messages: [TUBULU_VALIDATIONS.LIST_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'facebook_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [FACEBOOK_MESSENGER_VALIDATIONS.LIST_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'instagram_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [INSTAGRAM_VALIDATIONS.LIST_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'telegram': {
                    validationResult = {
                        flag: true,
                        messages: [TELEGRAM_VALIDATIONS.LIST_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'webchat': {
                    validationResult = {
                        flag: true,
                        messages: [WEBCHAT_VALIDATIONS.LIST_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'rcs': {
                    validationResult = {
                        flag: true,
                        messages: [RCS_VALIDATIONS.LIST_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });
        // ===============================================================================

        if (result.flag === false) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'whatsapp':
                    case 'whatsapp_pinnacle_bsp':
                        validationResult = WhatsAppValidation.validateList(data);
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });
            if (result.flag === false) {
                result = NodeValidationWithoutChannel.validateList(data);
            }

            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }
            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }
            const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
        }
        return result;
    },
};



const carouselNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // TODO : COMPLETE THE PROPER VALIDATION WITH TUBULU DOCUMENTATION & Character limit for all channels


        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        if (distinctChannelsIdArray.length > 0) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'telegram':
                        validationResult = {
                            flag: true,
                            messages: [TELEGRAM_VALIDATIONS.CAROUSEL_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'webchat':
                        validationResult = {
                            flag: true,
                            messages: [WEBCHAT_VALIDATIONS.CAROUSEL_NODE_NOT_SUPPORTED],
                        };
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });
        }
        // ===============================================================================


        if (result.flag === false || data.version === '2.0') { // case to validate carousel node in version 2.0
            result = NodeValidationWithoutChannel.validateCarouselNode(data);
            if (result.flag === false) {
                distinctChannelsIdArray.forEach(channelId => {
                    let validationResult = null;
                    switch (channelId) {
                        case 'tubulu':
                            validationResult = TubuluValidation.validateCarousel(data);
                            break;
                        case 'facebook_messenger':
                            validationResult = MessengerValidation.validateCarousel(data);
                            break;
                        case 'instagram_messenger':
                            validationResult = InstagramMessengerValidation.validateCarousel(data);
                            break;
                        case 'rcs':
                            validationResult = RCSValidation.validateCarouselNode(data);
                            break;
                        case 'whatsapp':
                        case 'whatsapp_pinnacle_bsp':
                            validationResult = WhatsAppValidation.validateCarousel(data);
                        default:
                            break;
                    }
                    if (validationResult != null && validationResult.flag === true)
                        result = {
                            flag: true,
                            messages: [...result.messages, ...validationResult.messages],
                        };
                });
            }

            // --------------------------- COMPULSORY VALIDATIONS ------------------------------------

            // for connect On Error check
            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }

            // for output variable check
            const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }

            // input time error handler node validation 
            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }
        }


        return result;
    },
};

const mediaListNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationMediaListNode(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const locationOutputNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        if (distinctChannelsIdArray.length > 0) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'tubulu':
                        validationResult = {
                            flag: true,
                            messages: [TUBULU_VALIDATIONS.LOCATION_OUTPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'facebook_messenger':
                        validationResult = {
                            flag: true,
                            messages: [FACEBOOK_MESSENGER_VALIDATIONS.LOCATION_OUTPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'instagram_messenger':
                        validationResult = {
                            flag: true,
                            messages: [INSTAGRAM_VALIDATIONS.LOCATION_OUTPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'webchat':
                        validationResult = {
                            flag: true,
                            messages: [WEBCHAT_VALIDATIONS.LOCATION_OUTPUT_NODE_NOT_SUPPORTED],
                        };
                        break;

                    case 'rcs': {
                        validationResult = {
                            flag: true,
                            messages: [RCS_VALIDATIONS.LOCATION_OUTPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    }
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });
        }
        // ===============================================================================
        if (result.flag === false) {
            result = NodeValidationWithoutChannel.validationLocationOutputNode(data);
        }
        return result;
    },
};

const locationInputNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        if (distinctChannelsIdArray.length > 0) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'tubulu':
                        validationResult = {
                            flag: true,
                            messages: [TUBULU_VALIDATIONS.LOCATION_INPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'facebook_messenger':
                        validationResult = {
                            flag: true,
                            messages: [FACEBOOK_MESSENGER_VALIDATIONS.LOCATION_INPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'instagram_messenger':
                        validationResult = {
                            flag: true,
                            messages: [INSTAGRAM_VALIDATIONS.LOCATION_INPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'webchat':
                        validationResult = {
                            flag: true,
                            messages: [WEBCHAT_VALIDATIONS.LOCATION_INPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    case 'rcs': {
                        validationResult = {
                            flag: true,
                            messages: [RCS_VALIDATIONS.LOCATION_INPUT_NODE_NOT_SUPPORTED],
                        };
                        break;
                    }
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });
        }
        // ===============================================================================

        if (result.flag === false) {
            if (result.flag === false) {
                result = NodeValidationWithoutChannel.validationLocationInputNode(data);
            }
            const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }
            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }
        }
        return result;
    },
};

const dynamicButtonNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationDynamicButtonNode(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
            const inputValidationResult = validationForInputValidationChecked(data.validation);
            if (inputValidationResult !== null && inputValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputValidationResult.messages],
                };
            }
        }
        if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
            const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
            if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                };
            }
        }
        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        return result;
    },
};

const dynamicListNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        if (distinctChannelsIdArray.length > 0) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'tubulu': {
                        validationResult = {
                            flag: true,
                            messages: [TUBULU_VALIDATIONS.DYNAMIC_LIST_NOT_SUPPORTED],
                        };
                        break;
                    }
                    case 'facebook_messenger': {
                        validationResult = {
                            flag: true,
                            messages: [FACEBOOK_MESSENGER_VALIDATIONS.DYNAMIC_LIST_NOT_SUPPORTED],
                        };
                        break;
                    }
                    case 'instagram_messenger': {
                        validationResult = {
                            flag: true,
                            messages: [INSTAGRAM_VALIDATIONS.DYNAMIC_LIST_NOT_SUPPORTED],
                        };
                        break;
                    }
                    case 'telegram': {
                        validationResult = {
                            flag: true,
                            messages: [TELEGRAM_VALIDATIONS.DYNAMIC_LIST_NOT_SUPPORTED],
                        };
                        break;
                    }
                    case 'webchat': {
                        validationResult = {
                            flag: true,
                            messages: [WEBCHAT_VALIDATIONS.DYNAMIC_LIST_NOT_SUPPORTED],
                        };
                        break;
                    }
                    case 'rcs': {
                        validationResult = {
                            flag: true,
                            messages: [RCS_VALIDATIONS.DYNAMIC_LIST_NOT_SUPPORTED],
                        };
                        break;
                    }
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
                }
            });
        }
        // ===============================================================================

        if (result.flag === false) {
            let validationResult = NodeValidationWithoutChannel.validationDynamicListNode(data);
            if (validationResult != null && validationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
            }
            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }
            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }
            const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
        }
        return result;
    },
};

const mediaNodeValidations = {
    validate: (data) => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        let validationResult = NodeValidationWithoutChannel.validateMediaNode(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        distinctChannelsIdArray.forEach((channelId) => {
            let validationResult = null;



            switch (channelId) {
                case 'whatsapp':
                case 'whatsapp_pinnacle_bsp':
                    break;
                case 'tubulu':
                    validationResult = TubuluValidation.validateMediaNode(data);
                    break;
                case 'facebook_messenger':
                    break;
                case 'instagram_messenger':
                    validationResult = InstagramMessengerValidation.validateMediaNode(data);
                    break;
                case 'telegram':
                    break;
                case 'webchat':
                    validationResult = WebChatValidation.validateMediaNode(data);
                    break;
                case 'rcs':
                    validationResult = RCSValidation.validateMediaNode(data);
                    break;
                default:
                    break;
            }

            if (data.contents.mediaType === "gif" && channelId !== "rcs") {
                validationResult = {
                    flag: true,
                    messages: [`GIF not supported in the ${channelId} channel`],
                };
            }


            if (validationResult != null && validationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
            }
        });

        return result;
    },
};


const mediaInputNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        distinctChannelsIdArray.forEach(channelId => {
            let validationResult = null;
            switch (channelId) {
                case 'whatsapp':
                case 'whatsapp_pinnacle_bsp':
                    break;
                case 'tubulu':
                    break;
                case 'facebook_messenger':
                    break;
                case 'instagram_messenger':
                    validationResult = InstagramMessengerValidation.validateMediaInputNode(data);
                    break;
                case 'telegram':
                    break;
                case 'webchat':
                    break;
                case 'rcs':
                    validationResult = RCSValidation.validateInputMediaNode(data)
                    break;
                default:
                    break;
            }




            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });

        let validationResult = NodeValidationWithoutChannel.validateMediaInputNode(data);

        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
            const inputValidationResult = validationForInputValidationChecked(data.validation);
            if (inputValidationResult !== null && inputValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputValidationResult.messages],
                };
            }
        }
        if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
            const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
            if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                };
            }
        }
        return result;
    },
};

// Channels Base Validation End

// Logical Base Validation Start

const channelSwitchCardValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        return result;
    },
};

const simpleConditionNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateSimpleConditionNode(data);
        if (validationResult != null && validationResult.flag === true)
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        return result;
    },
};

const complexConditionNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateSwitchConditionNode(data);
        if (validationResult != null && validationResult.flag === true)
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        return result;
    },
};

const webHookCardValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateWebhookNode(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        return result;
    },
};

const loopBackNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationLoopBackNode(data);
        if (validationResult != null && validationResult.flag === true)
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        return result;
    },
};

const iterationGuardNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationIterationGuard(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }

        return result;
    },
};

const subFlowNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationSubFlowNode(data);
        if (validationResult != null && validationResult.flag === true)
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        return result;
    },
};

const delayNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationDelayNode(data);
        if (validationResult != null && validationResult.flag === true)
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        return result;
    },
};

const sendSMSCardValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationSendSMS(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const sendEmailCardValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationSendEmail(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const jsonMapperCardValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationJsonMapper(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        return result;
    },
};

const saveRecipientDataNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validationSaveRecipientData(data);
        if (validationResult != null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const startNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResultMessages = NodeValidationWithoutChannel.validateStartNode(data);
        if (validationResultMessages && validationResultMessages.length > 0) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResultMessages],
            };
        }
        return result;
    },
};

const liveAgentNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateLiveAgentNode(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        return result;
    },
};

const documentCognitionNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateDocumentCognitionNode(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const variableAssignmentNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateVariableAssignmentNode(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const constantAssignmentCardValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateConstantAssignmentCard(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const registerHttpTriggerNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.validateRegisterHttpTriggerNode(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const httpTriggerNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };

        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }
        if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
            const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
            if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                };
            }
        }
        return result;
    },
};

const responseCardValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.responseCard(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const broadcastNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.broadcastNode(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }

        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }

        return result;
    },
};

const broadcastSMSNodeValidation = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.broadcastSMSNode(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }

        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }

        return result;
    },
};

const setLocaleNodeValidation = {
    validate: (data, localeSupportedByBot) => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.setLocaleNode(data, localeSupportedByBot);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }
        return result;
    },
};

const whatsAppFlowsNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        distinctChannelsIdArray.forEach(channelId => {
            let validationResult = null;
            switch (channelId) {
                case 'tubulu': {
                    validationResult = {
                        flag: true,
                        messages: [TUBULU_VALIDATIONS.WHATSAPP_FLOWS_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'facebook_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [FACEBOOK_MESSENGER_VALIDATIONS.WHATSAPP_FLOWS_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'instagram_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [INSTAGRAM_VALIDATIONS.WHATSAPP_FLOWS_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'telegram': {
                    validationResult = {
                        flag: true,
                        messages: [TELEGRAM_VALIDATIONS.WHATSAPP_FLOWS_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'webchat': {
                    validationResult = {
                        flag: true,
                        messages: [WEBCHAT_VALIDATIONS.WHATSAPP_FLOWS_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'rcs': {
                    validationResult = {
                        flag: true,
                        messages: [RCS_VALIDATIONS.WHATSAPP_FLOWS_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });
        // ===============================================================================

        if (result.flag === false) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'whatsapp':
                    case 'whatsapp_pinnacle_bsp':
                        validationResult = WhatsAppValidation.validateFlows(data);
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });

            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }

            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }

            const variableResult = validationForComplusoryVariableInNode(data.outputVariable);

            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
        }
        return result;
    },
};

const whatsAppFlowsTemplateValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        distinctChannelsIdArray.forEach(channelId => {
            let validationResult = null;
            switch (channelId) {
                case 'tubulu': {
                    validationResult = {
                        flag: true,
                        messages: [TUBULU_VALIDATIONS.WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'facebook_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [FACEBOOK_MESSENGER_VALIDATIONS.WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'instagram_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [INSTAGRAM_VALIDATIONS.WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'telegram': {
                    validationResult = {
                        flag: true,
                        messages: [TELEGRAM_VALIDATIONS.WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'webchat': {
                    validationResult = {
                        flag: true,
                        messages: [WEBCHAT_VALIDATIONS.WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'rcs': {
                    validationResult = {
                        flag: true,
                        messages: [RCS_VALIDATIONS.WHATSAPP_TEMPLATE_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });
        // ===============================================================================

        if (result.flag === false) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'whatsapp':
                    case 'whatsapp_pinnacle_bsp':
                        validationResult = WhatsAppValidation.validateTemplate(data);
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });

            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }

            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }

            const variableResult = validationForComplusoryVariableInNode(data.outputVariable);

            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
        }
        return result;
    },
};

const whatsAppPaymentValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        distinctChannelsIdArray.forEach(channelId => {
            let validationResult = null;
            switch (channelId) {
                case 'tubulu': {
                    validationResult = {
                        flag: true,
                        messages: [TUBULU_VALIDATIONS.WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'facebook_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [FACEBOOK_MESSENGER_VALIDATIONS.WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'instagram_messenger': {
                    validationResult = {
                        flag: true,
                        messages: [INSTAGRAM_VALIDATIONS.WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'telegram': {
                    validationResult = {
                        flag: true,
                        messages: [TELEGRAM_VALIDATIONS.WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'webchat': {
                    validationResult = {
                        flag: true,
                        messages: [WEBCHAT_VALIDATIONS.WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case 'rcs': {
                    validationResult = {
                        flag: true,
                        messages: [RCS_VALIDATIONS.WHATSAPP_PAYMENT_NODE_NOT_SUPPORTED]
                    };
                    break;
                }
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });
        // ===============================================================================

        if (result.flag === false) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'whatsapp':
                    case 'whatsapp_pinnacle_bsp':
                        validationResult = WhatsAppValidation.validatePayment(data);
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });

            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }

            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }

            const variableResult = validationForComplusoryVariableInNode(data.outputVariable);

            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
        }
        return result;
    },
};
// Logical Base Validation End

const connectorNode = {
    validate: data => {
        let result = { flag: false, messages: [] };
        // let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        switch (data.connectorType) {
            case 'zohoCrm':
                result = NodeValidationWithoutChannel.validateZohoCrmNode(data);
                break;
            default:
                break;
        }
        // let validationResult = null;

        // if (result.flag === false) {
        //     distinctChannelsIdArray.forEach((channelId) => {
        //         let validationResult = null;
        //         switch (channelId) {
        //             case "whatsapp":
        //             case "whatsapp_pinnacle_bsp":
        //                 validationResult = WhatsAppValidation.validateTextMessage(data);
        //                 break;
        //             case "tubulu":
        //                 validationResult = TubuluValidation.validateTextMessage(data);
        //                 break;
        //             case "facebook_messenger":
        //                 validationResult = MessengerValidation.validateTextMessage(data);
        //                 break;
        //             case 'instagram_messenger':
        //                 validationResult = InstagramMessengerValidation.validateTextMessage(data);
        //                 break;
        //             case "telegram":
        //                 validationResult = TelegramValidation.validateTextMessage(data);
        //                 break;
        //             case "webchat":
        //                 break;
        //             default:
        //                 break;
        //         }
        //         if (validationResult !== null && validationResult.flag === true)
        //             result = {
        //                 flag: true,
        //                 messages: [...result.messages, ...validationResult.messages],
        //             };
        //     });
        // }
        return result;
    },
};






const messagingServiceNodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let validationResult = NodeValidationWithoutChannel.messagingServiceNode(data);
        if (validationResult !== null && validationResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...validationResult.messages],
            };
        }

        const variableResult = validationForComplusoryVariableInNode(data.outputVariable);
        if (variableResult !== null && variableResult.flag === true) {
            result = {
                flag: true,
                messages: [...result.messages, ...variableResult.messages],
            };
        }

        return result;
    },
    // Logical Base Validation End
};
export const nodeValidationsLogic = {
    validateNodeForChannel: (_nodeToValidate, localeSupportedByBot) => {
        let reports = { errors: {}, warnings: {} };
        if (_nodeToValidate) {
            if (_nodeToValidate.data.reports && _nodeToValidate.data.reports.errors) {
                reports = {
                    ...reports,
                    errors: _nodeToValidate.data.reports.errors,
                };
            }
            if (_nodeToValidate.data.reports && _nodeToValidate.data.reports.warnings)
                reports = {
                    ...reports,
                    warnings: { ..._nodeToValidate.data.reports.warnings },
                };

            // validating node description for max 100 characters.
            // reports.errors[reports_key.error_key.NODE_DESCRIPTION.key] = nodeValidationsLogic.validateNodeDescription(_nodeToValidate);
            reports.errors = {
                ...reports.errors,
                [reports_key.error_key.NODE_DESCRIPTION.key]: nodeValidationsLogic.validateNodeDescription(_nodeToValidate),
            };

            //------------------------------------------------------------------------------------
            // Validation for Set Locale Node
            if (_nodeToValidate.type === 'setLocaleNode') {
                const validationResult = setLocaleNodeValidation.validate(_nodeToValidate.data, localeSupportedByBot);
                reports.errors[reports_key.error_key.NODE_CONTENT.key] = {
                    messages: validationResult.messages,
                };
                return reports;
            }
            //--------------------------------------------------------------------------------------

            // First check if the current node is renderable node
            // If yes then perform the logic else do the previous one
            // Here we are performing a trick[HACK] to not distrub the previous validation that we have writtern
            // We will change this later
            // This hack was written because validation does not update any state, except main errors state and it is a read only operation

            reports.errors[reports_key.error_key.NODE_CONTENT.key] = { messages: [] };
            if (renderableCards.includes(_nodeToValidate.type)) {
                // nodeValidationsLogic.mainValidation(_nodeToValidate);

                Object.keys(localeSupportedByBot).forEach(localeContentKeyName => {
                    // Only validate those locale which are enabled in our bot
                    if (localeSupportedByBot[localeContentKeyName] === true) {
                        // This is a hack
                        const generatedContentWithoutLocale = nodeValidationsLogic.generateNodeContentBasedForValidation(
                            _nodeToValidate,
                            localeContentKeyName,
                        );
                        const nodeToValidate = {
                            ..._nodeToValidate,
                            data: {
                                ..._nodeToValidate.data,
                                contents: generatedContentWithoutLocale,
                            },
                        };
                        const validationResult = nodeValidationsLogic.mainValidation(nodeToValidate);

                        //  #TODO :- Future Implentation should like belowed code
                        // if (!reports.errors[reports_key.error_key.NODE_CONTENT.key][localeContentKeyName]) {
                        //     reports.errors[reports_key.error_key.NODE_CONTENT.key] = {
                        //         [localeContentKeyName]: validationResult
                        //     };
                        // } else {
                        //     reports.errors[reports_key.error_key.NODE_CONTENT.key] = {
                        //         ...reports.errors[reports_key.error_key.NODE_CONTENT.key],
                        //         [localeContentKeyName]: validationResult
                        //     };
                        // }

                        // #TODO : TEMP Code

                        let reportMessages = [];
                        if (
                            reports.errors[reports_key.error_key.NODE_CONTENT.key] &&
                            reports.errors[reports_key.error_key.NODE_CONTENT.key].messages
                        ) {
                            reportMessages = reports.errors[reports_key.error_key.NODE_CONTENT.key].messages;
                        }
                        reports.errors[reports_key.error_key.NODE_CONTENT.key] = {
                            messages: [
                                ...reportMessages,
                                // For now we are going with only one locale English
                                ...validationResult.messages.map(errorMessage => `For Locale (${localeContentKeyName}) : ${errorMessage}`),
                                // ...validationResult.messages.map((errorMessage) => `${errorMessage}`)
                            ],
                        };
                        // errorMessages = [...errorMessages, ...validationResult.messages.map((errorMessage) => `For Locale (${localeContentKeyName}) : ${errorMessage}`)]
                    }
                });
                return reports;
                // return {
                //     flag: errorMessages.length > 0,
                //     messages: errorMessages
                // }
            } else {
                const validationResult = nodeValidationsLogic.mainValidation(_nodeToValidate);
                reports.errors[reports_key.error_key.NODE_CONTENT.key] = {
                    messages: validationResult.messages,
                };
                return reports;
                // if (validationResult && validationResult.messages && Array.isArray(validationResult.messages)) {
                //     errorMessages = [...errorMessages, ...validationResult.messages]
                // }
                // return {
                //     flag: errorMessages.length > 0,
                //     messages: errorMessages
                // }
            }
        }
        return reports;
    },

    mainValidation: nodeToValidate => {
        switch (nodeToValidate.type) {
            // Channels Base Validation Start

            // =============================== Renderable nodes validations ===============================
            case 'startNode':
                return startNodeValidation.validate(nodeToValidate.data);
            case 'plainMessageTextNode': {
                return messageNodeValidations.validate(nodeToValidate.data);
            }
            case 'questionNode':
                return questionNodeValidations.validate(nodeToValidate.data);
            case 'buttonNode':
                return buttonNodeValidations.validate(nodeToValidate.data);
            case 'listNode':
                return listNodeValidations.validate(nodeToValidate.data);
            case 'carouselNode':
                return carouselNodeValidations.validate(nodeToValidate.data);
            case 'dynamicButtonNode':
                return dynamicButtonNodeValidations.validate(nodeToValidate.data);
            case 'dynamicListNode':
                return dynamicListNodeValidations.validate(nodeToValidate.data);
            case 'locationInputNode':
                return locationInputNodeValidations.validate(nodeToValidate.data);
            case 'mediaNode':
                return mediaNodeValidations.validate(nodeToValidate.data);
            case 'mediaInputNode':
                return mediaInputNodeValidations.validate(nodeToValidate.data);
            case 'loopBackNode':
                return loopBackNodeValidations.validate(nodeToValidate.data);
            case 'mediaListNode':
                return mediaListNodeValidations.validate(nodeToValidate.data);
            case 'locationOutputNode':
                return locationOutputNodeValidations.validate(nodeToValidate.data);
            // =============================== Renderable nodes validations ===============================

            // Channels Base Validation End

            // Logical Base Validation Start
            // =============================== logicall node validation ===============================
            case 'webhookNode':
                return webHookCardValidations.validate(nodeToValidate.data);
            case 'conditionNode':
                return simpleConditionNodeValidations.validate(nodeToValidate.data);
            case 'switchConditionNode':
                return complexConditionNodeValidations.validate(nodeToValidate.data);
            case 'iterationGuardNode':
                return iterationGuardNodeValidations.validate(nodeToValidate.data);
            case 'channelSwitchNode':
                return channelSwitchCardValidations.validate(nodeToValidate.data);
            case 'subFlowNode':
                return subFlowNodeValidations.validate(nodeToValidate.data);

            case 'delayNode':
                return delayNodeValidations.validate(nodeToValidate.data);

            case 'sendSMSCard':
                return sendSMSCardValidations.validate(nodeToValidate.data);

            case 'sendEmailCard':
                return sendEmailCardValidations.validate(nodeToValidate.data);

            case 'jsonMapperCard':
                return jsonMapperCardValidation.validate(nodeToValidate.data);

            case 'saveRecipientDataNode':
                return saveRecipientDataNodeValidation.validate(nodeToValidate.data);

            case 'liveAgentNode':
                return liveAgentNodeValidation.validate(nodeToValidate.data);

            case 'documentCognitionCard':
                return documentCognitionNodeValidation.validate(nodeToValidate.data);

            case 'variableAssignmentNode': {
                return variableAssignmentNodeValidation.validate(nodeToValidate.data);
            }

            case 'constantAssignmentCard': {
                return constantAssignmentCardValidation.validate(nodeToValidate.data);
            }

            case 'responseCard': {
                return responseCardValidation.validate(nodeToValidate.data);
            }

            case 'broadcastNode': {
                return broadcastNodeValidation.validate(nodeToValidate.data);
            }

            case 'registerHttpTriggerNode': {
                return registerHttpTriggerNodeValidation.validate(nodeToValidate.data);
            }

            case 'httpTriggerNode': {
                return httpTriggerNodeValidation.validate(nodeToValidate.data);
            }

            case 'broadcastSMSNode': {
                return broadcastSMSNodeValidation.validate(nodeToValidate.data);
            }

            case 'whatsAppFlowsNode': {
                return whatsAppFlowsNodeValidations.validate(nodeToValidate.data);
            }

            case 'whatsAppTemplateNode': {
                return whatsAppFlowsTemplateValidations.validate(nodeToValidate.data);
            }

            case 'whatsAppPaymentNode': {
                return whatsAppPaymentValidations.validate(nodeToValidate.data);
            }

            case 'connectorNode': {
                return connectorNode.validate(nodeToValidate.data);
            }

            case 'setLocaleNode': {
                //
                break;
            }

            case 'messagingServiceNode': {
                return messagingServiceNodeValidations.validate(nodeToValidate.data);
            }

            case "whatsAppCatalogNode": {
                return whatsAppCatalogValidations.validate(nodeToValidate.data);
            }

            case 'whatsAppCTANode': {
                return whatsAppCTANodeValidations.validate(nodeToValidate.data);
            }
            // =============================== logicall node validation ===============================
            // Logical Base Validation End
            // ==================================== stand Alone Node ===============================
            case 'rcsStandAloneNode':
                return rcsStandAloneNodeValidations.validate(nodeToValidate.data);

            // ==================================== stand Alone Node ===============================
            default:
                return { messages: [] };
        }
    },
    /** This method convert current content to previous content for which is without locale  */
    generateNodeContentBasedForValidation: (nodeToValidate, locale) => {
        let content = null;
        const version = nodeToValidate.data.version;

        switch (nodeToValidate.type) {
            case 'plainMessageTextNode':
            case 'questionNode': {
                content = nodeToValidate.data.contents[locale] ? nodeToValidate.data.contents[locale] : '';
                break;
            }

            case 'buttonNode': {
                let tempContent = {
                    ...nodeToValidate.data.contents,
                    headerText: '',
                    bodyText: '',
                    footerText: '',
                    buttons: [],
                    // isMediaRender: nodeToValidate.data.contents.isMediaRender
                };

                tempContent['headerText'] = nodeToValidate.data.contents.headerText[locale] ? nodeToValidate.data.contents.headerText[locale] : '';
                tempContent['bodyText'] = nodeToValidate.data.contents.bodyText[locale] ? nodeToValidate.data.contents.bodyText[locale] : '';
                tempContent['footerText'] = nodeToValidate.data.contents.footerText[locale] ? nodeToValidate.data.contents.footerText[locale] : '';

                if (Array.isArray(nodeToValidate.data.contents.buttons)) {
                    tempContent['buttons'] = nodeToValidate.data.contents.buttons.map(button => {
                        return {
                            ...button,
                            value: button.value[locale] ? button.value[locale] : '',
                        };
                    });
                }

                content = tempContent;
                break;
            }

            case 'listNode': {
                let tempContent = {
                    openButtonText: '',
                    bodyText: '',
                    list: [],
                };

                tempContent['openButtonText'] = nodeToValidate.data.contents.openButtonText[locale]
                    ? nodeToValidate.data.contents.openButtonText[locale]
                    : '';
                tempContent['bodyText'] = nodeToValidate.data.contents.bodyText[locale] ? nodeToValidate.data.contents.bodyText[locale] : '';

                if (Array.isArray(nodeToValidate.data.contents.list)) {
                    tempContent['list'] = nodeToValidate.data.contents.list.map(listItem => {
                        return {
                            ...listItem,
                            value: listItem.value[locale] ? listItem.value[locale] : '',
                        };
                    });
                }
                content = tempContent;
                break;
            }

            case 'carouselNode': {
                switch (version) {

                    case '2.0': {

                        //=========================================For FB Messenger ==================================================
                        let tempContentForFbMessenger = {
                            ...nodeToValidate.data.contents.fbMessenger,
                            bodyText: {},
                            carousels: [],
                            buttons: [],
                        };

                        // converting all the value into locale object
                        if (!nodeToValidate.data.contents.fbMessenger.getPayloadFromVariable && Array.isArray(nodeToValidate.data.contents.fbMessenger.carousels)) {
                            tempContentForFbMessenger["carousels"] = nodeToValidate.data.contents.fbMessenger.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: item.buttonName[locale] ?? "",
                                        };
                                    });
                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        title: listItem.title[locale] ?? "",
                                        bodyText: listItem.bodyText[locale] ?? "",
                                    };
                                }
                            );
                        }

                        //=========================================For Instagram ==================================================

                        let tempContentForInstagram = {
                            ...nodeToValidate.data.contents.instagram,
                            bodyText: {},
                            carousels: [],
                            buttons: [],
                        };

                        // converting all the value into locale object
                        if (!nodeToValidate.data.contents.instagram.getPayloadFromVariable && Array.isArray(nodeToValidate.data.contents.instagram.carousels)) {
                            tempContentForInstagram["carousels"] = nodeToValidate.data.contents.instagram.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: item.buttonName[locale] ?? "",
                                        };
                                    });
                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        title: listItem.title[locale] ?? "",
                                        bodyText: listItem.bodyText[locale] ?? "",
                                    };
                                }
                            );
                        }

                        // -------------------------------------- For Tubulu --------------------------------------
                        let tempContentForTubulu = {
                            ...nodeToValidate.data.contents.tubulu,
                            bodyText: {},
                            carousels: [],
                            buttons: [],
                        };

                        if (!nodeToValidate.data.contents.tubulu.getPayloadFromVariable) {
                            tempContentForTubulu["bodyText"] = nodeToValidate.data.contents.tubulu.bodyText[locale] ?? "";
                        }


                        if (nodeToValidate.data.contents.tubulu.suggestionButtons) {
                            tempContentForTubulu["suggestionButtons"] = nodeToValidate.data.contents.tubulu.suggestionButtons.map(
                                (suggestionButtonsItem) => {
                                    return {
                                        ...suggestionButtonsItem,
                                        text: suggestionButtonsItem.text[locale] ?? ''
                                    }

                                });
                        }

                        // converting all the value into locale object
                        if (!nodeToValidate.data.contents.tubulu.getPayloadFromVariable && Array.isArray(nodeToValidate.data.contents.tubulu.carousels)) {
                            tempContentForTubulu["carousels"] = nodeToValidate.data.contents.tubulu.carousels.map(
                                (listItem) => {
                                    const tempButtons = listItem.buttons.map((item) => {
                                        return {
                                            ...item,
                                            buttonName: item.buttonName[locale] ?? "",
                                        };
                                    });
                                    return {
                                        ...listItem,
                                        buttons: tempButtons,
                                        // bodyText: listItem.bodyText[locale] ?? "",
                                    };
                                }
                            );
                        }

                        //=========================================For whatsApp ==================================================

                        let tempContentForWhatsApp = {
                            ...nodeToValidate.data.contents.whatsapp,

                        };


                        //TODO : write case for the whatsApp 

                        // ------------------------------------- FOR RCS -------------------------------------
                        let cardContentsRcs = [];

                        if (!nodeToValidate.data.contents.instagram.getPayloadFromVariable && Array.isArray(nodeToValidate.data.contents.rcs.carousels)) {
                            cardContentsRcs = nodeToValidate.data.contents.rcs.carousels.map(
                                (individualCard) => {
                                    const buttonsList = individualCard.buttons.map((button) => {
                                        return {
                                            ...button,
                                            buttonName: button.buttonName[locale] ?? ""
                                        }
                                    });

                                    return {
                                        ...individualCard,
                                        title: individualCard.title[locale] ?? "",
                                        bodyText: individualCard.bodyText[locale] ?? "",
                                        buttons: buttonsList
                                    };
                                }
                            );
                        }

                        content = {
                            ...nodeToValidate.data.contents, fbMessenger: tempContentForFbMessenger, instagram: tempContentForInstagram, tubulu: tempContentForTubulu,
                            WhatsApp: tempContentForWhatsApp, rcs: { ...nodeToValidate.data.contents.rcs, carousels: cardContentsRcs }
                        };

                        break;
                    }

                    default: {
                        let tempContent = {
                            title: '',
                            bodyText: '',
                            carousels: [],
                            buttons: [],
                        };

                        tempContent['bodyText'] = nodeToValidate.data.contents.bodyText[locale];

                        // converting all the value into locale object
                        if (Array.isArray(nodeToValidate.data.contents.carousels)) {
                            tempContent['carousels'] = nodeToValidate.data.contents.carousels.map(listItem => {
                                const tempButtons = listItem.buttons.map(item => {
                                    return {
                                        ...item,
                                        buttonName: item.buttonName[locale] ? item.buttonName[locale] : '',
                                    };
                                });
                                return {
                                    ...listItem,
                                    bodyText: listItem.bodyText[locale],
                                    title: listItem.title[locale],
                                    buttons: tempButtons,
                                };
                            });
                        }
                        content = tempContent;
                        break;
                    }
                }

                break;
            }

            case 'mediaNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    caption: '',
                };
                tempContent['caption'] = nodeToValidate.data.contents.caption[locale];
                content = tempContent;
                break;
            }

            case 'mediaInputNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    text: '',
                };
                tempContent['text'] = nodeToValidate.data.contents.text[locale];
                content = tempContent;
                break;
            }

            case 'locationInputNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    text: '',
                };
                tempContent['text'] = nodeToValidate.data.contents.text[locale];
                content = tempContent;
                break;
            }

            case 'dynamicButtonNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    bodyText: '',
                };
                tempContent['bodyText'] = nodeToValidate.data.contents.bodyText[locale];
                content = tempContent;
                break;
            }

            case 'dynamicListNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    bodyText: '',
                    openButtonText: '',
                };
                tempContent['bodyText'] = nodeToValidate.data.contents.bodyText[locale];
                tempContent['openButtonText'] = nodeToValidate.data.contents.openButtonText[locale];
                content = tempContent;
                break;
            }

            case 'liveAgentNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    messageAfterLiveAgentConnect: '',
                    liveAgentNotAvailableButtonContents: {
                        bodyText: '',
                        buttons: {
                            register: {
                                value: '',
                            },
                            cancelRegister: {
                                value: '',
                            },
                        },
                    },
                    liveAgentWhenCallbackReceived: {
                        bodyText: '',
                        buttons: {
                            connectToLiveAgent: {
                                value: '',
                            },
                            skipLiveAgentCallback: {
                                value: '',
                            },
                        },
                    },
                    liveAgentDisconnectKeywords: '',
                };
                tempContent.messageAfterLiveAgentConnect = nodeToValidate.data.contents.messageAfterLiveAgentConnect[locale];
                tempContent.liveAgentDisconnectKeywords = nodeToValidate.data.contents.liveAgentDisconnectKeywords[locale];
                tempContent.liveAgentNotAvailableButtonContents.bodyText =
                    nodeToValidate.data.contents.liveAgentNotAvailableButtonContents.bodyText[locale];
                tempContent.liveAgentNotAvailableButtonContents.buttons.register.value =
                    nodeToValidate.data.contents.liveAgentNotAvailableButtonContents.buttons.register.value[locale];
                tempContent.liveAgentNotAvailableButtonContents.buttons.cancelRegister.value =
                    nodeToValidate.data.contents.liveAgentNotAvailableButtonContents.buttons.cancelRegister.value[locale];
                tempContent.liveAgentWhenCallbackReceived.bodyText = nodeToValidate.data.contents.liveAgentWhenCallbackReceived.bodyText[locale];
                tempContent.liveAgentWhenCallbackReceived.buttons.connectToLiveAgent.value =
                    nodeToValidate.data.contents.liveAgentWhenCallbackReceived.buttons.connectToLiveAgent.value[locale];
                tempContent.liveAgentWhenCallbackReceived.buttons.skipLiveAgentCallback.value =
                    nodeToValidate.data.contents.liveAgentWhenCallbackReceived.buttons.skipLiveAgentCallback.value[locale];

                content = tempContent;
                break;
            }

            case 'documentCognitionCard': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    initialMessage: '',
                    modelDisconnectKeywords: [],
                    selectedConfig: {},
                };
                tempContent.initialMessage = nodeToValidate.data.contents.initialMessage[locale];
                tempContent.modelDisconnectKeywords = nodeToValidate.data.contents.modelDisconnectKeywords[locale];
                tempContent.selectedConfig = nodeToValidate.data.contents.selectedConfig;

                content = tempContent;
                break;
            }

            case 'httpTriggerNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    text: '',
                };
                tempContent.text = nodeToValidate.data.contents.text[locale];
                content = tempContent;
                break;
            }

            case 'whatsAppFlowsNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    headerText: '',
                    bodyText: '',
                    footerText: '',
                    openButtonText: '',
                };
                tempContent.headerText = nodeToValidate.data.contents.headerText[locale];
                tempContent.bodyText = nodeToValidate.data.contents.bodyText[locale];
                tempContent.footerText = nodeToValidate.data.contents.footerText[locale];
                tempContent.openButtonText = nodeToValidate.data.contents.openButtonText[locale];
                content = tempContent;
                break;
            }

            case 'whatsAppTemplateNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                };

                content = tempContent;
                break;
            }

            case 'whatsAppPaymentNode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    // headerText: '',
                    bodyText: '',
                    footerText: '',
                    response: { successResponse: '', failureResponse: '' },
                };
                // tempContent.headerText = nodeToValidate.data.contents.headerText[locale];
                tempContent.bodyText = nodeToValidate.data.contents.bodyText[locale];
                tempContent.footerText = nodeToValidate.data.contents.footerText[locale];
                // tempContent.bodyText = nodeToValidate.data.contents.bodyText[locale];
                tempContent.response.successResponse = nodeToValidate.data.contents.response.successResponse[locale];
                tempContent.response.failureResponse = nodeToValidate.data.contents.response.failureResponse[locale];
                content = tempContent;
                break;
            }
            case "whatsAppCatalogNode": {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    headerText: "",
                    bodyText: "",
                    footerText: "",
                };
                tempContent.headerText =
                    nodeToValidate.data.contents.headerText[locale];
                tempContent.bodyText = nodeToValidate.data.contents.bodyText[locale];
                tempContent.footerText =
                    nodeToValidate.data.contents.footerText[locale];
                content = tempContent;
                break;
            }
            case 'whatsAppCTANode': {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    headerText: '',
                    bodyText: '',
                    footerText: '',
                    text: '',
                };
                tempContent.headerText = nodeToValidate.data.contents.headerText[locale];
                tempContent.bodyText = nodeToValidate.data.contents.bodyText[locale];
                tempContent.footerText = nodeToValidate.data.contents.footerText[locale];
                tempContent.text = nodeToValidate.data.contents.text[locale];

                content = tempContent;
                break;
            }
            case "rcsStandAloneNode": {
                const tempContent = {
                    ...nodeToValidate.data.contents,
                    title: "",
                    description: "",
                    buttons: [],

                };
                tempContent['title'] = nodeToValidate.data.contents.title[locale] ? nodeToValidate.data.contents.title[locale] : '';
                tempContent['description'] = nodeToValidate.data.contents.description[locale] ? nodeToValidate.data.contents.description[locale] : '';

                if (Array.isArray(nodeToValidate.data.contents.buttons)) {
                    tempContent['buttons'] = nodeToValidate.data.contents.buttons.map(button => {
                        return {
                            ...button,
                            text: button.text[locale] ? button.text[locale] : '',
                        };
                    });
                }
                content = tempContent;
                break;
            }

            default:
                break;
        }
        return content;
    },

    validateNodeDescription: ({ data }) => {
        if (data.label !== undefined && data.label.length > limitation.description.maxCharacterLength) {
            return { messages: [NODE_DESCRIPTION_MAX_CHARACTER_ERROR] };
        } else {
            return { messages: [] };
        }
    },
};
const whatsAppCatalogValidations = {
    validate: (data) => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(
            data.belongsToChannel
        );

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        distinctChannelsIdArray.forEach((channelId) => {
            let validationResult = null;
            switch (channelId) {
                case "tubulu": {
                    validationResult = {
                        flag: true,
                        messages: [TUBULU_VALIDATIONS.WHATSAPP_CATALOG_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case "facebook_messenger": {
                    validationResult = {
                        flag: true,
                        messages: [
                            FACEBOOK_MESSENGER_VALIDATIONS.WHATSAPP_CATALOG_NODE_NOT_SUPPORTED,
                        ],
                    };
                    break;
                }
                case "instagram_messenger": {
                    validationResult = {
                        flag: true,
                        messages: [INSTAGRAM_VALIDATIONS.WHATSAPP_CATALOG_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case "telegram": {
                    validationResult = {
                        flag: true,
                        messages: [TELEGRAM_VALIDATIONS.WHATSAPP_CATALOG_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case "webchat": {
                    validationResult = {
                        flag: true,
                        messages: [WEBCHAT_VALIDATIONS.WHATSAPP_CATALOG_NODE_NOT_SUPPORTED],
                    };
                    break;
                }
                case "rcs": {
                    validationResult = {
                        flag: true,
                        messages: [RCS_VALIDATIONS.WHATSAPP_CATALOG_NODE_NOT_SUPPORTED]
                    };
                    break;
                }
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });
        // ===============================================================================

        if (result.flag === false) {
            distinctChannelsIdArray.forEach((channelId) => {
                let validationResult = null;
                switch (channelId) {
                    case "whatsapp":
                    case "whatsapp_pinnacle_bsp":
                        validationResult = WhatsAppValidation.validateCatalog(data);
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });

            if (
                data.validation !== undefined &&
                data.validation.validate !== undefined &&
                data.validation.validate === true
            ) {
                const inputValidationResult = validationForInputValidationChecked(
                    data.validation
                );
                if (
                    inputValidationResult !== null &&
                    inputValidationResult.flag === true
                ) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }

            if (
                data.inputTimeout !== undefined &&
                data.inputTimeout.enabled !== undefined &&
                data.inputTimeout.enabled === true
            ) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(
                    data.inputTimeout
                );
                if (
                    inputTimeoutValidationResult !== null &&
                    inputTimeoutValidationResult.flag === true
                ) {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            ...inputTimeoutValidationResult.messages,
                        ],
                    };
                }
            }

            const variableResult = validationForComplusoryVariableInNode(
                data.outputVariable
            );

            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
        }
        return result;
    },
};

const rcsStandAloneNodeValidations = {
    validate: (data) => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(
            data.belongsToChannel
        );

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        distinctChannelsIdArray.forEach((channelId) => {
            let validationResult = null;
            switch (channelId) {
                case "tubulu":
                    validationResult = {
                        flag: true,
                        messages: [TUBULU_VALIDATIONS.RCS_STAND_ALONE_NODE_NOT_SUPPORTED],
                    };
                    break;
                case "facebook_messenger":
                    validationResult = {
                        flag: true,
                        messages: [
                            FACEBOOK_MESSENGER_VALIDATIONS.RCS_STAND_ALONE_NODE_NOT_SUPPORTED,
                        ],
                    };
                    break;
                case "instagram_messenger":
                    validationResult = {
                        flag: true,
                        messages: [INSTAGRAM_VALIDATIONS.RCS_STAND_ALONE_NODE_NOT_SUPPORTED],
                    };
                    break;
                case "telegram":
                    validationResult = {
                        flag: true,
                        messages: [TELEGRAM_VALIDATIONS.RCS_STAND_ALONE_NODE_NOT_SUPPORTED],
                    };
                    break;
                case "webchat":
                    validationResult = {
                        flag: true,
                        messages: [WEBCHAT_VALIDATIONS.RCS_STAND_ALONE_NODE_NOT_SUPPORTED],
                    };
                    break;
                case "whatsapp":
                case "whatsapp_pinnacle_bsp":
                    validationResult = {
                        flag: true,
                        messages: [WHATSAPP_VALIDATIONS.RCS_STAND_ALONE_NODE_NOT_SUPPORTED],
                    };
                    break;
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });
        // ===============================================================================

        if (result.flag === false) {
            distinctChannelsIdArray.forEach((channelId) => {
                let validationResult = null;
                switch (channelId) {
                    case "rcs":
                        validationResult = RCSValidation.validateRCSStandAloneButton(data);
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });

            if (
                data.validation !== undefined &&
                data.validation.validate !== undefined &&
                data.validation.validate === true
            ) {
                const inputValidationResult = validationForInputValidationChecked(
                    data.validation
                );
                if (
                    inputValidationResult !== null &&
                    inputValidationResult.flag === true
                ) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }

            if (
                data.inputTimeout !== undefined &&
                data.inputTimeout.enabled !== undefined &&
                data.inputTimeout.enabled === true
            ) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(
                    data.inputTimeout
                );
                if (
                    inputTimeoutValidationResult !== null &&
                    inputTimeoutValidationResult.flag === true
                ) {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            ...inputTimeoutValidationResult.messages,
                        ],
                    };
                }
            }

            const variableResult = validationForComplusoryVariableInNode(
                data.outputVariable
            );

            if (variableResult !== null && variableResult.flag === true) {
                result = {
                    flag: true,
                    messages: [...result.messages, ...variableResult.messages],
                };
            }
        }
        return result;
    },
};

const whatsAppCTANodeValidations = {
    validate: data => {
        let result = { flag: false, messages: [] };
        let distinctChannelsIdArray = generateDistinctChannelsIdArray(data.belongsToChannel);

        // This temporary implementation checking channel not support
        // reason:- giving the channel not supporting error after fixing node validation which is not good user exprience
        // we should first check is the channel then go for node validation
        distinctChannelsIdArray.forEach(channelId => {
            let validationResult = null;
            switch (channelId) {
                case 'tubulu':
                    validationResult = {
                        flag: true,
                        messages: [TUBULU_VALIDATIONS.WHATSAPP_CTA_NODE_NOT_SUPPORTED],
                    };
                    break;
                case 'facebook_messenger':
                    validationResult = {
                        flag: true,
                        messages: [FACEBOOK_MESSENGER_VALIDATIONS.WHATSAPP_CTA_NODE_NOT_SUPPORTED],
                    };
                    break;
                case 'instagram_messenger':
                    validationResult = {
                        flag: true,
                        messages: [INSTAGRAM_VALIDATIONS.WHATSAPP_CTA_NODE_NOT_SUPPORTED],
                    };
                    break;
                case 'telegram':
                    validationResult = {
                        flag: true,
                        messages: [TELEGRAM_VALIDATIONS.WHATSAPP_CTA_NODE_NOT_SUPPORTED],
                    };
                    break;
                case 'webchat':
                    validationResult = {
                        flag: true,
                        messages: [WEBCHAT_VALIDATIONS.WHATSAPP_CTA_NODE_NOT_SUPPORTED],
                    };
                    break;
                case "rcs": {
                    validationResult = {
                        flag: true,
                        messages: [RCS_VALIDATIONS.WHATSAPP_CTA_NODE_NOT_SUPPORTED]
                    };
                    break;
                }
                default:
                    break;
            }
            if (validationResult != null && validationResult.flag === true)
                result = {
                    flag: true,
                    messages: [...result.messages, ...validationResult.messages],
                };
        });
        // ===============================================================================

        if (result.flag === false) {
            distinctChannelsIdArray.forEach(channelId => {
                let validationResult = null;
                switch (channelId) {
                    case 'whatsapp':
                    case 'whatsapp_pinnacle_bsp':
                        validationResult = WhatsAppValidation.validateCTA(data);
                        break;
                    default:
                        break;
                }
                if (validationResult != null && validationResult.flag === true)
                    result = {
                        flag: true,
                        messages: [...result.messages, ...validationResult.messages],
                    };
            });

            if (data.validation !== undefined && data.validation.validate !== undefined && data.validation.validate === true) {
                const inputValidationResult = validationForInputValidationChecked(data.validation);
                if (inputValidationResult !== null && inputValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputValidationResult.messages],
                    };
                }
            }

            if (data.inputTimeout !== undefined && data.inputTimeout.enabled !== undefined && data.inputTimeout.enabled === true) {
                const inputTimeoutValidationResult = validationForInputTimeoutChecked(data.inputTimeout);
                if (inputTimeoutValidationResult !== null && inputTimeoutValidationResult.flag === true) {
                    result = {
                        flag: true,
                        messages: [...result.messages, ...inputTimeoutValidationResult.messages],
                    };
                }
            }
        }
        return result;
    }
};