import axios from "axios";
import { appConfig } from "../config";

const axiosTestSimulator = axios.create({
    baseURL: appConfig.whatsAppTestSimulatorUrl
});

axiosTestSimulator.interceptors.response.use(
    (response) => response,
    (error) =>
        Promise.reject(error || "Something went wrong")
);

export default axiosTestSimulator;