import React, { forwardRef, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { rgba } from "polished";
import {
    Chip,
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    Menu as MuiMenu,
    Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { useSelector } from "react-redux";
import { ModuleNames, Permissions } from "../../utils/Constants/AccessControl";
import { isAccessible } from "../../utils/AccessControl";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref}>
        <NavLink {...props} />
    </div>
));

const Item = styled(ListItemButton)`
  border-left: 2px solid transparent;
  padding-bottom: 5%;
  padding-top: 5%;
  padding-left: 0;
  padding-right: 0;
  margin-inline: 8px;
  margin-block: 8px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  flex-direction: column;
  position: relative;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  &:hover {
    margin-inline: 8px;
    margin-block: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.palette.primary.main};
  }

  &.${(props) => props.activeclassname} {
    margin-inline: 8px;
    margin-block: 8px;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.primary.main};
    background-color: rgba(112, 179, 255, 0.2);

    span {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

const Title = styled(ListItemText)`
  margin: 4px;

  span {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${(props) => props.theme.typography.caption1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(0)};
  }
`;

const Menu = styled(MuiMenu)`
  .MuiMenu-list {
    padding: 0;
    border: 1px solid #dee2e6;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
  }
`;

//disable sidebar options
const ItemDisabled = styled(ListItemButton)`
  padding-top: ${(props) =>
        props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
        props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
        props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
        props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 30px;
    height: 30px;
    opacity: 0.5;
  }

  &.${(props) => props.activeclassname} {
    pointer-events: none !important;
  }
`;
const TitleDisabled = styled(ListItemText)`
  margin: 0;

  span {
    color: #1180ff;
    font-size: ${(props) => props.theme.typography.caption1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;
const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;

  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(0)};
    padding-right: ${(props) => props.theme.spacing(0)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNavListItem = (props) => {
    const { title, href, depth = 0, children, icon, badge, open } = props;
    const loggedInMember = useSelector((store) => store.org.LogedInTeamMember);
    const currentRoute = window.location.pathname;
    const location = useLocation();
    const permissions = useSelector((store) => store.org.permissions);
    let sideBarModulePermission = permissions.find(
        (x) => x.module === ModuleNames.SidebarMenu
    );
    const settingsIconAnchor = useRef(null);
    const { t } = useTranslation("global");
    const theme = useTheme();
    let isLgUp = useMediaQuery(theme.breakpoints.up("xl"));

    useEffect(() => {
        if (open && !isOpen) {
            return setIsOpen(open);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoute]);

    const [isOpen, setIsOpen] = useState(open);
    const { id } = useParams();
    const navigate = useNavigate();
    const { signOut } = useAuth();

    const handleToggle = () => {
        setIsOpen((state) => !state);
    };

    const handleClickMenu = async (e, url, title) => {
        e.preventDefault();
        if (url === "/auth/sign-out") {
            return signOut();
        }
        if (
            title === "Integrations" ||
            title === "Settings" ||
            title === "Reports"
        ) {
            return;
        } else {
            return navigate(url);
        }
    };

    const [isSettingsPopupOpen, setIsSettingsPopupOpen] = useState(false);
    const [isReportPopupOpen, setIsReportPopupOpen] = useState(false);
    const [isIntegrationsPopupOpen, setIsIntegrationsPopupOpen] = useState(false);

    const handlePopoverOpen = (event, title) => {
        if (title === "Integrations") {
            setIsIntegrationsPopupOpen(true);
        }
        if (title === "Settings") {
            setIsSettingsPopupOpen(true);
        }
        if (title === "Reports") {
            setIsReportPopupOpen(true);
        }
    };

    const popoverEnter = (title) => {
        if (title === "Integrations") {
            setIsIntegrationsPopupOpen(true);
        }
        if (title === "Settings") {
            setIsSettingsPopupOpen(true);
        }
        if (title === "Reports") {
            setIsReportPopupOpen(true);
        }
    };

    const popoverLeave = (title) => {
        if (title === "Integrations") {
            setIsIntegrationsPopupOpen(false);
        }
        if (title === "Settings") {
            setIsSettingsPopupOpen(false);
        }
        if (title === "Reports") {
            setIsReportPopupOpen(false);
        }
    };

    const handlePopoverClose = (title) => {
        if (title === "Integrations") {
            setIsIntegrationsPopupOpen(false);
        }
        if (title === "Settings") {
            setIsSettingsPopupOpen(false);
        }
        if (title === "Reports") {
            setIsReportPopupOpen(false);
        }
    };

    if (children) {
        return (
            <React.Fragment>
                <Item depth={depth} onClick={handleToggle}>
                    {icon}
                    <Title depth={depth}>
                        {title}
                        {badge && <Badge label={badge} />}
                    </Title>
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Item>
                <Collapse in={isOpen}>{children}</Collapse>
            </React.Fragment>
        );
    }

    if (!href || href === "") {
        return (
            <React.Fragment>
                <ItemDisabled depth={depth}>
                    {icon}
                    <TitleDisabled depth={depth}>
                        {title}
                        {badge && <Badge label={badge} />}
                    </TitleDisabled>
                </ItemDisabled>
            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <Item
                    depth={depth}
                    component={CustomRouterLink}
                    to={href}
                    ref={settingsIconAnchor}
                    activeclassname="active"
                    onClick={(e) => handleClickMenu(e, href, title)}
                    aria-owns={
                        open
                            ? isReportPopupOpen
                                ? "settings-over-popover"
                                : "mouse-over-popover"
                            : null
                    }
                    aria-haspopup="true"
                    onMouseEnter={(e) => handlePopoverOpen(e, title)}
                    onMouseLeave={(e) => handlePopoverClose(title)}
                >
                    {icon}
                    <Title depth={depth}>
                        {title}
                        {badge && <Badge label={badge} />}
                    </Title>
                </Item>

                {/*Integrations menu options*/}
                <Menu
                    id="integrations-over-popover"
                    open={isIntegrationsPopupOpen}
                    anchorEl={settingsIconAnchor.current}
                    onClose={() => popoverLeave('Integrations')}
                    PaperProps={{
                        style: {
                            pointerEvents: 'auto',
                            boxShadow: 'rgb(0 0 0 / 5%) 5px 4px 4px 2px',
                            borderRadius: '16px',
                            width: isLgUp ? "12rem" : "15rem"
                        }
                    }}
                    anchorOrigin={{ vertical: 0, horizontal: 'right' }}
                    transformOrigin={{ vertical: 0, horizontal: 'left' }}
                    MenuListProps={{
                        onMouseEnter: () => popoverEnter('Integrations'),
                        onMouseLeave: () => popoverLeave('Integrations')
                    }}
                    sx={{ pointerEvents: 'none' }}
                >
                    <List sx={{ padding: 0 }}>
                        {sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.Logs) &&
                            <ListItemButton onClick={() => (navigate('/integrations'))} className='pt-2' dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/integrations' ? '#1180FF' : ''
                                    }}>{t("channelIntegrations")}</Typography>} />
                            </ListItemButton>}
                        {sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.Reports) &&
                            <ListItemButton onClick={() => (navigate('/integrations/utility'))}
                                className="square pb-2" dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/integrations/utility' ? '#1180FF' : ''
                                    }}>{t("utilityIntegrations")}</Typography>} />
                            </ListItemButton>}
                        {sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.Reports) &&
                            <ListItemButton onClick={() => (navigate('/integrations/connectors'))}
                                className="square pb-2" dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/integrations/connectors' ? '#1180FF' : ''
                                    }}>{t("connectorItegrations")}</Typography>} />
                            </ListItemButton>}
                    </List>
                </Menu>

                {/*Settings menu*/}
                <Menu
                    id="mouse-over-popover"
                    open={isSettingsPopupOpen}
                    anchorEl={settingsIconAnchor.current}
                    onClose={() => popoverLeave("Settings")}
                    PaperProps={{
                        style: {
                            pointerEvents: "auto",
                            boxShadow: "rgb(0 0 0 / 5%) 5px 4px 4px 2px",
                            borderRadius: "16px",
                            width: isLgUp ? "12rem" : "15rem",
                        },
                    }}
                    anchorOrigin={{ vertical: 0, horizontal: "right" }}
                    transformOrigin={{ vertical: 0, horizontal: "left" }}
                    MenuListProps={{
                        onMouseEnter: () => popoverEnter("Settings"),
                        onMouseLeave: () => popoverLeave("Settings"),
                    }}
                    sx={{ pointerEvents: "none" }}
                >
                    <List sx={{ padding: 0 }}>
                        <ListItemButton
                            onClick={() => navigate("/settings/account")}
                            className="pt-2"
                            dense
                        >
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color:
                                                location.pathname === "/settings/account"
                                                    ? "#1180FF"
                                                    : "",
                                        }}
                                    >
                                        {t("account")}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                        {sideBarModulePermission &&
                            isAccessible(
                                sideBarModulePermission.permission,
                                Permissions.Sidebar.CompanyDetails
                            ) && (
                                <ListItemButton
                                    onClick={() => navigate("/settings/company-details")}
                                    className="square pb-2"
                                    dense
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color:
                                                        location.pathname === "/settings/company-details"
                                                            ? "#1180FF"
                                                            : "",
                                                }}
                                            >
                                                {t("companyDetails")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            )}
                        {sideBarModulePermission &&
                            isAccessible(
                                sideBarModulePermission.permission,
                                Permissions.Sidebar.Team
                            ) && (
                                <ListItemButton
                                    onClick={() => {
                                        if (loggedInMember.role === "owner") {
                                            navigate("/settings/team");
                                        } else {
                                            navigate(`/settings/team/${loggedInMember._id}`);
                                        }
                                    }}
                                    dense
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color:
                                                        location.pathname === "/settings/team" ||
                                                            location.pathname === `/settings/team/${id}`
                                                            ? "#1180FF"
                                                            : "",
                                                }}
                                            >
                                                {t("team")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            )}
                        {/* {sideBarModulePermission &&
                            isAccessible(
                                sideBarModulePermission.permission,
                                Permissions.Sidebar.BusinessUnits
                            ) && (
                                <ListItemButton
                                    onClick={() => {
                                        if (loggedInMember.role === "owner") {
                                            navigate("/settings/businessUnits");
                                        } else {
                                            navigate(`/settings/businessUnits/${loggedInMember._id}`);
                                        }
                                    }}
                                    dense
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color:
                                                        location.pathname === "/settings/businessUnits" ||
                                                            location.pathname ===
                                                            `/settings/businessUnits/${id}`
                                                            ? "#1180FF"
                                                            : "",
                                                }}
                                            >
                                                {"Business Unit"}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            )} */}
                        {/* {sideBarModulePermission &&
                            isAccessible(
                                sideBarModulePermission.permission,
                                Permissions.Sidebar.Subscription
                            ) && (
                                <ListItemButton
                                    onClick={() => navigate("/settings/subscription")}
                                    dense
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color:
                                                        location.pathname === "/settings/subscription"
                                                            ? "#1180FF"
                                                            : "",
                                                }}
                                            >
                                                {t("subscription")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            )} */}
                        {/* {sideBarModulePermission &&
                            isAccessible(
                                sideBarModulePermission.permission,
                                Permissions.Sidebar.Invoices
                            ) && (
                                <ListItemButton
                                    onClick={() => navigate("/settings/invoices")}
                                    dense
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color:
                                                        location.pathname === "/settings/invoices"
                                                            ? "#1180FF"
                                                            : "",
                                                }}
                                            >
                                                {t("invoices")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            )} */}
                    </List>
                </Menu>

                {/*Report Logs menu options*/}
                <Menu
                    id="settings-over-popover"
                    open={isReportPopupOpen}
                    anchorEl={settingsIconAnchor.current}
                    onClose={() => popoverLeave('Reports')}
                    PaperProps={{
                        style: {
                            pointerEvents: 'auto',
                            boxShadow: 'rgb(0 0 0 / 5%) 5px 4px 4px 2px',
                            borderRadius: '16px',
                            width: isLgUp ? "12rem" : "15rem"
                        }
                    }}
                    anchorOrigin={{ vertical: 0, horizontal: 'right' }}
                    transformOrigin={{ vertical: 0, horizontal: 'left' }}
                    MenuListProps={{
                        onMouseEnter: () => popoverEnter('Reports'),
                        onMouseLeave: () => popoverLeave('Reports')
                    }}
                    sx={{ pointerEvents: 'none' }}
                >
                    <List sx={{ padding: 0 }}>
                        {sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.Logs) &&
                            <ListItemButton onClick={() => (navigate('/activity/logs'))} className='pt-2' dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/activity/logs' ? '#1180FF' : ''
                                    }}>{t("logs")}</Typography>} />
                            </ListItemButton>}
                        {sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.Reports) &&
                            <ListItemButton onClick={() => (navigate('/activity/reports'))}
                                className="square pb-2" dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/activity/reports' ? '#1180FF' : ''
                                    }}>{t("reports")}</Typography>} />
                            </ListItemButton>}
                        {
                            sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.BroadcastReports) &&
                            <ListItemButton onClick={() => (navigate('/activity/broadcastreports'))}
                                className="square pb-2" dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/activity/broadcastreports' ? '#1180FF' : ''
                                    }}>{t("broadcastreports")}</Typography>} />
                            </ListItemButton>
                        }
                        {sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.UrlShortnerReports) &&
                            <ListItemButton onClick={() => (navigate('/activity/urlshortnerreport'))}
                                className="square pb-2" dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/activity/urlshortnerreport' ? '#1180FF' : ''
                                    }}>{t("buttonClickReports")}</Typography>} />
                            </ListItemButton>}
                        {/* {sideBarModulePermission && isAccessible(sideBarModulePermission.permission, Permissions.Sidebar.ProcessReports) &&
                            <ListItemButton onClick={() => (navigate('/activity/processreports'))}
                                className="square pb-2" dense>
                                <ListItemText
                                    disableTypography
                                    primary={<Typography variant="body1" sx={{
                                        color: location.pathname === '/activity/processreports' ? '#1180FF' : ''
                                    }}>{t("processReport")}</Typography>} />
                            </ListItemButton>
                        } */}
                        {sideBarModulePermission &&
                            isAccessible(
                                sideBarModulePermission.permission,
                                Permissions.Sidebar.BotAttributeReports
                            ) && (
                                <ListItemButton
                                    onClick={() => navigate("/activity/bot-attributes")}
                                    className="square pb-2"
                                    dense
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color:
                                                        location.pathname === "/activity/bot-attributes"
                                                            ? "#1180FF"
                                                            : "",
                                                }}
                                            >
                                                {t("botAttributeReports")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            )}

                        {sideBarModulePermission &&
                            isAccessible(
                                sideBarModulePermission.permission,
                                Permissions.Sidebar.WhatsAppPaymentReports
                            ) && (
                                <ListItemButton
                                    onClick={() => navigate("/activity/whatsApp-payment")}
                                    className="square pb-2"
                                    dense
                                >
                                    <ListItemText
                                        disableTypography
                                        primary={
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color:
                                                        location.pathname === "/activity/whatsApp-payment"
                                                            ? "#1180FF"
                                                            : "",
                                                }}
                                            >
                                                {t("whatsAppPayment")}
                                            </Typography>
                                        }
                                    />
                                </ListItemButton>
                            )}
                    </List>
                </Menu>

            </React.Fragment>
        );
    }

};

export default SidebarNavListItem;
