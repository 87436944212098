import { ModuleNames, Permissions } from "./Constants/AccessControl";
import axios from "./axios";
import { setSession } from "./jwt";


export function isAccessible(permission, module) {
    if (permission <= 0) return false;
    return (permission & module) === module;
}

export async function getPermission(url) {
    try {
        let module = getModuleByUrl(url);
        const accessToken = window.localStorage.getItem("accessToken");
        await setSession(accessToken);
        let result = await axios.post('permissions', { module: module });
        return result.data.permissions;

    } catch (e) {
        console.log("Error:", e);
    }
}

export async function authorizePage(permission, url) {
    let module_permission = getPermissionByUrl(url);
    return isAccessible(permission, module_permission);
}

function getModuleByUrl(url) {
    switch (url) {
        case "dashboard":
            return [ModuleNames.SidebarMenu, ModuleNames.Dashboard]
        case "integrations":
            return [ModuleNames.SidebarMenu, ModuleNames.Integrations]
        case "bots":
        case "workflow":
        case "master-workflow":
            return [ModuleNames.SidebarMenu, ModuleNames.Bots]
        case "process-container":
            return [ModuleNames.SidebarMenu, ModuleNames.Process]
        case "whatsapp-flows":
            return [ModuleNames.SidebarMenu, ModuleNames.WhatsAppFlows]
        case "broadcast":
            return [ModuleNames.SidebarMenu, ModuleNames.Broadcast]
        case "campaigns":
            return [ModuleNames.SidebarMenu, ModuleNames.Campaigns]
        case "users":
            return [ModuleNames.SidebarMenu, ModuleNames.Users]
        case "activity":
            return [ModuleNames.SidebarMenu]
        case "settings":
            return [ModuleNames.SidebarMenu, ModuleNames.Team]  // Getting teams permissions for teams module
        case "cognitive":
            return [ModuleNames.SidebarMenu]
        case "privacy":
            return [ModuleNames.SidebarMenu]
        case "terms-service":
            return [ModuleNames.SidebarMenu]
        default:
            return [];
    }
}

function getPermissionByUrl(url) {
    switch (url) {
        case "dashboard":
            return Permissions.Sidebar.Dashboard
        case "integrations":
            return Permissions.Sidebar.Integrations
        case "bots":
        case "workflow":
        case "master-workflow":
            return Permissions.Sidebar.Bots
        case "process-container":
            return Permissions.Sidebar.Process
        case "whatsapp-flows":
            return Permissions.Sidebar.WhatsAppFlows
        case "broadcast":
            return Permissions.Sidebar.Broadcast
        case "campaigns":
            return Permissions.Sidebar.Campaigns
        case "users":
            return Permissions.Sidebar.Users
        case "settings":
            return Permissions.Sidebar.Settings
        case "account":
            return Permissions.Sidebar.Account
        case "subscription":
            return Permissions.Sidebar.Subscription
        case "company-details":
            return Permissions.Sidebar.CompanyDetails
        case "team":
            return Permissions.Sidebar.Team
        case "businessUnits":
            return Permissions.Sidebar.BusinessUnits
        case "invoices":
            return Permissions.Sidebar.Invoices
        case "logs":
            return Permissions.Sidebar.Logs
        case "reports":
            return Permissions.Sidebar.Reports
        case "broadcastreports":
            return Permissions.Sidebar.BroadcastReports
        case "urlshortnerreport":
            return Permissions.Sidebar.UrlShortnerReports
        case "processreports":
            return Permissions.Sidebar.ProcessReports
        case "cognitive":
            return Permissions.Sidebar.Cognitive
        case "bot-attributes":
            return Permissions.Sidebar.BotAttributeReports
        case "whatsApp-payment":
            return Permissions.Sidebar.WhatsAppPaymentReports
        default:
            break;
    }
}
