import { createSlice } from "@reduxjs/toolkit";


const ActivitySlice = createSlice({
    name: "ActivitySlice",
    initialState: {
        data: [],
        totalCount: 0,
        totalRecords: 0,
        totalLoadRecords: 0
    },
    reducers: {
        SetActivityLogsList: (state, action) => {
            action.payload.activityLogs.forEach(x => {
                let isExists = state.data.find(resultObj => resultObj.date === x.date);
                if(isExists){
                    isExists.cards.push(x);
                }else {
                    state.data.push({date: x.date, cards: [x]});
                }
            });

            state.totalCount = action.payload.totalCount;
            state.totalRecords = action.payload.totalRecords;
            state.totalLoadRecords = state.totalLoadRecords + action.payload.activityLogs.length;
        },
        ResetActivityLogs: (state, action) => {
            state.data = [];
            state.totalCount = 0;
            state.totalRecords = 0;
            state.totalLoadRecords = 0;
        },
    },
});

export const {SetActivityLogsList, ResetActivityLogs}  = ActivitySlice.actions;

export default ActivitySlice.reducer;
