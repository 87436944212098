import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";


const ConversationLogsSlice = createSlice({
    name: "ActivitySlice",
    initialState: {
        chatHistory: [],
        googleMapsKey: "",
        totalCount: 0,
    },
    reducers: {
        SetChatHistory: (state, action) => {
            action.payload.conversationLogs.forEach(item => {
                if(state.chatHistory.length > 0 && state.chatHistory[0].cards.find(x=> x.recepientId.toString() !== item.recepientId.toString())){
                    state.chatHistory = [];
                    state.totalCount = 0;
                    return;
                } else {
                    let isExists;
                    if (!item.sentByBot) {
                        isExists = state.chatHistory.find(resultObj => resultObj.date === moment(item.messageMeasures?.delivered).format("DD MMM YYYY"));
                    } else {
                        isExists = state.chatHistory.find(resultObj => resultObj.date === moment(item.messageTimestampSentByBot).format("DD MMM YYYY"));
                    }
                    if (isExists) {
                        isExists.cards.unshift(item);
                    } else {
                        let newDate = !item.sentByBot ? moment(item.messageMeasures?.delivered).format("DD MMM YYYY") : moment(item?.messageTimestampSentByBot).format("DD MMM YYYY");
                        state.chatHistory.unshift({ date: newDate, cards: [item] });
                    }
                   state.totalCount = action.payload.totalCount;
                }

            });
        },
        SetGoogleMapsKey: (state, action) => {
            state.googleMapsKey = action.payload;
        },
        ResetGoogleMapsKey: (state) => {
            state.googleMapsKey = "";
        },
        ResetChatHistory: (state) => {
            state.chatHistory = [];
            state.totalCount = 0;
        }
    },
});

export const { SetChatHistory, ResetChatHistory, SetGoogleMapsKey, ResetGoogleMapsKey } = ConversationLogsSlice.actions;

export default ConversationLogsSlice.reducer;
