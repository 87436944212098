import styled from "@emotion/styled";
import {
    Grid,
    Button as MuiButton,
    Typography
} from "@mui/material";
import { spacing } from "@mui/system";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Expired from "../../../src/assets/SVG/expired2.svg";


const Button = styled(MuiButton)(spacing);


function Page498() {
    const navigate = useNavigate();

    const handleRedirect = () => {
        //const accessToken = window.localStorage.getItem("accessToken");
        //if (LogedInTeamMember.role === 'editor' && accessToken) {
        //    navigate("/bots");
        //} else if (accessToken && LogedInTeamMember.role !== 'editor') {
        navigate("/login");
        //}
    };

    return (
        <Grid
            container
            sx={{
                minHeight: "70vh",
                alignItems: "center",
                justifyContent: "center",
                gap: '5rem',
                // backgroundColor: "#f4f0f5",
                // position: "relative",
                boxShadow: 9,
                width: "90rem",
                marginLeft: "20px !important"
            }}
        >
            <Helmet title="Subscription expired" />

            <Grid>
                {/* {Object.keys(LogedInTeamMember).length === 0 ? (
                    <Fragment>
                        <CircularProgress />
                    </Fragment>
                ) : ( */}
                <Grid sx={{ textAlign: "left" }}>
                    <Typography variant="h1" color={'#525252'}>
                        Oops! <br />Your subscription has expired.
                    </Typography>
                    <Typography variant="subtitle1" sx={{ mt: 5 }}>
                        To ensure uninterrupted access to 1SPOC, please renew your subscription. <br /> Contact us at  <a href="mailto:support@1spoc.ai" style={{ "text-decoration": "none", "color": "black" }}>support@1spoc.ai</a>.
                    </Typography>
                    <Button
                        onClick={handleRedirect}
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{ mt: 8 }}
                    >
                        Return to Application
                    </Button>
                </Grid>
                {/* )} */}
            </Grid>
            <img src={Expired} style={{ width: '38rem' }} />
        </Grid>
    );
}

export default Page498;
