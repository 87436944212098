import React, {useEffect} from "react";
import { CircularProgress } from "@mui/material";
import useAuth from "../../hooks/useAuth";


function SignUp() {
  const { signUp} = useAuth();
  useEffect(()=>{
    (async function(){
        await signUp();
    })();
    // eslint-disable-next-line
  },[])
  return (
    <React.Fragment>
      <CircularProgress m={2} />
    </React.Fragment>
  );
}

export default SignUp;
