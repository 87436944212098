export const findVariableInStringWithBracess = (data = "") => {
    // check for null and undefined or other than string 
    if (!data && typeof data !== 'string') {
        return { variableFoundWhileTraversingData: [], inCompleteVariableFoundWhileTraversingData: [] };
    }

    const variableFoundWhileTraversingData = [];
    const inCompleteVariableFoundWhileTraversingData = [];
    let variableName = "";
    let captureString = false;

    for (let index = 0; index < data.length; index++) {
        const currentCharacter = data[index];
        const nextCharacterAfterCurrentCharacter = data[index + 1];
        if (currentCharacter === '{' && nextCharacterAfterCurrentCharacter === '{') {
            captureString = true;
            // Here check if varible name is closed with curly brases or not
            if (variableName.length > 2) {
                inCompleteVariableFoundWhileTraversingData.push(variableName);
                variableName = "";
            }
        }

        if (captureString) {
            if (currentCharacter === ' ') {
                inCompleteVariableFoundWhileTraversingData.push(variableName);
                variableName = "";
                captureString = false;
            } else {
                variableName += currentCharacter;
            }

            if (currentCharacter === '}' && nextCharacterAfterCurrentCharacter === '}') {
                variableName += currentCharacter; // add the last character
                variableFoundWhileTraversingData.push(variableName);
                variableName = "";
                captureString = false;
            }
        }

    }

    return {
        variableFoundWhileTraversingData,
        inCompleteVariableFoundWhileTraversingData
    };
};