import React from "react";
import styled from "@emotion/styled";
import { Divider, List, ListItemButton, ListItemText } from "@mui/material";
import SidebarNavSection from "./SidebarNavSection";
import "../../vendor/perfect-scrollbar.css";
import NavbarUserDropdown from "../navbar/NavbarUserDropdown";
import { useSelector } from "react-redux";
import NotificationsTwoToneIcon from '@mui/icons-material/NotificationsTwoTone';
import {useTranslation} from "react-i18next";

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)};
  padding-bottom: ${(props) => props.theme.spacing(0)};
`;

const Item = styled(ListItemButton)`
  border-left: 3px solid transparent;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-inline: 16px;
  margin-block: 8px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  flex-direction: column;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  &:hover {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:active {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.primary.main};
    background-color: rgba(112, 179, 255, 0.4);

    span {
      color: ${(props) => props.theme.palette.primary.main};;
    }
  }
`;

const NewItem = styled.div`
  border-left: 3px solid transparent;
  padding-bottom: 5%;
  padding-top: 5%;
  margin-inline: 16px;
  margin-block: 8px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 25px;
    width: 25px;
    height: 25px;
    opacity: 1;
  }

  &:hover {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.palette.primary.main};
  }

  &:active {
    margin-inline: 16px;
    margin-block: 8px;
    border-radius: 8px;
    color: ${(props) => props.theme.palette.primary.main};
    background-color: rgba(112, 179, 255, 0.4);

    span {
      color: ${(props) => props.theme.palette.primary.main};;
    }
  }
`;

const Title = styled(ListItemText)`

  span {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: ${(props) => props.theme.typography.caption1.fontSize};
    padding: 0 ${(props) => props.theme.spacing(0)};
  }
`;


const SidebarNav = ({ items, openNotification, isBotsScreen, permission }) => {
    const {t} = useTranslation('global')
  const ref = React.useRef(null);
  const LogedInTeamMember = useSelector(store => store.org.LogedInTeamMember);

  return (
    <List disablePadding className="d-flex flex-column justify-content-between"
      sx={{ height: "200%", width: "100%" }}>
      <Items>
        {LogedInTeamMember.role && items &&
          items.map((item, index) => (
            <SidebarNavSection
              component="div"
              key={index}
              pages={item.pages}
              title={item.title}
              isBotsScreen={isBotsScreen}
              permission={permission}
            />
          ))}
      </Items>

      {LogedInTeamMember.role && <Items>
        <Divider />
        {/* <DarkModeSwitch /> */}
        {/* <Item color="inherit" ref={ref} onClick={openNotification}>
          <NotificationsTwoToneIcon />
          <Title>{t("notification",{ns:"global"})}</Title>
        </Item> */}
        <NewItem>
          <NavbarUserDropdown />
        </NewItem>
      </Items>}
    </List>
  );
};

export default SidebarNav;
