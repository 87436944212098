import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	integrations: {
		smsIntegrations: [],
		emailIntegrations: [],
		whatsappIntegrations: [],
	}
};

export const BroadcastIntegrationslice = createSlice({
	name: "BroadcastIntegration",
	initialState,
	reducers: {
		SetBroadcastIntegrations: (state, action) => {
			state.integrations = action.payload;

		},

		ResetBroadcastIntegrations: (state, action) => {
			state.integrations = {
				smsIntegrations: [],
				emailIntegrations: [],
				whatsappIntegrations: []
			}
		}
	}
});

export const { SetBroadcastIntegrations, ResetBroadcastIntegrations } = BroadcastIntegrationslice.actions;
export default BroadcastIntegrationslice.reducer;
