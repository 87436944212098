import React, { useState } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import {
  Avatar as MuiAvatar,
  Drawer as MuiDrawer, Grid,
  ListItemButton, Typography,
} from "@mui/material";
import SidebarNav from "./SidebarNav";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import NotificationsReport from "../../pages/pages/Notifications";

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 80px;
  padding-left: ${(props) => props.theme.spacing(6)};
  padding-right: ${(props) => props.theme.spacing(6)};
  justify-content: center;
  cursor: pointer;
  flex-grow: 0;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 80px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.background};
  }
`;

const Avatar = styled(MuiAvatar)`
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
`;

const SmallAvatar = styled(Avatar)`
  height: auto;
`;

const BrandText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 1.4rem;
  font-weight: 700;
`;

const Sidebar = ({ items, isBotsScreen, ...rest }) => {
  const OrgData = useSelector((store) => store.org.data);
  const theme = useTheme();
  const [isOpen, setIsOen] = useState(false);

  const openNotification = async () => {
    await setIsOen(!isOpen);
  };

  return (
    <>
      <Drawer variant="permanent" {...rest} PaperProps={{
        sx: {
          width: "7rem",
          "&::-webkit-scrollbar": {
            width: 3,
          },
          "&::-webkit-scrollbar-thumb": {
            width: 3,
            backgroundColor: 'transparent'
          },
          "&:hover" : {
            "&::-webkit-scrollbar-thumb": {
              width: 3,
              backgroundColor: '#7A7A7A5F'
            },
          }
        },
      }}>
        {/*IF Org Logo Not Uploaded\\*/}
        {OrgData.length !== 0 && OrgData?.logo && !isBotsScreen ? (
          <Brand component={NavLink} to="/dashboard/" className="d-flex justify-content-center">
            <BigAvatar alt="Organization Logo" src={OrgData?.logo} className="mt-5 mb-4 " /></Brand>)

          //  If org logo not uploaded org name As Brand logo
          : (OrgData?.name && !isBotsScreen && (
            <Brand component={NavLink} to="/dashboard/" className="d-flex justify-content-start">
              <Grid item className="mt-5 mb-4 "><BrandText variant="body2">{OrgData?.name}</BrandText>
              </Grid>
            </Brand>))
        }

        {/*When Org Logo Not Uploaded\\*/}
        {OrgData.length === 0 && !isBotsScreen &&
          <Brand component={NavLink} to="/dashboard/" className="d-flex justify-content-start">
            <Grid item className="mt-5 mb-4 "> <BrandText variant="body2">1SPOC</BrandText> </Grid>
          </Brand>}
        {isBotsScreen &&
          (<Brand component={NavLink} to="/dashboard/">
            <SmallAvatar variant="square" alt="Organization Logo"
              src={theme.name === 'DARK' ? "/static/img/logo-light.svg" : "/static/img/logo-dark1.svg"}
              className="mt-3 mb-2 mr-1" />
          </Brand>)
        }
        <SidebarNav items={items} isBotsScreen={isBotsScreen} openNotification={openNotification} permission={rest.permission} />
      </Drawer>
      <NotificationsReport openNotification={openNotification} isOpen={isOpen} />
    </>
  );
};

export default Sidebar;
