import React from "react";
import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

import PresentationLayout from "./layouts/Presentation";

// Landing
import Landing from "./pages/presentation/Landing";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import FlowBuilderLayout from "./layouts/FlowBuilderLayout";
import Page401 from "./pages/auth/Page401";
import Page404 from "./pages/auth/Page404";
import Page498 from "./pages/auth/Page498";
import Page500 from "./pages/auth/Page500";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import SignOut from "./pages/auth/SignOut";
import SignUp from "./pages/auth/SignUp";

//SignIn & SignUp
const StepperSignUp = async(() => import("./pages/signup/index"));
const LoginPage = async(() => import("./pages/login/index"));
const Success = async(() => import("./pages/login/component/Success"));
const SetPassword = async(() => import("./pages/login/SetPassword/index"));
const PrivacyPage = async(() => import("./pages/pages/privacy/index"));
const TermsOfService = async(() =>
    import("./pages/pages/Terms of Service/index")
);

// Invoices/invoices components
const OrdersList = async(() => import("./pages/pages/Invoices/InvoicesList"));
const OrderDetails = async(() =>
    import("./pages/pages/Invoices/InvoiceDetails")
);
const Pricing = async(() => import("./pages/pages/Pricing"));

//Business units
const BusinessUnit = async(() => import("./pages/pages/BusinessUnit"));
const AddBusinessUnit = async(() =>
    import("./pages/pages/BusinessUnit/AddBusinessUnit")
);

// Dashboard Saas components
const Dashboard = async(() => import("./pages/dashboard"));

// Integrations
const ChannelIntegrations = async(() =>
    import("./pages/pages/Integrations/ChannelIntegrations")
);
const UtilityIntegrations = async(() =>
    import("./pages/pages/Integrations/UtilityIntegrations")
);
const ConnectorIntegrations = async(() =>
    import("./pages/pages/Integrations/ConnectorIntegration")
);

// BroadCastOld
//Message Templates
//const MessageTemplates = async(() => import("./pages/pages/BroadcastOLd/Message Template"));

// Campaigns
//const Campaigns = async(() => import("./pages/pages/BroadcastOld/Campaigns"));


// Dashboard Bots components
const Bots = async(() => import("./pages/pages/Bots/index"));
const BotTemplates = async(() => import("./pages/pages/Bots/BotTemplates"));
// const BotSetting = async(() => import("./pages/pages/Bots/EditBot"));
const BotSetting = async(() =>
    import("./pages/pages/Bots/BotsComponents/EditBot")
);
const WorkflowRender = async(() => import("./pages/pages/Bots/WorkflowRender"));
const WorkflowRenderForMaster = async(() =>
    import("./pages/pages/Bots/WorkflowRenderForMaster")
);
const WorkflowTemplates = async(() =>
    import("./pages/pages/Bots/workflowTemplateComponent")
);
const BotsArchives = async(() => import("./pages/pages/Bots/BotsArchives"));

// Dashboard Processes Components
const EditProcess = async(() => import("./pages/pages/Processes/ProcessComponents/EditProcess"));
const ProcessFlowRender = async(() => import("./pages/pages/Processes/ProcessFlowRenderer"));
const ProcessWorkflow = async(() => import("./pages/pages/Processes/ProcessWorkflow"));
const AppContainer = async(() => import("./pages/pages/Processes/AppContainer"))



//Users routes
const Users = async(() => import("./pages/pages/Users/UsersList"));
const EditUsers = async(() => import("./pages/pages/Users/EditUsers"));
const CSVImportUsers = async(() => import("./pages/pages/Users/CSVImport"));
const ImportHistory = async(() => import("./pages/pages/Users/ImportHistory"));
const CSVImportSummary = async(() =>
    import("./pages/pages/Users/ImportSummary")
);

//Setting routes
const Account = async(() => import("./pages/pages/Account/index"));
const Subscription = async(() => import("./pages/pages/Subscription/index"));
const CompanyDetails = async(() =>
    import("./pages/pages/Company Details/index")
);
const TeamsComponent = async(() => import("./pages/pages/Team/index"));
const AdministratorsComponent = async(() =>
    import("./pages/pages/Team/Components/Administrators")
);
const DataManagement = async(() =>
    import("./pages/pages/Data Management/index")
);

//Channel Integrations
const EmailList = async(() =>
    import("./pages/pages/Integrations/Email/EmailList")
);
const EmailForm = async(() =>
    import("./pages/pages/Integrations/Email/EmailForm")
);
const TubuluList = async(() =>
    import("./pages/pages/Integrations/Tubulu/TubuluList")
);
const TubuluForm = async(() =>
    import("./pages/pages/Integrations/Tubulu/TubuluForm")
);
const WhatsappList = async(() =>
    import("./pages/pages/Integrations/Whatsapp/WhatsappList")
);
const WhatsappBSPForm = async(() =>
    import("./pages/pages/Integrations/Whatsapp/WhatsappBSPForm")
);
//const WhatsappCloudForm = async(() => import("./pages/pages/Integrations/Whatsapp/WhatsappCloudForm"));
const WhatsappBSPEditComponent = async(() =>
    import("./pages/pages/Integrations/Whatsapp/WhatsappBSPEditComponent")
);
// const EmptyCard = async(() => import("./pages/pages/Integrations/ConfigComponents/EmptyCard"));
const TelegramList = async(() =>
    import("./pages/pages/Integrations/Telegram/TelegramList")
);
const TelegramForm = async(() =>
    import("./pages/pages/Integrations/Telegram/TelegramForm")
);
const FacebookMessengerList = async(() =>
    import("./pages/pages/Integrations/FacebookMessenger/FacebookMessengerList")
);
const FacebookMessengerForm = async(() =>
    import("./pages/pages/Integrations/FacebookMessenger/FacebookMessengerForm")
);
const SmsList = async(() => import("./pages/pages/Integrations/SMS/SmsList"));
const SmsForm = async(() => import("./pages/pages/Integrations/SMS/SmsForm"));

const ZohoList = async(() => import("./pages/pages/Integrations/Zoho/zohoList"));
const ZohoForm = async(() => import("./pages/pages/Integrations/Zoho/zohoForm"));

const VoiceList = async(() =>
    import("./pages/pages/Integrations/Voice/VoiceList")
);
const VoiceForm = async(() =>
    import("./pages/pages/Integrations/Voice/VoiceForm")
);
const InstagramList = async(() =>
    import("./pages/pages/Integrations/Instagram/InstagramList")
);
const InstagramForm = async(() =>
    import("./pages/pages/Integrations/Instagram/InstagramForm")
);
const LiveAgentList = async(() =>
    import("./pages/pages/Integrations/LiveAgent/LiveAgentList")
);
const LiveAgentForm = async(() =>
    import("./pages/pages/Integrations/LiveAgent/LiveAgentForm")
);
const WebChatList = async(() =>
    import("./pages/pages/Integrations/WebChat/WebChatList")
);
const WebChatForm = async(() =>
    import("./pages/pages/Integrations/WebChat/WebChatForm")
);

// RCS
const RCSList = async(() => import("./pages/pages/Integrations/RCS/RCSList"));
const RCSForm = async(() => import("./pages/pages/Integrations/RCS/RCSForm"));

// Document Cognition
const DocumentCognitionList = async(() =>
    import("./pages/pages/Integrations/DocumentCognition/DocumentCognitionList")
);
const DocumentCognitionForm = async(() =>
    import("./pages/pages/Integrations/DocumentCognition/DocumentCognitionForm")
);

// Activity Report/Logs feed
const ActivityLogsFeed = async(() => import("./pages/pages/ActivityFeed/ActivityLogsFeed"));
const ActivityReportsFeed = async(() => import("./pages/pages/ActivityFeed/ActivityReportsFeed"));
const BroadCastReportsFeed = async(() => import("./pages/pages/ActivityFeed/BroadCastReports"));
const IndividualBroadCastReports = async(() => import("./pages/pages/ActivityFeed/IndividualBroadCastReports"));
const UrlShortnerReports = async(() => import("./pages/pages/ActivityFeed/UrlShortnerReports"));
const UrlShortnerReportsDetailedStatistics = async(() => import("./pages/pages/ActivityFeed/UrlShortnerReportsDetailedStatistics"));
const ProcessReports = async(() => import("./pages/pages/ActivityFeed/ProcessReports"));
const BotAttributeReports = async(() => import("./pages/pages/ActivityFeed/BotAttributeReports"));

const WhatsAppPaymentReports = async(() => import("./pages/pages/ActivityFeed/WhatsAppPaymentReports"));



// Whatsapp Flows 
// const WhatsAppFlowsCreatorOld = async(() => import("./pages/pages/WhatsAppFlows/Creator"));
const WhatsAppFlowCreator = async(() =>
    import("./pages/pages/WhatsAppFlows/WhatsAppFlowCreator")
);
const WhatsAppFlows = async(() =>
    import("./pages/pages/WhatsAppFlows/WhatsAppFlows")
);


const ProcessRequestActivityLogs = async(() => import("./pages/pages/ActivityFeed/ProcessRequestActivityLogs"));


const routes = [
    {
        path: "/",
        element: <PresentationLayout />,
        children: [
            {
                path: "",
                element: <Landing />,
            },
            {
                path: "login",
                element: <LoginPage />,
            },
            {
                path: "forgot-password",
                element: <LoginPage />,
            },
            {
                path: "password-link",
                element: <LoginPage />,
            },
            {
                path: "success",
                element: <Success />,
            },
            {
                path: "signup",
                element: <StepperSignUp />,
            },
            {
                path: "invite/set-password/:token",
                element: <SetPassword />,
            },
        ],
    },

    {
        path: "auth",
        element: <AuthLayout />,
        children: [
            {
                path: "sign-in",
                element: <SignIn />,
            },
            {
                path: "sign-up",
                element: <SignUp />,
            },
            {
                path: "sign-out",
                element: (
                    <AuthGuard>
                        {" "}
                        <SignOut />{" "}
                    </AuthGuard>
                ),
            },
            {
                path: "reset-password",
                element: <ResetPassword />,
            },
            {
                path: "404",
                element: <Page404 />,
            },
            {
                path: "500",
                element: <Page500 />,
            },
            {
                path: "498",
                element: <Page498 />,
            }, {
                path: "401",
                element: <Page401 />,
            },
        ],
    },
    {
        path: "/",
        element: (
            <AuthGuard>
                <DashboardLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "dashboard/",
                element: <Dashboard />,
            },
            //--------- Channel Integrations --------------------
            {
                path: "integrations",
                element: <ChannelIntegrations />,
            },
            {
                path: "integrations/utility",
                element: <UtilityIntegrations />,
            },
            {
                path: "integrations/email/:id",
                element: <EmailList />,
            },
            {
                path: "integrations/email/:operation/:parentid/:id",
                element: <EmailForm />,
            },
            {
                path: "integrations/tubulu/:id",
                element: <TubuluList />,
            },
            {
                path: "integrations/tubulu/:operation/:parentid/:id",
                element: <TubuluForm />,
            },
            {
                path: "integrations/whatsapp/:id",
                element: <WhatsappList />,
            },

            //----------------  ZOho CRM Connectors routes -----------------------

            {
                path: "integrations/connectors",
                element: <ConnectorIntegrations />,
            },
            {
                path: "integrations/zohocrm/:id",
                element: <ZohoList />,
            },
            {
                path: "integrations/zohocrm/:operation/:parentid/:id",
                element: <ZohoForm />,
            },
            //app container routes
            {
                path: "process-container",
                element: <AppContainer />,

                // element: <ProcessWorkflow />,
            },
            //Process routes
            {
                path: "process-container/:containerId",
                element: <ProcessWorkflow />,
            },
            {
                path: 'process-container/:containerId/:id',
                element: <EditProcess />,
            },

            // {
            //     path: "integrations/whatsapp/cloud/:operation/:parentid/:id",
            //     element: <WhatsappCloudForm />,
            // },
            {
                path: "integrations/whatsapp/bsp/:operation/:parentid/:id",
                element: <WhatsappBSPForm />,
            },
            {
                path: "integrations/whatsapp/bsp/edit/:parentid/:id",
                element: <WhatsappBSPEditComponent />,
            },
            // RCS
            {
                path: "integrations/rcs/:id",
                element: <RCSList />,
            },
            {
                path: "integrations/rcs/:operation/:parentid/:id",
                element: <RCSForm />,
            },
            //telegram
            {
                path: "integrations/telegram/:id",
                element: <TelegramList />,
            },
            {
                path: "integrations/telegram/:operation/:parentid/:id",
                element: <TelegramForm />,
            },
            //facebook messenger
            {
                path: "integrations/facebookmessenger/:id",
                element: <FacebookMessengerList />,
            },
            {
                path: "integrations/facebookmessenger/:operation/:parentid/:id",
                element: <FacebookMessengerForm />,
            },
            //web chat
            {
                path: "integrations/webchat/:id",
                element: <WebChatList />,
            },
            {
                path: "integrations/webchat/:operation/:parentid/:id",
                element: <WebChatForm />,
            },
            //sms
            {
                path: "integrations/sms/:id",
                element: <SmsList />,
            },
            {
                path: "integrations/sms/:operation/:parentid/:id",
                element: <SmsForm />,
            },
            {
                path: "integrations/voice/:id",
                element: <VoiceList />,
            },
            {
                path: "integrations/voice/:operation/:parentid/:id",
                element: <VoiceForm />,
            },
            //instagram
            {
                path: "integrations/instagram/:id",
                element: <InstagramList />,
            },
            {
                path: "integrations/instagram/:operation/:parentid/:id",
                element: <InstagramForm />,
            },
            //live agent
            {
                path: "integrations/liveagent/:id",
                element: <LiveAgentList />,
            },
            {
                path: "integrations/liveagent/:operation/:parentid/:id",
                element: <LiveAgentForm />,
            },

            //  Bots routes
            {
                path: "bots",
                element: <Bots />,
            },
            {
                path: "master-workflow/:id",
                element: <WorkflowRenderForMaster />,
            },
            {
                path: "workflow/workflow-templates/",
                element: <WorkflowTemplates />,
            },
            {
                path: "bots/bot-templates/",
                element: <BotTemplates />,
            },
            {
                path: "bots/:id",
                element: <BotSetting />,
            },
            {
                path: "bots/edit/:id",
                element: <BotSetting />,
            },
            {
                path: "bots/bot-channel/:id",
                element: <BotSetting />,
            },
            {
                path: "bots/chat/:id",
                element: <BotSetting />,
            },
            {
                path: "bots/archives",
                element: <BotsArchives />,
            },


            //WhatsApp Flows routes
            {
                path: "/whatsapp-flows",
                element: <WhatsAppFlows />,
            },
            {
                path: "/whatsapp-flows/:id",
                element: <WhatsAppFlowCreator />,
            },

            //  Invoices routes
            {
                path: "orders",
                element: <OrdersList />,
            },
            {
                path: "pricing",
                element: <Pricing />,
            },
            {
                path: '/users',
                element: <Users />
            },
            {
                path: '/users/import-history',
                element: <ImportHistory />
            },
            {
                path: '/users/import-user',
                element: <CSVImportUsers />
            },
            {
                path: '/users/summary/:id',
                element: <CSVImportSummary />
            },
            {
                path: '/users/edit/:id',
                element: <EditUsers />
            },
            {
                path: "profile",
                element: <Account />,
            },
            {
                path: "settings/account",
                element: <Account />
            },
            {
                path: "settings/subscription",
                element: <Subscription />
            },
            {
                path: "settings/company-details",
                element: <CompanyDetails />
            },
            {
                path: "settings/team",
                element: <TeamsComponent />
            },
            {
                path: "settings/businessUnits",
                element: <BusinessUnit />
            },
            // {
            //     path: "settings/businessUnits/new",
            //     element: <AddBusinessUnit />
            // },
            {
                path: "settings/businessUnits/:id",
                element: <AddBusinessUnit />
            },
            {
                path: "settings/team/:id",
                element: <AdministratorsComponent />
            },
            {
                path: "settings/invoices",
                element: <OrdersList />
            },
            {
                path: "settings/invoices/:id",
                element: <OrderDetails />
            },
            {
                path: "settings/data-management",
                element: <DataManagement />
            },
            // Activity Logs Route
            {
                path: "activity/logs",
                element: <ActivityLogsFeed />
            },
            {
                path: "activity/reports",
                element: <ActivityReportsFeed />
            },
            {
                path: "activity/broadcastreports",
                element: <BroadCastReportsFeed />
            },
            {
                path: "activity/broadcastreports/:id",
                element: <IndividualBroadCastReports />
            },
            {
                path: "activity/urlshortnerreport",
                element: <UrlShortnerReports />
            },
            {
                path: "activity/urlshortnerreport/:id",
                element: <UrlShortnerReportsDetailedStatistics />
            },
            {
                path: "activity/processreports",
                element: <ProcessReports />
            },
            {
                path: "activity/bot-attributes",
                element: <BotAttributeReports />,
            },
            {
                path: "activity/whatsApp-payment",
                element: <WhatsAppPaymentReports />,
            },
            // Cognitive Route
            {
                path: "cognitive/documents",
                element: <DocumentCognitionList />
            },
            {
                path: "cognitive/documents/add-document",
                element: <DocumentCognitionForm />
            },
            // Privacy and Terms Of Service
            {
                path: "privacy",
                element: <PrivacyPage />,
            },
            {
                path: "terms-service",
                element: <TermsOfService />,
            },
            {
                path: "activity/processreports/:id",
                element: <ProcessRequestActivityLogs />
            },
        ],
    },

    {
        path: "/",
        element: (
            <AuthGuard>
                <FlowBuilderLayout />
            </AuthGuard>
        ),
        children: [
            {
                path: "workflow/:id/:workflowId",
                element: <WorkflowRender />,
            },
            {
                path: "workflow/process/:id/:workflowId",
                element: <ProcessFlowRender />,
            },
        ],
    },
    //BroadCast
    // {path: 'broadcast',
    //   element: (
    //       <AuthGuard>
    //         <BroadcastSubMenuLayout />
    //       </AuthGuard>
    //   ),
    //   children: [
    //     {
    //       path: "campaigns",
    //       element: <Campaigns/>,
    //     },
    //     {
    //       path: "message-templates",
    //       element: <MessageTemplates />,
    //     },
    //   ],
    // },

    {
        path: "401",
        element: <AuthLayout />,
        children: [
            {
                path: "",
                element: <Page401 />,
            },
        ],
    },
    {
        path: "500",
        element: <AuthLayout />,
        children: [
            {
                path: "",
                element: <Page500 />,
            },
        ],
    },
    {
        path: "498",
        element: <AuthLayout />,
        children: [
            {
                path: "",
                element: <Page498 />,
            },
        ],
    },

    {
        path: "*",
        element: <AuthLayout />,
        children: [
            {
                path: "*",
                element: <Page404 />,
            },
        ],
    },
];
export default routes;
