import React from "react";
import {CircularProgress} from "@mui/material";

function SignIn() {
    return (
        <React.Fragment>
            <CircularProgress m={2}/>
        </React.Fragment>
    );
}

export default SignIn;
