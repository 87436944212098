import { createSlice } from "@reduxjs/toolkit";

const ConnectorsSlice = createSlice({
  name: "connectors",
  initialState: {
    connectors: []
  },
  reducers: {
    setListOfConnectors: (state, action) => {
      state.connectors = action.payload;
    },

    ResetListOfConnectors: (state) => {
      state.connectors = []
    },
  },
});

export const { ResetListOfConnectors, setListOfConnectors } = ConnectorsSlice.actions;

export default ConnectorsSlice.reducer;
