import React from "react";
import styled from "@emotion/styled";
import {
  Container,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";

import { spacing } from "@mui/system";
import {useTranslation} from "react-i18next";
import Loader from "../../../components/Loader";

const Typography = styled(MuiTypography)(spacing);

const Wrapper = styled.div`
  padding-top: 3.5rem;
  position: relative;
  text-align: center;
  overflow: hidden;
`;

const Content = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
  line-height: 150%;
`;

const Title = styled(Typography)`
  color: #000;
  line-height: 1.4;
  font-size: 1.75rem;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};

  ${(props) => props.theme.breakpoints.up("sm")} {
    font-size: 1.5rem;
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    font-size: 2.1rem;
  }

  span {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const Subtitle = styled(Typography)`
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-family: ${(props) => props.theme.typography.fontFamily};
  margin: ${(props) => props.theme.spacing(2)} 0;
`;


function Introduction() {
  const {t,ready}=useTranslation('LandingPage')
  if(!ready) return <Loader />
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={9} md={8} lg={8}>
            <Content>
              <Title variant="h1" gutterBottom>
                {t("braceForALLNewOmnichannel")}
                <span> Pinnacle Teleservices.</span>
              </Title>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item xs={12} lg={10}>
                  <Subtitle color="textSecondary">
                    {t("landingPageBody")}
                  </Subtitle>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}

export default Introduction;
