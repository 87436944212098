import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import Axios from 'axios';
import {appConfig} from "../../config";

export let STATUS = {
    IDLE:'idle',
    LOADING:'Loading',
    ERROR:'error'
}

const orgDataSlice = createSlice({
    name: 'org',
    initialState: {
        data : [],
        LogedInTeamMember: {},
        notifications:[],
        notificationsCount:0,
        totalActiveCount:0,
        permissions:[],
        countries: [],
        status : STATUS.IDLE
    },
    reducers:{
        SetOrgList: (state, action) => {
            state.data = action.payload;
        },
        SetCountries: (state, action) => {
            state.countries = action.payload;
        },
        SetLogedInTeamMember: (state, action) => {
            state.LogedInTeamMember = action.payload;
        },
        addMoreNotification: (state, action) => {
            let data = state.notifications;
            data.push(...action.payload.notifications);
            state.notifications = data;
            state.notificationsCount = action.payload.totalCount;
        },
        markAsReadNotification: (state, action) => {
            let foundIndex =  state.notifications.findIndex((notification)=> notification._id === action.payload);
            if(state.notifications[foundIndex].status ===  'read') return;
            state.notifications[foundIndex].status = 'read';
            state.totalActiveCount =  state.totalActiveCount - 1;
        },
        SetPagePermissions: (state, action) => {
            state.permissions = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTeamMemberByEmail.pending, (state, action) => {
                state.status = STATUS.LOADING;
            })
            .addCase(getTeamMemberByEmail.fulfilled, (state, action) => {
                state.LogedInTeamMember = action.payload;
                state.status = STATUS.IDLE;
            })
            .addCase(getTeamMemberByEmail.rejected, (state) => {
                state.status = STATUS.ERROR;
            })

            //Get notifications for login teamMember
            .addCase(getTeamMemberNotifications.pending, (state, action) => {
            state.status = STATUS.LOADING;
        })
            .addCase(getTeamMemberNotifications.fulfilled, (state, action) => {
                state.notifications = action.payload.notifications;
                state.totalActiveCount = action.payload.totalActiveCount;
                state.notificationsCount = action.payload.totalCount;
                state.status = STATUS.IDLE;
            })
            .addCase(getTeamMemberNotifications.rejected, (state) => {
                state.status = STATUS.ERROR;
            })

    },

})

export const { SetOrgList, SetLogedInTeamMember, addMoreNotification, SetCountries, markAsReadNotification, SetPagePermissions } = orgDataSlice.actions;

export default orgDataSlice.reducer;

// Thunks async  actions
export const getTeamMemberByEmail = createAsyncThunk('teamMembers/getTeamMember/email', async (email) => {
    const res = await axios.get(`teamMembers/getTeamMember/${email}`);
    let data = await res.data.teamMember;
    return data;
});

//Get all notifications
export const getTeamMemberNotifications = createAsyncThunk('notifications', async () => {
    const accessToken =  window.localStorage.getItem("accessToken");
    Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    const res = await Axios.get(`${appConfig.appNotificationServerUrl}notifications`,
    );
    let data = await res.data;
    return data;
});