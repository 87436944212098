import * as React from "react";
import styled from "@emotion/styled";
import { Grid, List, ListItemButton as MuiListItemButton, ListItemText as MuiListItemText, } from "@mui/material";
import packageJson from '../../package.json';
import {useNavigate} from "react-router-dom";


const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(0.25)} ${(props) => props.theme.spacing(4)};
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

const ListItemButton = styled(MuiListItemButton)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(2)};

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;

function Footer() {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <Grid container spacing={0} justifyContent="flex-start">
                <Grid
                    sx={{ display: { xs: "none", md: "block" } }}
                    container
                    item
                    xs={12}
                    md={4}
                >
                    <List>
                        <ListItemButton component="a" onClick={() => navigate ('/privacy')}>
                            <ListItemText primary="Privacy" />
                        </ListItemButton>
                        <ListItemButton component="a" onClick={() => navigate ('/terms-service')}>
                            <ListItemText primary="Terms of Service" />
                        </ListItemButton>
                    </List>
                </Grid>
                <Grid container item md={2} justifyContent="flex-end">
                    <List>
                        <ListItemButton sx={{ background: 'none !important', cursor: 'auto' }}>
                            <ListItemText primary={`V ${packageJson.version}`} />
                        </ListItemButton>
                    </List>
                </Grid>
                <Grid container item xs={12} md={6} justifyContent="flex-end">
                    <List>
                        <ListItemButton sx={{ background: 'none !important', cursor: 'auto' }}>
                            <ListItemText primary={`© ${new Date().getFullYear()} - 1SPOC`} />
                        </ListItemButton>
                    </List>
                </Grid>
            </Grid>
        </Wrapper>
    );
}

export default Footer;
