import WorkflowErrors from "../../utils/workflowErrors";
import { limitation } from "./limitation";
const {
    WHATSAPP_VALIDATIONS,
    WHATSAPP_FLOWS_NODE,
    WHATSAPP_TEMPLATE_NODE,
    WHATSAPP_PAYMENT_NODE,
    WHATSAPP_CATALOG_NODE,
    RCS_VALIDATIONS,
    WHATSAPP_CTA_NODE
} = WorkflowErrors;

// Node level validation
export const WhatsAppValidation = {
    validateTextMessage: (data) => {
        let messages = [];
        let limitWhatAppMessage =
            limitation.channels.whatsapp.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitWhatAppMessage.maxCharacterLength) {
                messages = [WHATSAPP_VALIDATIONS.MESSAGE_TEXT_MAX_CHARACTER_ERROR];
            } else if (data.contents.trim().length === 0) {
                messages = [WHATSAPP_VALIDATIONS.EMPTY_MESSAGE_TEXT_ERROR];
            }
            return messages;
        }
    },
    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText = limitation.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Question node character length should not greater than ${limitMessageText.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Question node text can't be empty."],
                };
            }
        }
        return result;
    },
    validateButton: (data) => {
        let errorMessages = [];
        let limitWhatAppButton = limitation.channels.whatsapp.nodes.buttonNode;
        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.bodyText !== undefined
        ) {
            // VALIDATE BUTTON NODE
            if (data.contents.buttons.length > limitWhatAppButton.maxButtons) {
                errorMessages.push(
                    `WhatsApp does not support more than ${limitWhatAppButton.maxButtons} buttons.`
                );
            }
            // VALIDATE BODY TEXT
            if (data.contents.bodyText.length > limitWhatAppButton.bodyText) {
                errorMessages.push(
                    `Whatsapp message body text should not greater than ${limitWhatAppButton.bodyText} characters.`
                );
            }
            // IS MEDIA RENDER
            if (data.contents.isMediaRender === false) {
                if (data.contents.headerText.trim() === "") {
                    errorMessages.push(`Please Enter WhatsApp header title.`);
                }
                if (data.contents.headerText.length > limitWhatAppButton.headerText) {
                    errorMessages.push(
                        `Whatsapp button header text should not greater than ${limitWhatAppButton.headerText} characters.`
                    );
                }
            }

            // HEADER WITH MEDIA
            // if (data.contents.isMediaRender === true && data.contents.mediaUrl.trim().length === 0) {
            if (data.contents.isMediaRender === true) {
                if (
                    !data.contents.mediaFromType ||
                    data.contents.mediaFromType === ""
                ) {
                    errorMessages.push(
                        `Please select media url or previously uploaded file.`
                    );
                } else {
                    if (
                        data.contents.mediaFromType === "byURL" &&
                        data.contents.mediaUrl.trim().length === 0
                    ) {
                        errorMessages.push(`Please enter valid media URL.`);
                    }
                    if (
                        data.contents.mediaFromType === "byUpload" &&
                        data.contents.mediaUrl.trim().length === 0
                    ) {
                        errorMessages.push(`Please select file from given dropdown.`);
                    }
                }
            }
            if (data.contents.footerText.length > limitWhatAppButton.footerText) {
                errorMessages.push(
                    `Whatsapp button footer text should not greater than ${limitWhatAppButton.footerText} characters.`
                );
            }

            if (
                data.contents.isMediaRender === true &&
                data.contents.mediaType === "document"
            ) {
                if (data.contents.fileDisplayNameForDocument === "") {
                    errorMessages.push(`Filename for document can't be empty.`);
                }
            }
        }
        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },
    validateList: (data) => {
        let result = { flag: false, messages: [] };
        let limitWhatAppList = limitation.channels.whatsapp.nodes.listNode;
        if (
            data.contents !== undefined &&
            data.contents.list !== undefined &&
            data.contents.bodyText !== undefined &&
            data.contents.openButtonText !== undefined
        ) {
            if (data.contents.list.length > limitWhatAppList.maxListItems) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `WhatsApp does not support more than ${limitWhatAppList.maxListItems} items in one list.`,
                    ],
                };
            }
            if (data.contents.bodyText.length > limitWhatAppList.bodyText) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Whatsapp list body text should not greater than ${limitWhatAppList.bodyText} characters.`,
                    ],
                };
            }
            if (
                data.contents.openButtonText.length > limitWhatAppList.openButtonText
            ) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Whatsapp open button text should not greater than ${limitWhatAppList.openButtonText} characters.`,
                    ],
                };
            }
        }
        return result;
    },
    validateOptionNode: (data) => {
        let result = { flag: false, messages: [] };
        let limitWhatAppOptionNode = limitation.channels.whatsapp.nodes.optionNode;
        if (
            data.contents !== undefined &&
            data.contents.options !== undefined &&
            data.contents.header !== undefined
        ) {
            if (data.contents.options.length > limitWhatAppOptionNode.maxListItems) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `WhatsApp does not support more than ${limitWhatAppOptionNode.maxListItems} buttons.`,
                    ],
                };
            } else if (data.contents.options.length < 1) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        "WhatsApp list node should have at least one button.",
                    ],
                };
            }
            if (data.contents.header.trim() === "") {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        "WhatsApp list body text can't be empty.",
                    ],
                };
            } else if (data.contents.header.length > limitWhatAppOptionNode.header) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Whatsapp list body text should not greater than ${limitWhatAppOptionNode.header} characters.`,
                    ],
                };
            }
        }
        return result;
    },
    validateFlows: (data) => {
        let errorMessages = [];
        let flowsLimitations = limitation.channels.whatsapp.nodes.whatsAppFlowsNode;

        if (
            data.contents.flowData === undefined ||
            data.contents.flowData.flowId === undefined ||
            data.contents.flowData.flowId.trim() === ""
        ) {
            errorMessages.push(WHATSAPP_FLOWS_NODE.FLOW_NOT_SELECTED_ERROR);
        }

        if (
            data.contents !== undefined &&
            data.contents.bodyText !== undefined &&
            data.contents.footerText !== undefined &&
            data.contents.headerText !== undefined &&
            data.contents.openButtonText !== undefined
        ) {
            // =========================== VALIDATE BODY TEXT ===========================
            if (data.contents.bodyText.trim().length === 0) {
                errorMessages.push(WHATSAPP_FLOWS_NODE.BODY_TEXT_EMPTY_ERROR);
            }

            if (data.contents.bodyText.trim().length > flowsLimitations.bodyText) {
                errorMessages.push(WHATSAPP_FLOWS_NODE.BODY_TEXT_MAX_LIMIT_ERROR);
            }

            // =========================== VALIDATE OPEN BUTTON TEXT ===========================
            if (data.contents.openButtonText.trim().length === 0) {
                errorMessages.push(WHATSAPP_FLOWS_NODE.OPEN_BUTTON_TEXT_EMPTY_ERROR);
            }

            if (
                data.contents.openButtonText.trim().length >
                flowsLimitations.openButtonText
            ) {
                errorMessages.push(
                    WHATSAPP_FLOWS_NODE.OPEN_BUTTON_TEXT_MAX_LIMIT_ERROR
                );
            }

            // =========================== VALIDATE HEADER TEXT ===========================
            if (
                data.contents.headerText.trim().length > flowsLimitations.headerText
            ) {
                errorMessages.push(WHATSAPP_FLOWS_NODE.HEADER_TEXT_MAX_LIMIT_ERROR);
            }

            // =========================== VALIDATE FOOTER TEXT ===========================
            if (
                data.contents.footerText.trim().length > flowsLimitations.footerText
            ) {
                errorMessages.push(WHATSAPP_FLOWS_NODE.FOOTER_TEXT_MAX_LIMIT_ERROR);
            }
        }
        //=============================== VALIDATE FLOW VARIABLE ============================
        //======================================== STATIC ===================================

        if (
            data.contents.flowData !== undefined &&
            data.contents.flowData.dataChannelComponent !== undefined
        ) {
            if (data.contents.flowData.dataChannelComponent === false) {
                if (data.contents.flowData.firstScreenVariables && Array.isArray(data.contents.flowData.firstScreenVariables) && data.contents.flowData.firstScreenVariables.length > 0) {
                    for (
                        let index = 0;
                        index < data.contents.flowData.firstScreenVariables.length;
                        index++
                    ) {
                        const element = data.contents.flowData.firstScreenVariables[index];

                        if (element.value !== null && element.value !== undefined) {
                            if (element.value === "") {
                                errorMessages.push(
                                    element.variableName + WHATSAPP_FLOWS_NODE.FLOW_VARIABLE_ERROR
                                );
                            }
                        }
                    }
                }
            }
        }

        //=================================== DYNAMIC FLOWS =================================

        if (
            data.contents.flowData !== undefined &&
            // data.contents.firstScreenSubmitAction !== undefined &&
            data.contents.flowData.dataChannelComponent !== undefined
        ) {
            if (
                data.contents.flowData.dataChannelComponent === true &&
                (data.contents.firstScreenSubmitAction === undefined || data.contents.firstScreenSubmitAction === "navigate")
            ) {
                if (data.contents.flowData.firstScreenVariables && Array.isArray(data.contents.flowData.firstScreenVariables) && data.contents.flowData.firstScreenVariables.length > 0) {
                    for (
                        let index = 0;
                        index < data.contents.flowData.firstScreenVariables.length;
                        index++
                    ) {
                        const element = data.contents.flowData.firstScreenVariables[index];
                        if (element.value !== null && element.value !== undefined) {
                            if (element.value === "") {
                                errorMessages.push(
                                    element.variableName + WHATSAPP_FLOWS_NODE.FLOW_VARIABLE_ERROR
                                );
                            }
                        }
                    }
                }
            }
        }

        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },

    validateTemplate: (data) => {
        let errorMessages = [];
        let flowsLimitations =
            limitation.channels.whatsapp.nodes.whatsAppTemplateNode;

        if (
            data.contents.templateData === undefined ||
            data.contents.templateData.templateType === undefined ||
            data.contents.templateData.templateType === ""
        ) {
            errorMessages.push(
                WHATSAPP_TEMPLATE_NODE.TEMPLATE_TYPE_NOT_SELECTED_ERROR
            );
            return {
                flag: errorMessages.length > 0,
                messages: errorMessages,
            };
        }

        if (
            data.contents.templateData === undefined ||
            data.contents.templateData.templateName === undefined ||
            data.contents.templateData.templateName === ""
        ) {
            errorMessages.push(WHATSAPP_TEMPLATE_NODE.TEMPLATE_NOT_SELECTED_ERROR);
            return {
                flag: errorMessages.length > 0,
                messages: errorMessages,
            };
        }

        if (!data.contents.getItemsFromVariable) {
            const str =
                data.contents &&
                data.contents.templateData &&
                data.contents.templateData.templateBodyPlaceHolder;

            const lastIndex = str && str.charAt(str.length - 1);
            if (lastIndex !== null || lastIndex !== undefined) {
                for (let index = 1; index <= lastIndex; index++) {
                    if (
                        data.contents.templateData.carousel === undefined ||
                        data.contents.templateData.carousel.body === undefined ||
                        data.contents.templateData.carousel.body[index] === undefined ||
                        data.contents.templateData.carousel.body[index] === ""
                    ) {
                        errorMessages.push(
                            `Place-Holder ${index} text should not be empty.`
                        );
                    }
                }
            }
            const carouselCards =
                data.contents.templateData &&
                data.contents.templateData.carousel &&
                data.contents.templateData.carousel?.cards;

            if (carouselCards !== null && carouselCards !== undefined) {
                for (let index = 0; index < carouselCards.length; index++) {
                    const cardData = carouselCards[index];
                    for (let key in cardData) {
                        if (cardData[key] === "") {
                            errorMessages.push(
                                ` ${key} should not be empty for Card ${cardData.cardIndex}.`
                            );
                        }
                        if (key === "body") {
                            const cardBody = cardData && cardData?.body;
                            for (let example in cardBody) {
                                if (cardBody[example] === "") {
                                    errorMessages.push(
                                        `Place-Holder ${example} should not be empty for Card ${cardData.cardIndex}.`
                                    );
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if (
                data.contents.templateDataByVariable === undefined ||
                data.contents.templateDataByVariable === null ||
                data.contents.templateDataByVariable === ""
            ) {
                errorMessages.push(WHATSAPP_TEMPLATE_NODE.TEMPLATE_DATA_BY_VARIABLE);
            }
        }

        // if (
        //   data.contents !== undefined &&
        //   data.contents.bodyText !== undefined &&
        //   data.contents.footerText !== undefined &&
        //   data.contents.headerText !== undefined &&
        //   data.contents.openButtonText !== undefined
        // ) {
        //   // =========================== VALIDATE BODY TEXT ===========================
        //   if (data.contents.bodyText.trim().length === 0) {
        //     errorMessages.push(WHATSAPP_TEMPLATE_NODE.BODY_TEXT_EMPTY_ERROR);
        //   }

        //   if (data.contents.bodyText.trim().length > flowsLimitations.bodyText) {
        //     errorMessages.push(WHATSAPP_TEMPLATE_NODE.BODY_TEXT_MAX_LIMIT_ERROR);
        //   }

        //   // =========================== VALIDATE OPEN BUTTON TEXT ===========================
        //   if (data.contents.openButtonText.trim().length === 0) {
        //     errorMessages.push(WHATSAPP_TEMPLATE_NODE.OPEN_BUTTON_TEXT_EMPTY_ERROR);
        //   }

        //   if (
        //     data.contents.openButtonText.trim().length >
        //     flowsLimitations.openButtonText
        //   ) {
        //     errorMessages.push(
        //       WHATSAPP_TEMPLATE_NODE.OPEN_BUTTON_TEXT_MAX_LIMIT_ERROR
        //     );
        //   }

        //   // =========================== VALIDATE HEADER TEXT ===========================
        //   if (
        //     data.contents.headerText.trim().length > flowsLimitations.headerText
        //   ) {
        //     errorMessages.push(WHATSAPP_TEMPLATE_NODE.HEADER_TEXT_MAX_LIMIT_ERROR);
        //   }

        //   // =========================== VALIDATE FOOTER TEXT ===========================
        //   if (
        //     data.contents.footerText.trim().length > flowsLimitations.footerText
        //   ) {
        //     errorMessages.push(WHATSAPP_TEMPLATE_NODE.FOOTER_TEXT_MAX_LIMIT_ERROR);
        //   }
        // }

        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },

    validateCarousel: (data) => {
        let errorMessages = [];
        let flowsLimitations =
            limitation.channels.whatsapp.nodes.carouselNode;

        if (
            data.contents.whatsapp && data.contents.whatsapp.templateData === undefined ||
            data.contents.whatsapp.templateData.templateName === undefined ||
            data.contents.whatsapp.templateData.templateName === ""
        ) {
            errorMessages.push(WHATSAPP_TEMPLATE_NODE.TEMPLATE_NOT_SELECTED_ERROR);
            return {
                flag: errorMessages.length > 0,
                messages: errorMessages,
            };
        }

        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },

    // Actual payment card Validations
    validatePayment: (data) => {
        const calculateTotalPayableAmount = (itemsData) => {
            let itemsTotal = 0;
            for (let index = 0; index < itemsData.length; index++) {
                if (
                    itemsData[index].itemSaleAmount &&
                    itemsData[index].itemSaleAmount !== ""
                ) {
                    itemsTotal +=
                        itemsData[index].itemSaleAmount * itemsData[index].itemQuantity - 0.99;
                } else {
                    itemsTotal +=
                        itemsData[index].itemAmount * itemsData[index].itemQuantity - 0.99;
                }
            }
            return itemsTotal;
        };

        let errorMessages = [];
        let paymentLimitations =
            limitation.channels.whatsapp.nodes.whatsAppPaymentNode;

        if ([null, undefined].includes(data.contents)) {
            errorMessages.push("Content not found");
            return {
                flag: errorMessages.length > 0,
                messages: errorMessages,
            };
        }

        if (
            data.contents.paymentConfigurationData &&
            (data.contents.paymentConfigurationData.payment_name !== undefined ||
                data.contents.paymentConfigurationData.payment_currency !== undefined ||
                data.contents.paymentConfigurationData.payment_method !== undefined) &&
            data.contents.paymentConfigurationData.payment_name.trim() === ""
        ) {
            // =========================== VALIDATE PAYMENT CONFIGURATION  ===========================
            errorMessages.push(
                WHATSAPP_PAYMENT_NODE.PAYMENT_CONFIGURATION_EMPTY_ERROR
            );
        }

        // =========================== VALIDATE BODY TEXT ===========================
        if (!data.contents.bodyText) {
            errorMessages.push(WHATSAPP_PAYMENT_NODE.BODY_TEXT_EMPTY_ERROR);
        }

        if (
            data.contents.bodyText &&
            data.contents.bodyText.length > paymentLimitations.bodyText
        ) {
            errorMessages.push(WHATSAPP_PAYMENT_NODE.BODY_TEXT_MAX_LIMIT_ERROR);
        }
        //################################ VALIDATE ITEMS IF PRESENT ######################

        if (
            !data.contents.getItemsFromVariable &&
            data.contents &&
            data.contents.items
        ) {
            for (let index = 0; index < data.contents.items.length; index++) {
                const objectData = data.contents.items[index];
                for (const key of Object.keys(objectData)) {
                    switch (key) {
                        case "itemName":
                            if (
                                objectData["itemName"] &&
                                objectData["itemName"].length > paymentLimitations.itemName
                            ) {
                                errorMessages.push(
                                    `${WHATSAPP_PAYMENT_NODE.PAYMENT_ITEM_MAX_LIMIT_ERROR}`
                                );
                            }

                            if (objectData["itemName"].trim() === "") {
                                errorMessages.push(
                                    `Item name` + WHATSAPP_PAYMENT_NODE.ITEMS_EMPTY_ERROR
                                );
                            }

                            break;
                        case "itemAmount":
                            if (
                                objectData["itemAmount"] &&
                                isNaN(Number(objectData["itemAmount"]))
                            ) {
                                errorMessages.push(
                                    `Product MRP value ${WHATSAPP_PAYMENT_NODE.ITEM_TYPE_ERROR}`
                                );
                            }

                            if (
                                objectData["itemAmount"] &&
                                Number(objectData["itemAmount"] < 1)
                            ) {
                                errorMessages.push(`Product MRP should not be less than 1`);
                            }

                            // Check if the item amount has more than two decimal places
                            if (objectData["itemAmount"]) {
                                const decimalPart = objectData["itemAmount"].split(".")[1];
                                if (decimalPart && decimalPart.length > 2) {
                                    errorMessages.push(
                                        "Product MRP should not have more than two digit after the decimal"
                                    );
                                }
                            }
                            break;

                        case "itemQuantity":
                            if (
                                objectData["itemQuantity"] &&
                                isNaN(Number(objectData["itemQuantity"]))
                            ) {
                                errorMessages.push(
                                    `Item quantity value ${WHATSAPP_PAYMENT_NODE.ITEM_TYPE_ERROR}`
                                );
                            }

                            if (
                                objectData["itemQuantity"] &&
                                Number(objectData["itemQuantity"] < 1)
                            ) {
                                errorMessages.push(`Item quantity should not be less than 1`);
                            }

                            // Check if the item amount has more than two decimal places
                            if (objectData["itemQuantity"]) {
                                const decimalPart = objectData["itemQuantity"].includes(".");
                                if (decimalPart) {
                                    errorMessages.push(
                                        "Decimal places are not allowed in item quantities"
                                    );
                                }
                            }

                            if (objectData["itemQuantity"].trim() === "") {
                                errorMessages.push(
                                    `Item quantity ` + WHATSAPP_PAYMENT_NODE.ITEMS_EMPTY_ERROR
                                );
                            }
                            break;

                        case "itemSaleAmount":
                            if (
                                objectData["itemSaleAmount"] &&
                                isNaN(Number(objectData["itemSaleAmount"]))
                            ) {
                                errorMessages.push(
                                    `Item offer price value ${WHATSAPP_PAYMENT_NODE.ITEM_TYPE_ERROR}`
                                );
                            }

                            if (
                                !isNaN(Number(objectData["itemSaleAmount"])) &&
                                Number(objectData["itemSaleAmount"]) >=
                                Number(objectData["itemAmount"])
                            ) {
                                errorMessages.push(
                                    `${WHATSAPP_PAYMENT_NODE.ITEM_SALE_AMOUNT_ERROR}`
                                );
                            }

                            if (
                                objectData["itemSaleAmount"] &&
                                Number(objectData["itemSaleAmount"]) < 1
                            ) {
                                errorMessages.push(
                                    `Item offer price should not be less than 1`
                                );
                            }
                            // Check if the item amount has more than two decimal places
                            if (objectData["itemSaleAmount"]) {
                                const decimalPart = objectData["itemSaleAmount"].split(".")[1];
                                if (decimalPart && decimalPart.length > 2) {
                                    errorMessages.push(
                                        // "Item offer price should not have more than two decimal places."
                                        "Item offer price should not have more than two digit after the decimal"
                                    );
                                }
                            }

                            break;
                        default:
                            break;
                    }
                    // handle all empty cases.
                    // if (key !== "itemSaleAmount" && key !== "retailerId" && key !== 'itemAmount') {
                    //     if (objectData[key].trim() === "") {
                    //         errorMessages.push(
                    //             `${key}` + WHATSAPP_PAYMENT_NODE.ITEMS_EMPTY_ERROR
                    //         );
                    //         return {
                    //             flag: errorMessages.length > 0,
                    //             messages: errorMessages,
                    //         };
                    //     }
                    // }
                    if (key === 'itemAmount') {
                        if (objectData[key].trim() === "") {
                            errorMessages.push(
                                `Product MRP` + WHATSAPP_PAYMENT_NODE.ITEMS_EMPTY_ERROR
                            );
                        }
                    }

                    //retailer id is require when catalog id added.
                    if (
                        key === "retailerId" &&
                        data.contents.catalogId &&
                        data.contents.catalogId.trim() !== ""
                    ) {
                        if (objectData[key].trim() === "") {
                            errorMessages.push(
                                `Retailer id ` +
                                WHATSAPP_PAYMENT_NODE.ITEMS_EMPTY_ERROR +
                                ` if catalog id is added`
                            );
                        }
                        //MAX LIMIT ERROR
                        if (objectData[key].trim().length > paymentLimitations.retailerId) {
                            errorMessages.push(
                                `${WHATSAPP_PAYMENT_NODE.RETAILER_ID_MAX_LIMIT_ERROR}`
                            );
                        }
                    }

                    if (
                        key === "retailerId" &&
                        objectData[key].trim() !== ""
                    ) {
                        if (!data.contents.catalogId || data.contents.catalogId.trim() === "") {
                            errorMessages.push(
                                `Catalog id` +
                                WHATSAPP_PAYMENT_NODE.ITEMS_EMPTY_ERROR +
                                ` if retailer id is added`
                            );
                        }
                    }
                }
            }
        }

        // ---------------------------- Response variable validations  ------------------------------

        //TODO saleAmount& discount should not be greater than itemAmount
        if (!data.contents.getItemsFromVariable) {
            if (
                data.contents.discount &&
                data.contents.discount.value !== "" &&
                isNaN(Number(data.contents.discount.value))
            ) {
                errorMessages.push(
                    `Discount value ${WHATSAPP_PAYMENT_NODE.ITEM_TYPE_ERROR}`
                );
            } else if (
                data.contents.discount &&
                !isNaN(Number(data.contents.discount.value)) &&
                data.contents.items &&
                data.contents.discount.value !== "" &&
                Number(data.contents.discount.value) >=
                Number(calculateTotalPayableAmount(data.contents.items))
            ) {
                errorMessages.push(
                    `Discount value must be less than ${calculateTotalPayableAmount(
                        data.contents.items
                    )}`
                );
            }

            if (
                data.contents.shipping &&
                isNaN(Number(data.contents.shipping.value))
            ) {
                errorMessages.push(
                    `Shipping value ${WHATSAPP_PAYMENT_NODE.ITEM_TYPE_ERROR}`
                );
            }

            if (data.contents.tax && isNaN(Number(data.contents.tax.value))) {
                errorMessages.push(
                    `Tax value ${WHATSAPP_PAYMENT_NODE.ITEM_TYPE_ERROR}`
                );
            }
        }

        if (!data.contents.response.successResponse) {
            errorMessages.push(WHATSAPP_PAYMENT_NODE.SUCCESS_RESPONSE_EMPTY_ERROR);
        }
        if (!data.contents.response.failureResponse) {
            errorMessages.push(WHATSAPP_PAYMENT_NODE.FAILURE_RESPONSE_EMPTY_ERROR);
        }

        if (
            data.contents.expiration.timestamp &&
            !data.contents.expiration.description
        ) {
            errorMessages.push(WHATSAPP_PAYMENT_NODE.DESCRIPTION_ERROR_MESSAGE);
        }

        if (
            data.contents.getItemsFromVariable === true &&
            !data.contents.itemsByVariable
        ) {
            errorMessages.push(
                WHATSAPP_PAYMENT_NODE.PAYMENT_ITEM_VARIABLE_ERROR_MESSAGE
            );
        }

        //validation added for timestamp
        if (
            data.contents.expiration.timestamp &&
            data.contents.expiration.timestamp !== null &&
            data.contents.expiration.timestamp !== ""
        ) {
            const currDate = new Date();
            const todayTime = new Date(currDate.getTime() + 8 * 60000);

            if (data.contents.expiration.timestamp < todayTime) {
                const convertUTCtoIST = todayTime.toLocaleString();
                errorMessages.push(
                    `The expiration time should not be less than  ${convertUTCtoIST}`
                );
            }

            if (
                data.contents.expiration.description &&
                data.contents.expiration.description.length >
                paymentLimitations.TimestampDescription
            ) {
                errorMessages.push(
                    `${WHATSAPP_PAYMENT_NODE.TIMESTAMP_DESCRIPTION_MAX_LIMIT_ERROR}`
                );
            }
        }

        if (data.contents.shipping) {
            if (
                data.contents.shipping.description &&
                data.contents.shipping.description.length >
                paymentLimitations.description
            ) {
                errorMessages.push(
                    `Shipping  ${WHATSAPP_PAYMENT_NODE.DESCRIPTION_MAX_LIMIT_ERROR}`
                );
            }

            if (data.contents.shipping.value) {
                const decimalPart = data.contents.shipping.value.split(".")[1];
                if (decimalPart && decimalPart.length > 2) {
                    errorMessages.push(
                        "Shipping amount should not have more than two digit after the decimal."
                    );
                }
            }
            if (data.contents.shipping.value && data.contents.shipping.value < 1) {
                errorMessages.push('Shipping amount should not be less than 1')
            }
        }

        if (data.contents.discount) {
            if (
                data.contents.discount.description &&
                data.contents.discount.description.length >
                paymentLimitations.description
            ) {
                errorMessages.push(
                    `Discount ${WHATSAPP_PAYMENT_NODE.DESCRIPTION_MAX_LIMIT_ERROR}`
                );
            }

            if (data.contents.discount.value) {
                const decimalPart = data.contents.discount.value.split(".")[1];
                if (decimalPart && decimalPart.length > 2) {
                    errorMessages.push(
                        "Discount amount should not have more than two digit after the decimal."
                    );
                }
            }
            if (data.contents.discount.value && data.contents.discount.value < 1) {
                errorMessages.push('Discount amount should not be less than 1')
            }
        }

        if (data.contents.tax && data.contents.tax.value) {
            const decimalPart = data.contents.tax.value.split(".")[1];
            if (decimalPart && decimalPart.length > 2) {
                errorMessages.push(
                    "Tax amount should not have more than two digit after the decimal."
                );
            }

            if (data.contents.tax && data.contents.tax.value && data.contents.tax.value < 1) {
                errorMessages.push('Tax amount should not be less than 1')
            }
        }

        if (data.contents.response) {
            if (
                data.contents.response.successResponse &&
                data.contents.response.successResponse.length >
                paymentLimitations.responseText
            ) {
                errorMessages.push(
                    `Success response ${WHATSAPP_PAYMENT_NODE.RESPONSE_MAX_LIMIT_ERROR}`
                );
            }

            if (
                data.contents.response.failureResponse &&
                data.contents.response.failureResponse.length >
                paymentLimitations.responseText
            ) {
                errorMessages.push(
                    `Failure response ${WHATSAPP_PAYMENT_NODE.RESPONSE_MAX_LIMIT_ERROR}`
                );
            }
        }

        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },
    validateCatalog: (data) => {
        let errorMessages = [];
        let catalogLimitation =
            limitation.channels.whatsapp.nodes.whatsAppCatalogNode;

        if (
            data.contents !== undefined &&
            data.contents.bodyText !== undefined &&
            data.contents.footerText !== undefined &&
            data.contents.headerText !== undefined &&
            data.contents.sectionVariable !== undefined &&
            data.contents.catalogId !== undefined
        ) {
            // =========================== VALIDATE  DYNAMIC VARIABLE ===========================

            if (data.contents.catalogId.trim().length === 0) {
                errorMessages.push(WHATSAPP_CATALOG_NODE.CATALOG_ID_EMPTY_ERROR);
            }

            // =========================== VALIDATE BODY TEXT ===========================
            if (data.contents.bodyText.trim().length === 0) {
                errorMessages.push(WHATSAPP_CATALOG_NODE.BODY_TEXT_EMPTY_ERROR);
            }

            if (data.contents.bodyText.trim().length > catalogLimitation.bodyText) {
                errorMessages.push(WHATSAPP_CATALOG_NODE.BODY_TEXT_MAX_LIMIT_ERROR);
            }

            // =========================== VALIDATE HEADER TEXT ===========================
            if (data.contents.headerText.trim().length === 0) {
                errorMessages.push(WHATSAPP_CATALOG_NODE.HEADER_TEXT_EMPTY_ERROR);
            }
            if (
                data.contents.headerText.trim().length > catalogLimitation.headerText
            ) {
                errorMessages.push(WHATSAPP_CATALOG_NODE.HEADER_TEXT_MAX_LIMIT_ERROR);
            }

            // =========================== VALIDATE FOOTER TEXT ===========================
            if (
                data.contents.footerText.trim().length > catalogLimitation.footerText
            ) {
                errorMessages.push(WHATSAPP_CATALOG_NODE.FOOTER_TEXT_MAX_LIMIT_ERROR);
            }
            // =========================== VALIDATE  DYNAMIC VARIABLE ===========================

            if (data.contents.sectionVariable.trim().length === 0) {
                errorMessages.push(WHATSAPP_CATALOG_NODE.SECTION_DYNAMIC_EMPTY_ERROR);
            }
        }

        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },
    validateCTA: (data) => {

        let errorMessages = [];
        let ctaLimitations = limitation.channels.whatsapp.nodes.whatsAppCTANode;


        if (
            data.contents !== undefined &&
            data.contents.bodyText !== undefined &&
            data.contents.footerText !== undefined &&
            data.contents.headerText !== undefined &&
            data.contents.text !== undefined &&
            data.contents.url !== undefined
        ) {

            // =========================== VALIDATE HEADER TEXT ===========================
            if (
                data.contents.headerText.trim().length > ctaLimitations.headerText
            ) {
                errorMessages.push(WHATSAPP_CTA_NODE.HEADER_TEXT_MAX_LIMIT_ERROR);
            }

            // =========================== VALIDATE BODY TEXT ===========================

            if (data.contents.bodyText.trim().length === 0) {
                errorMessages.push(WHATSAPP_CTA_NODE.BODY_TEXT_EMPTY_ERROR);
            }

            if (data.contents.bodyText.trim().length > ctaLimitations.bodyText) {
                errorMessages.push(WHATSAPP_CTA_NODE.BODY_TEXT_MAX_LIMIT_ERROR);
            }

            // =========================== VALIDATE TEXT ===========================
            if (data.contents.text.trim().length === 0) {
                errorMessages.push(WHATSAPP_CTA_NODE.TEXT_EMPTY_ERROR);
            }

            if (data.contents.text.trim().length > ctaLimitations.text) {
                errorMessages.push(WHATSAPP_CTA_NODE.TEXT_MAX_LIMIT_ERROR);
            }

            // =========================== VALIDATE CTA URL ===========================
            if (data.contents.url.trim().length === 0) {
                errorMessages.push(WHATSAPP_CTA_NODE.CTA_URL_EMPTY_ERROR);
            }

            // =========================== VALIDATE FOOTER TEXT ===========================
            if (
                data.contents.footerText.trim().length > ctaLimitations.footerText
            ) {
                errorMessages.push(WHATSAPP_CTA_NODE.FOOTER_TEXT_MAX_LIMIT_ERROR);
            }
        }

        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },
};


export const TubuluValidation = {
    validateTextMessage: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents !== undefined) {
            if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Tubulu message text can't be empty."],
                };
            }
        }
        return result;
    },
    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        if (data.contents !== undefined) {
            if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Question node text can't be empty."],
                };
            }
        }
        return result;
    },
    validateButton: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.bodyText !== undefined
        ) {
            if (data.contents.bodyText.trim() === "") {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Tubulu message body text can't be empty.`,
                    ],
                };
            }
        }
        return result;
    },
    validateList: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.contents !== undefined &&
            data.contents.list !== undefined &&
            data.contents.bodyText !== undefined
        ) {
            if (data.contents.bodyText.trim() === "") {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Tubulu message body text can't be empty.`,
                    ],
                };
            }
        }
        return result;
    },
    validateOption: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.contents !== undefined &&
            data.contents.options !== undefined &&
            data.contents.header !== undefined
        ) {
            if (data.contents.header.trim() === "") {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Tubulu message body text can't be empty.`,
                    ],
                };
            }
        }
        return result;
    },
    validateCarousel: (data) => {

        /** NOTE : "In this method, only nodes with version 2.0 are validated for the Tubulu carousel, 
                as version 1.0 nodes are handled separately in the `withoutChannelsValidations` method."   **/

        let result = { flag: false, messages: [] };



        if (
            data.contents &&
            data.contents.tubulu &&
            data.contents.tubulu.getPayloadFromVariable
        ) {

            if (data.contents.tubulu.carouselPayloadByVariable === "") {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Tubulu Carousel: input variable can't be empty.`,
                    ],
                };
            }

        }

        if (
            data.contents &&
            data.contents.tubulu &&
            !data.contents.tubulu.getPayloadFromVariable &&
            data.contents.tubulu.carousels
        ) {
            if (data.contents.tubulu.carousels.length === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, `Please add carousel in tubulu.`],
                };
            }

            if (data.contents.tubulu.bodyText.trim() === "") {
                result = {
                    flag: true,
                    messages: [...result.messages, `Body text is required in tubulu.`],
                };
            }

            for (let i = 0; i < data.contents.tubulu.carousels.length; i++) {
                if (data.contents.tubulu.carousels[i]?.imageUrl.trim() === "") {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Tubulu Carousel ${i + 1}: image URL can't be empty.`,
                        ],
                    };
                }
                // else {
                //     let regexForUrl =
                //         /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                //     if (!regexForUrl.test(data.contents.tubulu.carousels[i]?.imageUrl.trim())) {
                //         result = {
                //             flag: true,
                //             messages: [
                //                 ...result.messages,
                //                 `Tubulu: Wrong image URL in Carousel No ${i + 1}.`,
                //             ],
                //         };
                //     }
                // }


                if (data.contents.tubulu.carousels[i].buttons.length === 0) {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Tubulu Carousel ${i + 1} should have at least one button.`,
                        ],
                    };
                }

                if (data.contents.tubulu.carousels[i].buttons.length > 1) {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Tubulu support only one button in each carousel.`,
                        ],
                    };
                }

                if (data.contents.tubulu.carousels[i].buttons.length) {
                    if (data.contents.tubulu.carousels[i].buttons[0].buttonName === '') {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Tubulu Carousel ${i + 1}: Button text required.`,
                            ],
                        };
                    }
                    if (data.contents.tubulu.carousels[i].buttons[0].redirectUrl === '') {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Tubulu Carousel ${i + 1}: Redirect url required.`,
                            ],
                        };
                    }
                }
            }
        }



        if (
            data.contents &&
            data.contents.tubulu &&
            !data.contents.tubulu.getPayloadFromVariable &&
            data.contents.tubulu.suggestionButtons
        ) {

            if (data.contents.tubulu.suggestionButtons.length) {

                let suggestionButtonsValidationFlag = true;
                for (const element of data.contents.tubulu.suggestionButtons) {
                    if (element.text && element.text.trim() !== '') {
                        suggestionButtonsValidationFlag = false;
                        break
                    }
                }

                if (suggestionButtonsValidationFlag) {
                    result = {
                        flag: true,
                        messages: [...result.messages, `There must be atleast one suggestion button for the tubulu.`],
                    };
                }
            }


        }
        return result;
    },
    validateMediaNode: (data) => {
        const errorMessage = [];
        if (data.contents.media.mediaType === "image") {
            if (!data.contents.caption) {
                errorMessage.push("Caption is required in tubulu.");
            }
        }
        return {
            flag: errorMessage.length > 0,
            messages: errorMessage,
        };
    },
};

export const TelegramValidation = {
    validateTextMessage: (data) => {
        let result = { flag: false, messages: [] };
        let limitTelegramMessage =
            limitation.channels.telegram.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitTelegramMessage.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Telegram message character length should not greater than ${limitTelegramMessage.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Telegram message text can't be empty."],
                };
            }
        }
        return result;
    },
    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText =
            limitation.channels.telegram.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Question node character length should not greater than ${limitMessageText.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Question node text can't be empty."],
                };
            }
        }
        return result;
    },
    validateButton: (data) => {
        let result = { flag: false, messages: [] };
        let limitTelegramButton = limitation.channels.telegram.nodes.buttonNode;
        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.bodyText !== undefined
        ) {
            if (data.contents.buttons.length > limitTelegramButton.maxButtons) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Telegram does not support more than ${limitTelegramButton.maxButtons} buttons.`,
                    ],
                };
            }
            if (data.contents.bodyText.length > limitTelegramButton.bodyText) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Telegram message body Text should not greater than ${limitTelegramButton.bodyText} characters.`,
                    ],
                };
            }
        }
        return result;
    },
};

export const MessengerValidation = {
    validateTextMessage: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessengerMessage =
            limitation.channels.messenger.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessengerMessage.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Messenger message character length should not greater than ${limitMessengerMessage.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Messenger message text can't be empty."],
                };
            }
        }
        return result;
    },
    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText =
            limitation.channels.messenger.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Question node character length should not greater than ${limitMessageText.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Question node text can't be empty."],
                };
            }
        }
        return result;
    },
    validateButton: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessengerButton = limitation.channels.messenger.nodes.buttonNode;
        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.bodyText !== undefined
        ) {
            if (data.contents.buttons.length > limitMessengerButton.maxButtons) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Messenger does not support more than ${limitMessengerButton.maxButtons} buttons.`,
                    ],
                };
            }
            if (data.contents.bodyText.length > limitMessengerButton.bodyText) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Messenger message body text should not greater than ${limitMessengerButton.bodyText} characters.`,
                    ],
                };
            }
        }
        return result;
    },
    validateCarousel: (data) => {

        let result = { flag: false, messages: [] };

        if (
            data.contents &&
            data.contents.fbMessenger &&
            data.contents.fbMessenger.getPayloadFromVariable
        ) {

            if (data.contents.fbMessenger.carouselPayloadByVariable === "") {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Facebook Carousel: input variable can't be empty.`,
                    ],
                };
            }

        }
        if (
            data.contents !== undefined &&
            data.contents.fbMessenger !== undefined &&
            !data.contents.fbMessenger.getPayloadFromVariable &&
            data.contents.fbMessenger.carousels !== undefined
        ) {
            if (data.contents.fbMessenger.carousels.length === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, `Please add carousel in facebook messenger.`],
                };
            }
            if (data.contents.fbMessenger.carousels.length > 10) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Facebook messenger support only ten carousel cards.`,
                    ],
                };
            }
            for (let i = 0; i < data.contents.fbMessenger.carousels.length; i++) {
                if (data.contents.fbMessenger.carousels[i]?.imageUrl.trim() === "") {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Facebook messenger Carousel ${i + 1}: image URL can't be empty.`,
                        ],
                    };
                } else {
                    let regexForUrl =
                        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                    if (!regexForUrl.test(data.contents.fbMessenger.carousels[i]?.imageUrl.trim())) {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Wrong image URL In Carousel ${i + 1}.`,
                            ],
                        };
                    }
                }
                if (data.contents.fbMessenger.carousels[i].bodyText.trim() === "") {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Facebook messenger carousel ${i + 1}: body text can't be empty`,
                        ],
                    };
                }
                if (data.contents.fbMessenger.carousels[i].buttons.length === 0) {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Facebook messenger each carousel should have at least one button`,
                        ],
                    };
                } else {
                    if (data.contents.fbMessenger.carousels[i].buttons.length > 3) {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Facebook messenger support only three button in each carousel`,
                            ],
                        };
                    }
                }
            }
        }
        return result;
    },
};

export const InstagramMessengerValidation = {
    validateTextMessage: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessengerMessage =
            limitation.channels.instagram.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessengerMessage.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Messenger message character length should not greater than ${limitMessengerMessage.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Messenger message text can't be empty."],
                };
            }
        }
        return result;
    },
    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText =
            limitation.channels.instagram.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Question node character length should not greater than ${limitMessageText.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Question node text can't be empty."],
                };
            }
        }
        return result;
    },
    validateMediaNode: (data) => {
        let result = { flag: false, messages: [] };
        const mediaLimitation = limitation.channels.instagram.nodes.media;
        if (
            data.contents.mediaType === "audio" &&
            data.contents.media.mimeType !== undefined
        ) {
            let support = false;
            for (
                let i = 0;
                i < mediaLimitation.audio.supportedFormatType.length;
                i++
            ) {
                if (
                    data.contents.media.mimeType.includes(
                        mediaLimitation.audio.supportedFormatType[i]
                    )
                ) {
                    support = true;
                }
            }
            if (support === false) {
                result = {
                    flag: true,
                    messages: [
                        `${data.contents.media.mimeType} not supported in Instagram messenger.`,
                    ],
                };
            }
        } else if (
            data.contents.mediaType === "image" &&
            data.contents.media.mimeType !== undefined
        ) {
            let support = false;
            for (
                let i = 0;
                i < mediaLimitation.image.supportedFormatType.length;
                i++
            ) {
                if (
                    data.contents.media.mimeType.includes(
                        mediaLimitation.image.supportedFormatType[i]
                    )
                ) {
                    support = true;
                }
            }
            if (support === false) {
                result = {
                    flag: true,
                    messages: [
                        `${data.contents.media.mimeType} not supported in Instagram messenger.`,
                    ],
                };
            }
        } else if (
            data.contents.mediaType === "video" &&
            data.contents.media.mimeType !== undefined
        ) {
            let support = false;
            for (
                let i = 0;
                i < mediaLimitation.video.supportedFormatType.length;
                i++
            ) {
                if (
                    data.contents.media.mimeType.includes(
                        mediaLimitation.video.supportedFormatType[i]
                    )
                ) {
                    support = true;
                }
            }
            if (support === false) {
                result = {
                    flag: true,
                    messages: [
                        `${data.contents.media.mimeType} not supported in Instagram messenger.`,
                    ],
                };
            }
        } else if (data.contents.mediaType === "document") {
            result = {
                flag: true,
                messages: ["Instagram messenger does not support document."],
            };
        }
        return result;
    },

    validateMediaInputNode: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.validation &&
            data.validation.mediaInput &&
            data.validation.mediaInput.mediaTypes &&
            data.validation.mediaInput.mediaTypes === "document"
        ) {
            result = {
                flag: true,
                messages: ["Instagram messenger does not support document."],
            };
        }
        return result;
    },

    validateCarousel: (data) => {
        let result = { flag: false, messages: [] };

        if (
            data.contents &&
            data.contents.instagram &&
            data.contents.instagram.getPayloadFromVariable
        ) {

            if (data.contents.instagram.carouselPayloadByVariable === "") {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Instagram Carousel: input variable can't be empty.`,
                    ],
                };
            }

        }
        if (
            data.contents !== undefined &&
            data.contents.instagram !== undefined &&
            !data.contents.instagram.getPayloadFromVariable &&
            data.contents.instagram.carousels !== undefined
        ) {
            if (data.contents.instagram.carousels.length === 0) {
                result = {
                    flag: true,
                    messages: [...result.messages, `Please add carousel in instagram.`],
                };
            }
            if (data.contents.instagram.carousels.length > 10) {
                result = {
                    flag: true,
                    messages: [
                        ...result.messages,
                        `Instagram support only ten carousel cards.`,
                    ],
                };
            }
            for (let i = 0; i < data.contents.instagram.carousels.length; i++) {
                if (data.contents.instagram.carousels[i]?.imageUrl.trim() === "") {
                    if (data.contents.instagram.carousels[i].mediaFromType === "byURL") {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Instagram Carousel ${i + 1}: image URL can't be empty.`,
                            ],
                        };
                    }

                    if (data.contents.instagram.carousels[i].mediaFromType === "byUpload") {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Instagram Carousel ${i + 1}: please select image from dropdown.`,
                            ],
                        };
                    }
                } else {
                    let regexForUrl =
                        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                    if (!regexForUrl.test(data.contents.instagram.carousels[i]?.imageUrl.trim())) {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Instagram: Invalid Image URL in Carousel ${i + 1}.`,
                            ],
                        };
                    }
                }
                if (data.contents.instagram.carousels[i].bodyText.trim() === "") {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Instagram Carousel ${i + 1}: body text can't be empty.`,
                        ],
                    };
                }
                if (data.contents.instagram.carousels[i].buttons.length === 0) {
                    result = {
                        flag: true,
                        messages: [
                            ...result.messages,
                            `Instagram Carousel ${i + 1}: atleast one button is required.`,
                        ],
                    };
                } else {
                    if (data.contents.instagram.carousels[i].buttons.length > 3) {
                        result = {
                            flag: true,
                            messages: [
                                ...result.messages,
                                `Instagram support only three button.`,
                            ],
                        };
                    }
                }
            }
        }
        return result;
    },
    validateButtonNode: (data) => {
        let result = { flag: false, messages: [] };
        let buttonLimit = limitation.channels.instagram.nodes.buttonNode;
        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.bodyText !== undefined
        ) {
            if (data.contents.buttons.length > buttonLimit.maxButton) {
                result = {
                    flag: true,
                    messages: [
                        `Instagram support only ${buttonLimit.maxButton} buttons.`,
                    ],
                };
            }
            return result;
        }
    },
};

export const SMSValidation = {
    validateTextMessage: (data) => {
        let result = { flag: false, messages: [] };
        let limitSMSMessage = limitation.channels.sms.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitSMSMessage.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `SMS message character length should not greater than ${limitSMSMessage.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["SMS message text can't be empty."],
                };
            }
        }
        return result;
    },
    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText = limitation.channels.sms.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        `Question node character length should not greater than ${limitMessageText.maxCharacterLength} characters.`,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: ["Question node text can't be empty."],
                };
            }
        }
        return result;
    },
};

export const WebChatValidation = {
    validateMediaNode: (data) => {
        const errorMessage = [];
        if (data.contents.media.mediaType === "image") {
            if (!data.contents.caption) {
                errorMessage.push("Caption is required in Webchat.");
            }
        }
        return {
            flag: errorMessage.length > 0,
            messages: errorMessage,
        };
    },
};

export const RCSValidation = {
    validateTextMessage: (data) => {
        let errorMessages = [];
        let limitRCSMessage = limitation.channels.rcs.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitRCSMessage.maxCharacterLength) {
                errorMessages = [RCS_VALIDATIONS.MESSAGE_TEXT_MAX_CHARACTER_ERROR];
            } else if (data.contents.trim().length === 0) {
                errorMessages = [RCS_VALIDATIONS.EMPTY_MESSAGE_TEXT_ERROR];
            }
            return {
                flag: errorMessages.length > 0,
                messages: errorMessages,
            };
        }
    },

    // RCS Question Card
    validateTextQuestion: (data) => {
        let result = { flag: false, messages: [] };
        let limitMessageText = limitation.channels.rcs.nodes.plainMessageTextNode;
        if (data.contents !== undefined) {
            if (data.contents.length > limitMessageText.maxCharacterLength) {
                result = {
                    flag: true,
                    messages: [
                        RCS_VALIDATIONS.RCS_QUESTION_CARD_ERROR,
                    ],
                };
            } else if (data.contents.trim().length === 0) {
                result = {
                    flag: true,
                    messages: [RCS_VALIDATIONS.QUESTION_CARD_EMPTY_ERROR],
                };
            }
        }
        return result;
    },

    // Media Output Node Validation
    validateMediaNode: (data) => {
        const errorMessage = [];
        // const limitRCSMessage = limitation.channels.rcs.nodes.mediaOutputNode;

        // if (data.contents.media.mediaType === "image") {
        //     if (!data.contents.caption) {
        //         errorMessage.push(RCS_VALIDATIONS.MEDIA_OUTPUT_CARD_EMPTY_CAPTION_ERROR);
        //     } else if (data.contents.caption.length > limitRCSMessage.caption) {
        //         errorMessage.push(RCS_VALIDATIONS.RCS_MEDIA_OUTPUT_CARD_CAPTION_ERROR);
        //     }
        // }

        // if (data.contents.fileSizeInMB > 2) {
        //     errorMessage.push("Upload only images up to 2 MB");
        //     return;
        // }

        if (data.contents.mediaType === "audio") {
            errorMessage.push("RCS channel does not support audio media type.");
        }
        return {
            flag: errorMessage.length > 0,
            messages: errorMessage,
        };
    },
    // Media INput
    validateInputMediaNode: (data) => {
        let result = { flag: false, messages: [] };
        if (
            data.validation &&
            data.validation.mediaInput &&
            data.validation.mediaInput.mediaTypes &&
            data.validation.mediaInput.mediaTypes === "audio"
        ) {
            result = {
                flag: true,
                messages: ["RCS channel does not support audio."],
            };
        }
        return result;
    },
    validateButton: (data) => {
        let errorMessages = [];
        let limitRCSButton = limitation.channels.rcs.nodes.buttonNode;
        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.bodyText !== undefined
        ) {
            // VALIDATE BUTTON NODE
            if (data.contents.buttons.length > limitRCSButton.maxButtons) {
                errorMessages.push(
                    `RCS does not support more than ${limitRCSButton.maxButtons} buttons.`
                );
            }
            // VALIDATE BODY TEXT
            if (data.contents.bodyText.length > limitRCSButton.bodyText) {
                errorMessages.push(
                    `RCS message body text should not greater than ${limitRCSButton.bodyText} characters.`
                );
            }
            // IS MEDIA RENDER
            if (data.contents.isMediaRender === true) {
                errorMessages.push('RCS does not support media render.');
            }

        }
        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },


    validateRCSStandAloneButton: (data) => {
        let errorMessages = [];
        let limitRCSButton = limitation.channels.rcs.nodes.rcsStandAloneNode;
        if (
            data.contents !== undefined &&
            data.contents.buttons !== undefined &&
            data.contents.title !== undefined &&
            data.contents.description !== undefined
        ) {
            // ================================= 
            if (data.contents) {
                switch (data.contents.isMediaRender) {
                    case "text": {
                        if (typeof data.contents.title === 'string' && data.contents.title.trim() === "") {
                            errorMessages.push('Please enter title.');
                        }
                        if (data.contents.title.length > limitRCSButton.text) {
                            errorMessages.push(
                                `Text should not exceed than ${limitRCSButton.text} characters.`
                            );
                        }
                        break;
                    }
                    case "media": {
                        if (typeof data.contents.mediaType === 'string' && data.contents.mediaType.trim() === "") {
                            errorMessages.push('Please select media type.');
                        }
                        if (typeof data.contents.mediaUrl === 'string' && data.contents.mediaUrl.trim() === "") {
                            errorMessages.push('Please enter media url.');
                        }
                        break;
                    }
                    case "standAlone": {
                        if (typeof data.contents.mediaType === 'string' && data.contents.mediaType.trim() === "") {
                            errorMessages.push('Please select media type.');
                        }
                        if (typeof data.contents.mediaUrl === 'string' && data.contents.mediaUrl.trim() === "") {
                            errorMessages.push('Please enter media url.');
                        }

                        if (typeof data.contents.title === 'string' && data.contents.title.trim() === "") {
                            errorMessages.push('Please enter title.');
                        }

                        if (data.contents.title.length > limitRCSButton.title) {
                            errorMessages.push(
                                `Title should not exceed than ${limitRCSButton.title} characters.`
                            );
                        }
                        if (data.contents.description.length > limitRCSButton.description) {
                            errorMessages.push(
                                `Description should not exceed than ${limitRCSButton.description} characters.`
                            );
                        }
                        // if (data.contents.mediaType !== "" && data.contents.mediaType === "video") {
                        //     if (typeof data.contents.thumbnailImageUrl === 'string' && data.contents.thumbnailImageUrl.trim() === "") {
                        //         errorMessages.push('Please enter thumbNail image url.');
                        //     }
                        // }
                        break;
                    }
                    default:
                        break;
                }
            }

            // ============================ BUTTON VALIDATION ========================================


            if (data.contents && Array.isArray(data.contents.buttons)) {
                switch (data.contents.isMediaRender) {
                    case "text": {
                        if (data.contents && Array.isArray(data.contents.buttons) && data.contents.buttons.length < 1) {
                            errorMessages.push('RCS interactive card should have at least one button.');
                        }
                        if (data.contents.buttons.length > 10) {
                            errorMessages.push(
                                `RCS does not support more than 10 buttons.`
                            );
                        }
                        break;
                    }
                    case "media": {
                        if (data.contents && Array.isArray(data.contents.buttons) && data.contents.buttons.length < 1) {
                            errorMessages.push('Button should have at least one button.');
                        }
                        if (data.contents.buttons.length > 10) {
                            errorMessages.push(
                                `RCS does not support more than 10 buttons.`
                            );
                        }
                        break;
                    }
                    case "standAlone": {
                        if (data.contents && Array.isArray(data.contents.buttons) && data.contents.buttons.length < 1) {
                            errorMessages.push('RCS interactive card should have at least one button.');
                        }
                        if (data.contents.buttons.length > limitRCSButton.maxButtons) {
                            errorMessages.push(
                                `RCS does not support more than ${limitRCSButton.maxButtons} buttons.`
                            );
                        }
                        break;
                    }
                    default:
                        break;
                }
            }

            if (data.contents && Array.isArray(data.contents.buttons)) {
                for (let index = 0; index < data.contents.buttons.length; index++) {
                    const element = data.contents.buttons[index];
                    if (typeof element.text === 'string' && element.text.trim() === "") {
                        errorMessages.push('Please enter button text.');
                        return {
                            flag: errorMessages.length > 0,
                            messages: errorMessages,
                        };
                    }
                    if (typeof element.value === 'string' && element.value.trim() === "" && element.type === "url") {
                        errorMessages.push('Please enter URL.');
                        return {
                            flag: errorMessages.length > 0,
                            messages: errorMessages,
                        };
                    }
                }
            }

            // ============================ BUTTON VALIDATION ======================================== 
        }
        return {
            flag: errorMessages.length > 0,
            messages: errorMessages,
        };
    },

    validateCarouselNode: (data) => {
        const errorMessages = [];
        const rcsCarouselNodeLimitations = limitation.channels.rcs.nodes.rcsCarouselNode;

        if (
            data.contents &&
            data.contents.rcs &&
            data.contents.rcs.getPayloadFromVariable
        ) {

            if (data.contents.rcs.carouselPayloadByVariable === "") {
                errorMessages.push(
                    `RCS Carousel: input variable can't be empty.`
                );
            }

        }


        if (
            data.contents &&
            data.contents.rcs &&
            !data.contents.rcs.getPayloadFromVariable &&
            data.contents.rcs.carousels
        ) {
            if (data.contents.rcs.carousels.length < 2) {
                errorMessages.push([RCS_VALIDATIONS.RCS_ATLEAST_TWO_CAROUSELS]);
            }
            if (data.contents.rcs.carousels.length > 10) {
                errorMessages.push([RCS_VALIDATIONS.RCS_MAX_CAROUSELS_VALIDATION]);
            }
            let index = 1;
            for (const cardContent of data.contents.rcs.carousels) {

                // VALIDATE bodyText
                if (cardContent.bodyText.length > rcsCarouselNodeLimitations.bodyText) {
                    errorMessages.push(
                        `RCS carousel ${index}: The body text cannot exceeds ${rcsCarouselNodeLimitations.bodyText} characters.`
                    );
                };
                if (cardContent.bodyText.length === 0) {
                    errorMessages.push(
                        `RCS carousel ${index}: Body text can't be empty.`
                    );
                };

                // VALIDATE title
                if (cardContent.title.length > rcsCarouselNodeLimitations.title) {
                    errorMessages.push(
                        `RCS carousel ${index}: The title cannot exceeds ${rcsCarouselNodeLimitations.title} characters.`
                    );
                };
                if (cardContent.title.length === 0) {
                    errorMessages.push(
                        `RCS carousel ${index}: Title can't be empty.`
                    );
                };

                // VALIDATE buttons
                if (cardContent.buttons) {
                    if (cardContent.buttons.length === 0) {
                        errorMessages.push(
                            `RCS Carousel ${index}: At least one button required`
                        )
                    }
                    for (const buttonElement of cardContent.buttons) {
                        if (buttonElement.buttonName.length > rcsCarouselNodeLimitations.buttonName) {
                            errorMessages.push(
                                `RCS carousel ${index}: The button text cannot exceeds ${rcsCarouselNodeLimitations.buttonName} characters.`
                            );
                        }
                        if (buttonElement.buttonName.length === 0) {
                            errorMessages.push(
                                `RCS carousel ${index}: The button text can't be empty.`
                            );
                        }
                        if (buttonElement.actionType === 'url' && buttonElement.redirectUrl.length === 0) {
                            errorMessages.push(
                                `RCS carousel ${index}: The button redirect url can't be empty.`
                            );
                        }
                    };

                };

                ++index;
            }
        }
        return {
            flag: errorMessages.length > 0,
            messages: errorMessages
        }
    }
};

const findChannelIdFromChannelSwitch = (sourceNode, sourceHandle) => {
    // all invalid arguments
    if (
        !sourceNode ||
        !sourceNode.data ||
        !Array.isArray(sourceNode.data.channels)
    ) {
        return {
            //  connection is not from channel id and
            channelId: null,
            status: false,
            reason: "source node invalid",
        };
    }

    // For Valid Arguments Continue...
    let selectedChannelId = sourceNode.data.channels.find(
        (channel) => channel.handleId === sourceHandle
    );

    return selectedChannelId
        ? {
            status: true,
            channelId: selectedChannelId.channelId,
        }
        : {
            //  connection is not from channel id and
            channelId: null,
            status: false,
        };
};

export function getChannelFromTheChannelSwitchNode(sourceNode, sourceHandle) {
    if (
        !sourceHandle ||
        typeof sourceHandle !== "string" ||
        sourceHandle.trim().trim().length === 0
    ) {
        return {
            status: false,
            channelId: null,
            reason: "source handle invalid",
        };
    }

    let result = findChannelIdFromChannelSwitch(sourceNode, sourceHandle);

    return result != null && result.status === true
        ? {
            status: true,
            channelId: result.channelId,
        }
        : {
            status: false,
            channelId: null,
        };
}
