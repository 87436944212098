import {createSlice} from "@reduxjs/toolkit";

export let STATUS = {
    IDLE: 'idle',
    LOADING: 'Loading',
    ERROR: 'error'
}

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        bots: [],
        teams: [],
        summary: [],
        engagements: {installedIntegrations: [], totalInstalledIntegrations: 0, duration: 'today',pageNumber: 1},
        invoices: [],
        isBotDeployedInOrganisation: false,
        status: STATUS.IDLE
    },
    reducers: {
        SetData: (state, action) => {
            state.bots = action.payload.data.bots ? action.payload.data.bots : [];
            state.isBotDeployedInOrganisation = action.payload.data.isBotDeployedInOrganisation ? action.payload.data.isBotDeployedInOrganisation : false;
            state.teams = action.payload.data.teams ? action.payload.data.teams : [];
            state.summary = action.payload.data.summary ? action.payload.data.summary : [];
            state.engagements = action.payload.data.engagements ? action.payload.data.engagements : {
                installedIntegrations: [],
                totalInstalledIntegrations: 0,
                duration: 'today',
                pageNumber: 1
            };
            state.engagements.duration = 'today';
            state.engagements.pageNumber = 1;
            state.invoices = action.payload.data.invoices ? action.payload.data.invoices : [];
        },
        UpdateFilters: (state, action) => {
            state.engagements.pageNumber = action.payload.pageNumber;
            state.engagements.duration = action.payload.duration;
        },
        UpdateEngagements: (state, action) => {
            state.engagements.installedIntegrations = action.payload.installedIntegrations;
            state.engagements.totalInstalledIntegrations = action.payload.totalInstalledIntegrations;
        },
        AddEngagements: (state, action) => {
            state.engagements.installedIntegrations = [...state.engagements.installedIntegrations, ...action.payload.installedIntegrations];
            state.engagements.totalInstalledIntegrations = action.payload.totalInstalledIntegrations;
        },
        resetState: (state, action) => {
            state.bots = [];
            state.isBotDeployedInOrganisation = false;
            state.teams = [];
            state.summary = [];
            state.engagements = [];
            state.invoices = [];
            state.status = STATUS.IDLE;
        }
    }
});

export const {SetData, resetState, UpdateFilters, UpdateEngagements, AddEngagements} = dashboardSlice.actions;

export default dashboardSlice.reducer;
